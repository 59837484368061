const appSettings = {
    productionUrl: 'https://whichpharma.pt',
    developmentUrl: 'https://whichpharma.pt:50010/',
    localDevelopmentUrl: 'http://localhost:3000',
    //CLOUD
    users_api_url: process.env.REACT_APP_USERS_API_URL || '',
    products_api_url: process.env.REACT_APP_PRODUCTS_API_URL || '',
    domainInfo_api_url: process.env.REACT_APP_DOMAININFO_API_URL || '',
    suppliers_api_url: process.env.REACT_APP_SUPPLIERS_API_URL || '',
    rfqs_api_url: process.env.REACT_APP_RFQS_API_URL || '',
    external_api_url: process.env.REACT_APP_EXTERNAL_API_URL || '',
    platform_api_url: process.env.REACT_APP_PLATFORM_API_URL || '',
    enquiry_api_url: process.env.REACT_APP_ENQUIRY_API_URL || '',
    health_api_url: process.env.REACT_APP_HEALTH_API_URL || '',
    health_api_tst_url: process.env.REACT_APP_HEALTH_API_TST_URL || '',
    enquiry_need_api_url: process.env.REACT_APP_ENQUIRY_NEED_API_URL || '',
    // SERVER
    backend_url: process.env.REACT_APP_BACK_END_URL || '',
    valor_de_teste: process.env.REACT_APP_ENV,
};

export default appSettings;
