import { Action, Reducer } from 'redux';
import { PlatformV3State } from './state';
import {
    PlatformV3ProcessingSettingsLoadedAction,
    PlatformV3ProcessingSettingsNewTermAction,
    PlatformV3ProcessingSettingsUpdatedAction,
} from './types';
import { Actions as SessionActions } from '../Session/actions';
import { Actions } from './actions';
import { MappingName } from '../../models/ProcessingSettingsV3';

const unloadedState: PlatformV3State = {
    processingSettings: {},
    isLoading: false,
    isSaving: false,
};

export const persistor = (state: PlatformV3State): PlatformV3State => ({
    ...unloadedState,
});

export const reconciler = (stored: PlatformV3State): PlatformV3State => ({
    ...stored,
    isLoading: false,
});

const handlePlatformV3DataLoading = (state: PlatformV3State): PlatformV3State => ({
    ...state,
    isLoading: true,
});

const handlePlatformV3DataLoadError = (state: PlatformV3State): PlatformV3State => ({
    ...state,
    isLoading: false,
});

const handlePlatformV3ProcessingSettingsLoaded = (
    state: PlatformV3State,
    action: PlatformV3ProcessingSettingsLoadedAction,
): PlatformV3State => ({
    ...state,
    isLoading: false,
    processingSettings: {
        [action.country]: {
            ...state.processingSettings[action.country],
            [action.mappingName]: action.mappings,
        },
    },
});

const handlePlatformV3ProcessingSettingsUpdated = (
    state: PlatformV3State,
    action: PlatformV3ProcessingSettingsUpdatedAction,
): PlatformV3State => {
    const oldMappings = state.processingSettings[action.country][
        action.mappingName as MappingName.PharmaceuticalForms | MappingName.ActiveIngredients
    ].items.filter((item) => !action.mappings.includes(item));

    return {
        ...state,
        isLoading: false,
        processingSettings: {
            [action.country]: {
                [action.mappingName]: {
                    items: [...oldMappings, ...action.mappings],
                    total: state.processingSettings[action.country][action.mappingName].total,
                },
                ...state.processingSettings[action.country],
            },
        },
    };
};

const handlePlatformV3ProcessingSettingsNewTermLoaded = (
    state: PlatformV3State,
    action: PlatformV3ProcessingSettingsNewTermAction,
): PlatformV3State => {
    return {
        ...state,
        isLoading: false,
        processingSettings: {
            [action.country]: {
                ...state.processingSettings[action.country],
                [action.mappingName]: {
                    items: [action.newTerm, ...state.processingSettings[action.country][action.mappingName].items],
                    total: state.processingSettings[action.country][action.mappingName].total,
                },
            },
        },
    };
};

export const reducer: Reducer<PlatformV3State> = (
    state: PlatformV3State = unloadedState,
    action: Action,
): PlatformV3State => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.platformV3DataLoading:
            return handlePlatformV3DataLoading(state);
        case Actions.platformV3DataLoadError:
            return handlePlatformV3DataLoadError(state);
        case Actions.platformV3ProcessingSettingsLoaded:
            return handlePlatformV3ProcessingSettingsLoaded(state, action as PlatformV3ProcessingSettingsLoadedAction);
        case Actions.platformV3ProcessingSettingsUpdated:
            return handlePlatformV3ProcessingSettingsUpdated(
                state,
                action as PlatformV3ProcessingSettingsUpdatedAction,
            );
        case Actions.platformV3ProcessingSettingsNewTermLoaded:
            return handlePlatformV3ProcessingSettingsNewTermLoaded(
                state,
                action as PlatformV3ProcessingSettingsNewTermAction,
            );
        default:
            return state;
    }
};
