import * as React from 'react';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';
import Panel, { PanelButtonsContainer } from '../../../components/Panel';
import { Grid, Button, AppBar, Tabs, Tab, Box, Dialog } from '@material-ui/core';
import SingleSelectInput from '../../../components/inputs/SingleSelectInput';
import CountryFlag from '../../../components/CountryFlag';
import { Country } from '../../../models/Country';
import { useCountries } from '../../../store/DomainInfo/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformV3ProcessingSettings, isLoadingPlatformV3Data } from '../../../store/Platform/selectors';
import {
    fetchPlatformV3ProcessingSettings,
    fetchPlatformV3ProcessingSettingsOneRequest,
    savePlatformV3ProcessingSettings,
} from '../../../store/Platform/actions';
import { MappingName, ProcessingSettingsV3 } from '../../../models/ProcessingSettingsV3';
import PharmaceuticalFormTable from './PharmaceuticalFormTable/PharmaceuticalFormTable';
import CategoriesListTable from './CategoriesListTable/CategoriesListTable';
import ActiveIngredientsTable from './ActiveIngredientsTable/ActiveIngredientsTable';
import ActiveIngredientsFinalTable from './ActiveIngredientsFinalTable/ActiveIngredientsFinalTable';
import ATCsTable from './ATCsTable/ATCsTable';
import { FilterByField, FilterFieldNames } from './ProcessingSettingsPanel.types';
import { buildFiltersQuery } from './utils';
import { Pagination } from '@material-ui/lab';
import { ProcessingPformsSettings } from '../../../models/ProcessingPformsSettings';
import { alertDispatch } from '../../../store/Session/actions';
import { AlertType } from '../../../components/AlertBar/AlertBar';
import OverlayComponent from '../../../components/AGPaginatedTable/OverlayComponent';

export const INITIAL_OFFSET = 0;
export const DEFAULT_PAGE_SIZE = 10;
export const FULL_LIST_PAGE_SIZE = 20000;
export const INITIAL_FILTER_TEXT_VALUES = { type: '', value: '' };
export const NO_INDEX = -1;

const ProcessingSettingsPanel: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const countries = useCountries();
    const isLoading = useSelector(isLoadingPlatformV3Data);
    const processingSettings = useSelector(getPlatformV3ProcessingSettings);

    const [originCountry, setOriginCountry] = React.useState<string>('');
    const [tab, setTab] = React.useState<number | null>(null);
    const [isShowFullList, setIsShowFullList] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<ProcessingSettingsV3>({} as ProcessingSettingsV3);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalNumberOfPages, setTotalNumberOfPages] = React.useState<number>(0);
    const [filtersByField, setFiltersByField] = React.useState<
        {
            [key in FilterFieldNames]?: FilterByField;
        }
    >({});
    const [indexToEdit, setIndexToEdit] = React.useState<number>(NO_INDEX);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [dataToSave, setDataToSave] = React.useState<ProcessingPformsSettings[]>([]);
    const [isApproved, setIsApproved] = React.useState<boolean>(false);

    const handleOriginChange = (countryObject: Country) => {
        if (isLoading) {
            dispatch(alertDispatch(AlertType.Info, 'Still Fecthing Information', 5000));
            return;
        }
        setTab(0);
        setIsShowFullList(false);
        setOriginCountry(countryObject.alpha2Code);
    };

    const showContentByTab = (tab: number) => {
        if (isLoading) {
            dispatch(alertDispatch(AlertType.Info, 'Still Fecthing Information', 5000));
            return;
        }
        setIsShowFullList(false);
        setTab(tab);
    };

    const mappingName = React.useMemo(() => {
        switch (tab) {
            case 0:
                return !isShowFullList ? MappingName.PharmaceuticalForms : MappingName.CategoriesList;
            case 1:
                return !isShowFullList ? MappingName.ActiveIngredients : MappingName.ActiveIngredientsFinal;
            case 2:
                return MappingName.ATCs;
            default:
                return MappingName.PharmaceuticalForms;
        }
    }, [tab, isShowFullList]);

    React.useEffect(() => {
        if (!originCountry) {
            return;
        }
        if (isLoading) {
            return;
        }
        setFiltersByField({});
        setIndexToEdit(NO_INDEX);
        setChanged(false);
        setDataToSave([]);
        setCurrentPage(1);
        switch (tab) {
            case 0:
                dispatch(
                    fetchPlatformV3ProcessingSettingsOneRequest({
                        mappingName: MappingName.PharmaceuticalForms,
                        origin: originCountry,
                        pageSize: DEFAULT_PAGE_SIZE,
                        offset: INITIAL_OFFSET,
                        isNewTerm: isApproved || undefined,
                        pageSizeCat: isShowFullList ? DEFAULT_PAGE_SIZE : FULL_LIST_PAGE_SIZE,
                    }),
                );
                return;
            case 1:
                dispatch(
                    fetchPlatformV3ProcessingSettingsOneRequest({
                        mappingName: MappingName.ActiveIngredients,
                        origin: originCountry,
                        pageSize: DEFAULT_PAGE_SIZE,
                        offset: INITIAL_OFFSET,
                        isNewTerm: isApproved || undefined,
                        pageSizeCat: isShowFullList ? DEFAULT_PAGE_SIZE : FULL_LIST_PAGE_SIZE,
                    }),
                );
                return;
            case 2:
                dispatch(
                    fetchPlatformV3ProcessingSettings({
                        mappingName: MappingName.ATCs,
                        origin: originCountry,
                        pageSize: DEFAULT_PAGE_SIZE,
                        offset: INITIAL_OFFSET,
                    }),
                );
                return;
            default:
                return;
        }
    }, [tab, originCountry, isShowFullList, isApproved]);

    React.useEffect(() => {
        originCountry && processingSettings[originCountry] && setValues(processingSettings[originCountry]);
    }, [originCountry, processingSettings]);

    const onChangePageNumber = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
        if (isLoading) {
            dispatch(alertDispatch(AlertType.Info, 'Still Fecthing Information', 5000));
            return;
        }
        setCurrentPage(pageNumber);
    };

    const onFilterChange = React.useCallback(
        (fieldName: FilterFieldNames) => ({ filterType, textValue }: { filterType: string; textValue: string }) => {
            if (isLoading) {
                dispatch(alertDispatch(AlertType.Info, 'Still Fecthing Information', 5000));
                return;
            }
            setFiltersByField({ ...filtersByField, [fieldName]: { value: textValue, type: filterType } });
        },
        [filtersByField, isLoading],
    );

    React.useEffect(() => {
        if (isLoading) {
            return;
        }
        setIndexToEdit(NO_INDEX);
        setChanged(false);
        setDataToSave([]);
        setCurrentPage(1);
        originCountry &&
            dispatch(
                fetchPlatformV3ProcessingSettings({
                    mappingName: mappingName,
                    origin: originCountry,
                    pageSize: DEFAULT_PAGE_SIZE,
                    offset: INITIAL_OFFSET,
                    filters: buildFiltersQuery(filtersByField),
                    isNewTerm: isApproved || undefined,
                }),
            );
    }, [filtersByField]);

    React.useEffect(() => {
        if (isLoading) {
            return;
        }
        setIndexToEdit(NO_INDEX);
        setChanged(false);
        setDataToSave([]);
        originCountry &&
            dispatch(
                fetchPlatformV3ProcessingSettings({
                    mappingName: mappingName,
                    origin: originCountry,
                    pageSize: DEFAULT_PAGE_SIZE,
                    offset: (currentPage - 1) * DEFAULT_PAGE_SIZE,
                    filters: buildFiltersQuery(filtersByField),
                    isNewTerm: isApproved || undefined,
                }),
            );
    }, [currentPage]);

    const handleSave = React.useCallback(() => {
        if (!originCountry) {
            return;
        }
        dispatch(
            savePlatformV3ProcessingSettings({
                mappingName: mappingName,
                origin: originCountry,
                data: dataToSave,
            }),
        );
        setIndexToEdit(NO_INDEX);
        setChanged(false);
        setDataToSave([]);
    }, [mappingName, originCountry, dataToSave, isLoading]);

    return (
        <Panel title={t(TK.processingSettings)}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={6}>
                    <SingleSelectInput
                        label={t(TK.scrapingOrigin)}
                        value={originCountry}
                        options={countries}
                        isLoading={!!!countries?.length}
                        isCustomFilter={true}
                        onChange={handleOriginChange}
                        disabled={isLoading}
                        renderOption={(option: Country): React.ReactNode => (
                            <React.Fragment>
                                <CountryFlag
                                    countryCode={option.alpha2Code}
                                    showCode={false}
                                    country={`${option.name} (${option.alpha2Code})`}
                                />
                            </React.Fragment>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Button
                        disabled={isLoading ? true : tab ? !!(tab > 1) : false}
                        onClick={() => {
                            if (isLoading) {
                                dispatch(alertDispatch(AlertType.Info, 'Still Fecthing Information', 5000));
                                return;
                            }
                            setIsShowFullList(true);
                        }}
                        fullWidth
                        variant="contained"
                    >
                        {t(TK.fullList)}
                    </Button>
                </Grid>
            </Grid>
            {originCountry ? (
                <>
                    <AppBar position="static">
                        <Tabs value={tab} onChange={(_, tab) => showContentByTab(tab)} variant="fullWidth">
                            <Tab label={t(TK.drugForm)} disabled={isLoading} />
                            <Tab label={t(TK.activeSubstances)} disabled={isLoading} />
                            <Tab label={t(TK.atc)} disabled={isLoading} />
                        </Tabs>
                    </AppBar>
                    <Box>
                        <Dialog open={isLoading} maxWidth="lg">
                            <OverlayComponent />
                        </Dialog>
                        {values[MappingName.PharmaceuticalForms] && !isShowFullList && tab === 0 && (
                            <PharmaceuticalFormTable
                                pharmaceuticalForms={values[MappingName.PharmaceuticalForms]}
                                categoriesList={values[MappingName.CategoriesList]}
                                originCountry={originCountry}
                                setChanged={setChanged}
                                setTotalNumberOfPages={setTotalNumberOfPages}
                                onFilterChange={onFilterChange}
                                indexToEdit={indexToEdit}
                                setIndexToEdit={setIndexToEdit}
                                setDataToSave={setDataToSave}
                                setIsApproved={setIsApproved}
                                isApproved={isApproved}
                            />
                        )}
                        {values[MappingName.CategoriesList] && isShowFullList && tab === 0 && (
                            <CategoriesListTable
                                categoriesList={values[MappingName.CategoriesList]}
                                onFilterChange={onFilterChange}
                                setTotalNumberOfPages={setTotalNumberOfPages}
                            />
                        )}
                        {values[MappingName.ActiveIngredients] && !isShowFullList && tab === 1 && (
                            <ActiveIngredientsTable
                                activeIngredients={values[MappingName.ActiveIngredients]}
                                activeIngredientsFinal={values[MappingName.ActiveIngredientsFinal]}
                                originCountry={originCountry}
                                setChanged={setChanged}
                                setTotalNumberOfPages={setTotalNumberOfPages}
                                indexToEdit={indexToEdit}
                                setIndexToEdit={setIndexToEdit}
                                onFilterChange={onFilterChange}
                                setDataToSave={setDataToSave}
                                setIsApproved={setIsApproved}
                                isApproved={isApproved}
                            />
                        )}
                        {values[MappingName.ActiveIngredientsFinal] && isShowFullList && tab == 1 && (
                            <ActiveIngredientsFinalTable
                                activeIngredientsFinal={values[MappingName.ActiveIngredientsFinal]}
                                onFilterChange={onFilterChange}
                                setTotalNumberOfPages={setTotalNumberOfPages}
                            />
                        )}
                        {values[MappingName.ATCs] && tab == 2 && (
                            <ATCsTable aTCs={values[MappingName.ATCs]} setTotalNumberOfPages={setTotalNumberOfPages} />
                        )}
                        {totalNumberOfPages > 1 && (
                            <Box display="flex" justifyContent="right">
                                <Box margin="1">
                                    <Pagination
                                        color="primary"
                                        count={totalNumberOfPages}
                                        page={currentPage}
                                        onChange={onChangePageNumber}
                                        showFirstButton
                                        showLastButton
                                        disabled={isLoading}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {(tab === 0 || tab === 1) && !isShowFullList && (
                        <PanelButtonsContainer>
                            <Button
                                onClick={handleSave}
                                disabled={isLoading || !changed}
                                variant="contained"
                                color="primary"
                            >
                                {t(TK.save)}
                            </Button>
                        </PanelButtonsContainer>
                    )}
                </>
            ) : (
                <></>
            )}
        </Panel>
    );
};

export default ProcessingSettingsPanel;
