import { ApplicationState } from '..';
import { EnquiryNeed, EnquiryNeedComment, EnquiryNeedDetail, EnquiryNeedStatusDetail } from '../../models/EnquiryNeed';

export const getItems = (state: ApplicationState): EnquiryNeed[] => state.enquiry.items;

export const getDetail = (state: ApplicationState): EnquiryNeedDetail => state.enquiry.detail;

export const getTotal = (state: ApplicationState): number => state.enquiry.total;

export const isLoading = (state: ApplicationState): boolean => state.enquiry.isLoading;

export const isSubmitting = (state: ApplicationState): boolean => state.enquiry.isSubmitting;

export const getLastEnquiryCreated = (state: ApplicationState): string => state.enquiry.lastEnquiryCreated || '';

export const isLoadingDetail = (state: ApplicationState): boolean => state.enquiry.isLoadingDetail;

export const isLoadingComments = (state: ApplicationState): boolean => state.enquiry.isLoadingComments;

export const getComments = (state: ApplicationState): EnquiryNeedComment[] => state.enquiry.comments;

export const getEnquiryNeedStatusDetail = (state: ApplicationState): EnquiryNeedStatusDetail =>
    state.enquiry.needStatusDetail;

export const isEnquiryNeedStatusDetailLoading = (state: ApplicationState): boolean =>
    state.enquiry.isLoadingNeedStatusDetail;
