import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import { useHistory } from 'react-router-dom';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { TK } from '../../store/Translations/translationKeys';
import { getSelectedProducts } from '../../store/EnquiryProducts/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Panel from '../../components/Panel';
import { routes } from '../../store/Router/routes';

export enum EnquireCreationStep {
    SelectProducts = 0,
    Configurations,
}

export interface EnquireCreationProgress {
    activeStep: EnquireCreationStep;
}

const EnquireCreationProgress: React.FC<EnquireCreationProgress> = ({ activeStep }) => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const history = useHistory();

    const selectedProducts = useSelector(getSelectedProducts);

    const stepsLabel: string[] = [
        t(TK.productsSelection) +
            (activeStep > EnquireCreationStep.SelectProducts ? ` (${selectedProducts.length})` : ''),
        t(TK.configurations),
    ];

    const isStepFailed = (step: EnquireCreationStep): boolean => {
        if (activeStep <= step) return false;
        switch (step) {
            case EnquireCreationStep.SelectProducts:
                return selectedProducts.length === 0;
            default:
                return false;
        }
    };

    const handleStepClick = (step: EnquireCreationStep): void => {
        if (activeStep > step) {
            switch (step) {
                case EnquireCreationStep.SelectProducts:
                    let productsListPath = routes.enquiryProducts + '/' + window?.localStorage.getItem('queryString');
                    history.push(productsListPath);
                    break;
                case EnquireCreationStep.Configurations:
                    dispatch(push({ pathname: routes.enquiryConfigs }));
                    break;
            }
        }
    };

    return (
        <Panel>
            <Stepper activeStep={activeStep} alternativeLabel>
                {stepsLabel.map((label, idx) => (
                    <Step key={label} style={{ cursor: 'pointer' }} onClick={() => handleStepClick(idx)}>
                        <StepLabel error={isStepFailed(idx)}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Panel>
    );
};

export default EnquireCreationProgress;
