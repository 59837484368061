export interface EnquiryNeed {
    enquiryId?: string;
    id: string;
    needStatus?: EnquiryNeedStatus;
    enquiryNumber?: string;
    needName?: string;
    proposalNumber?: string;
    proposalStatus?: ProposalStatus;
    rfqNumber?: string;
    rfqStatus?: RFQStatus;
    orderNumber?: string;
    orderStatus?: OrderStatus;
    updatedBy?: string;
    updatedAt?: Date;
    createdBy?: string;
    clientName?: string;
    createdAt?: Date;
}

export interface EnquiryNeedComment {
    id?: string;
    idEnquiryNeed?: string;
    value?: string;
    updatedBy?: string;
    updatedAt?: Date;
    createdBy?: string;
    createdAt?: Date;
}

export interface EnquiryNeedDetail {
    productCode?:string;
    id?: string;
    enquiryId?: string;
    enquiryNumber?: string;
    needName?: string;
    countryCode?: string;
    needQuantity?: string;
    acceptAlternative?: boolean;
    acceptGeneric?: boolean;
    isSingleLot?: boolean;
    acceptOtherPackSize?: boolean;
    acceptDifferentCountries?: boolean;
    otherAcceptedCountries?: string;
    otherDocuments?: boolean;
    otherDocumentsNeeded?: string;
    comments?: string;
}

export enum EnquiryNeedDeclineType {
    NotInOurRange = 'notInOurRange',
    OnShortage = 'onShortage',
    NotCommercialized = 'notCommercialized',
    AuthorizationCeased = 'authorizationCeased',
    ExportBan = 'exportBan',
    Other = 'other',
}

export interface EnquiryNeedStatusDetail {
    needName?: string;
    status?: EnquiryNeedStatus;
    declineType?: EnquiryNeedDeclineType;
    customerComments?: string;
}

export enum EnquiryNeedStatus {
    Open = 'Open',
    Accepted = 'Accepted',
    Declined = 'Declined',
    // TODO To be uncommented when added
    // Awaiting = 'Awaiting',
    // Noproposal = "No proposal",
}
export enum ProposalStatus {
    NoOptions = 'No Options',
    // TODO uncomment when new fuction is implemented
    // Open = 'Open',
    // Accepted = 'Accepted',
    // Rejected = 'Rejected',
    // Pending = "Pending",
}
export enum RFQStatus {
    NoOptions = 'No Options',
    // TODO uncomment when new fuction is implemented
    // Open = 'Open',
    // Accepted = 'Accepted',
    // Rejected = 'Rejected',
    // Pending = "Pending",
}

export enum OrderStatus {
    NoOptions = 'No Options',
    // TODO uncomment when new fuction is implemented
    // Open = 'Open',
    // Pending = 'Pending Payment',
    // Accepted = 'Accepted',
    // Declined = 'Declined',
}
