import React, { useRef } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Box, Button, Theme, createStyles, makeStyles } from '@material-ui/core';

import { PageContainer } from './styled';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollableButton: {
            top: '250px',
            right: '30px',
            zIndex: 10,
        },
    }),
);

export interface PageProps {
    title?: string | React.ReactNode;
    actionPanel?: React.ReactNode;
    actionPanelAlign?: 'left' | 'right';
    children?: React.ReactNode;
    style?: React.CSSProperties;
    goBack?: () => void;
}

const Page: React.FC<PageProps> = ({ actionPanel, children, style }) => {
    const classes = useStyles();
    const bottomRef = useRef<HTMLDivElement>(null);

    const [isScrollTopBtn, setIsScrollTopBtn] = React.useState<boolean>(false);

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1400) {
                setIsScrollTopBtn(true);
                const buttonElement: HTMLElement | null = window.document.querySelector<HTMLElement>('#scrollToTop');
                if (buttonElement != null) {
                    buttonElement.style.top = 400 + window.scrollY + 'px';
                }
            } else {
                setIsScrollTopBtn(false);
            }
        };
        const bodyObject: HTMLElement | null = window.document.querySelector<HTMLElement>('#body');

        if (bodyObject != null) {
            bodyObject.style.height = '100%';
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (bodyObject != null) {
                bodyObject.style.height = '100vh';
            }
        };
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <PageContainer style={style}>
            {isScrollTopBtn && (
                <Box position="absolute" id="scrollToTop" className={classes.scrollableButton}>
                    <Button color="primary" variant="contained" onClick={goToTop}>
                        <ExpandLessIcon />
                    </Button>
                </Box>
            )}
            {actionPanel ? actionPanel : null}
            {children}
            <div ref={bottomRef} />
        </PageContainer>
    );
};

export default Page;
