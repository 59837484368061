import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { goToHome } from '../../store/Router/actions';

const NotFound: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const handleGoHome = () => {
        dispatch(goToHome());
    };

    return (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
            <h1 style={{ fontSize: '100px' }}>404</h1>
            <br />
            <h1>Opps... {t(TK.notFound)}.</h1>
            <br />
            <Button
                style={{ cursor: 'pointer' }}
                onClick={handleGoHome}
                variant="contained"
                color="primary"
            >
                {t(TK.goToHome)}
            </Button>
        </div>
    );
};

export default NotFound;
