import { ProductsState } from './state';
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { ProductsLoadedAction, ProductItemAction, ProductAction, ProductDetailsLoadedAction } from './types';

const unloadedState: ProductsState = {
    items: {},
    searchResult: [],
    selectedProducts: [],
    facets: {},
    searchTotal: 0,
    isLoading: false,
    timeInSeconds: 0,
    origins: {},
};

export const persistor = (state: ProductsState): ProductsState => ({
    ...state,
    items: {},
    searchResult: [],
    searchTotal: 0,
    timeInSeconds: 0,
    isLoading: false,
});

export const reconciler = (stored: ProductsState): ProductsState => ({
    ...stored,
    isLoading: false,
});

const handleProductsLoading = (state: ProductsState): ProductsState => ({
    ...state,
    isLoading: true,
});

const handleProductsLoadError = (state: ProductsState): ProductsState => ({
    ...state,
    isLoading: false,
});

const handleProductsLoaded = (state: ProductsState, action: ProductsLoadedAction): ProductsState => ({
    ...state,
    isLoading: false,
    items: Object.assign({}, ...action.result.items.map((p) => ({ [p.id]: p }))),
    searchResult: action.result.items.map((p) => p.id),
    searchTotal: action.result.total,
    timeInSeconds: action.result.timeInSeconds || 0,
    facets: action.result.facets || {},
});

const handleProductDetailsLoaded = (state: ProductsState, action: ProductDetailsLoadedAction): ProductsState => {
    return {
        ...state,
        isLoading: false,
        items: {
            ...state.items,
            [action.result.id]: action.result,
        },
    };
};

const handleProductsSelected = (state: ProductsState, action: ProductAction): ProductsState => {
    const productsToAdd = action.products;
    const newProductsArray = [...state.selectedProducts]
        .filter((oldObj) => !productsToAdd.some((newObj) => newObj.id === oldObj.id))
        .concat(productsToAdd);
    return {
        ...state,
        selectedProducts: newProductsArray,
    };
};

const handleProductsDeselected = (state: ProductsState, action: ProductAction): ProductsState => {
    const productsToDelete = action.products;
    const newProductsArray = [...state.selectedProducts].filter(
        (oldObj) => !productsToDelete.some((newObj) => newObj.id === oldObj.id),
    );
    return {
        ...state,
        selectedProducts: newProductsArray,
    };
};

const addOriginsByProductId = (state: ProductsState, action: ProductItemAction): ProductsState => {
    const productId = action.productId;
    const origins = action?.origins ? [...action.origins] : [];

    let selectedItemsByProductId = { ...state.origins };

    selectedItemsByProductId[productId] = selectedItemsByProductId[productId]
        ? [...new Set([...origins])]
        : [...new Set([...origins])];
    return {
        ...state,
        origins: selectedItemsByProductId,
    };
};

const handleProductsDeselectAll = (state: ProductsState): ProductsState => ({
    ...state,
    selectedProducts: [],
});

export const reducer: Reducer<ProductsState> = (
    state: ProductsState = unloadedState,
    action: Action,
): ProductsState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return reconciler(state);
        case Actions.productsLoading:
            return handleProductsLoading(state);
        case Actions.productsLoadError:
            return handleProductsLoadError(state);
        case Actions.productsLoaded:
            return handleProductsLoaded(state, action as ProductsLoadedAction);
        case Actions.productDetailsLoaded:
            return handleProductDetailsLoaded(state, action as ProductDetailsLoadedAction);
        case Actions.productsSelected:
            return handleProductsSelected(state, action as ProductAction);
        case Actions.productsDeselected:
            return handleProductsDeselected(state, action as ProductAction);
        case Actions.addOriginByProductId:
            return addOriginsByProductId(state, action as ProductItemAction);
        case Actions.productsDeselectAll:
            return handleProductsDeselectAll(state);
        default:
            return state;
    }
};
