import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { parseEuDecimalToDecimal } from '../../../utils/utils';

export default function AGEditableCellRender(props: ICellRendererParams) {
    const handleClick = () => {
        props.api.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column!.getId(),
        });
    };

    return props.colDef?.editable ? (
        <span style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
            <span style={{ paddingLeft: '4px' }}>{props.valueFormatted ? props.valueFormatted : props.value}</span>
            <IconButton
                aria-label="edit"
                size="small"
                color="primary"
                onClick={handleClick}
            >
                <EditIcon fontSize="small" />
            </IconButton>
        </span>
    ) : (
        <span style={{ paddingLeft: '4px' }}>
            {props.column?.getColId() === 'exwNetPriceEuro'
                ? Number(props.value ? props.value : props.data.exwNetPriceEuro).toFixed(2) +
                props.data.currency +
                ' (' +
                parseEuDecimalToDecimal(
                    props.data.priceCurrencyToEuro ? props.data.priceCurrencyToEuro : props.data.exwNetPriceEuro,
                ) +
                '€)'
                : (props.valueFormatted ? props.valueFormatted : props.value)}
        </span>
    );
}
