import { Box, Button, IconButton, Popover } from '@material-ui/core';
import React from 'react';
import { PlatFormFiltersContainer } from './styled';
import SingleSelectInput from '../inputs/SingleSelectInput';
import TextInput from '../inputs/TextInput';
import FilterListIcon from '@material-ui/icons/FilterList';

interface IPlatformFiltersProps {
    elementId: string;
    onTextFilterChange?: (value: string) => void;
    onSelectFilterChange?: (value: string) => void;
    onFilterChange?: ({ filterType, textValue }: { filterType: string; textValue: string }) => void;
}

const filterTypes = ['Contains', 'Not contains', 'Equal', 'Not equal', 'Starts with', 'Ends with'];

const PlatformFilters: React.FC<IPlatformFiltersProps> = ({
    elementId,
    onTextFilterChange,
    onSelectFilterChange,
    onFilterChange,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [textValue, setTextValue] = React.useState('');
    const [selectValue, setSelectValue] = React.useState('');

    const openPopover = Boolean(anchorEl);

    const idPopover = openPopover ? elementId : undefined;

    const onInputChange = React.useCallback(
        (value) => {
            setTextValue(value);
            if (value.length < 3) {
                return;
            }
            onTextFilterChange &&
                setTimeout(() => {
                    onTextFilterChange(value);
                }, 2000);
        },
        [onTextFilterChange, setTextValue],
    );

    const onSingleInputChange = React.useCallback(
        (value) => {
            setSelectValue(value);
            onSelectFilterChange && onSelectFilterChange(value);
        },
        [onSelectFilterChange],
    );

    const onClickReset = React.useCallback(() => {
        setTextValue('');
        setSelectValue('');
        onSelectFilterChange && onSelectFilterChange('');
        onTextFilterChange && onTextFilterChange('');
        onFilterChange && onFilterChange({ filterType: '', textValue: '' });
    }, [setTextValue, setSelectValue, onSelectFilterChange, onTextFilterChange]);

    const onClickApply = React.useCallback(() => {
        onFilterChange && onFilterChange({ filterType: selectValue, textValue: textValue });
    }, [textValue, selectValue]);

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="filter"
                aria-describedby={idPopover}
                size="small"
                color="primary"
                onClick={handlePopoverClick}
            >
                <FilterListIcon fontSize="small" />
            </IconButton>

            <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <PlatFormFiltersContainer>
                    <Box padding="5px">Filter</Box>
                    <Box padding="5px">
                        <SingleSelectInput value={selectValue} options={filterTypes} onChange={onSingleInputChange} />
                    </Box>
                    <Box padding="5px">
                        <TextInput style={{ width: '220px' }} value={textValue} onChange={onInputChange} />
                    </Box>

                    <Box display="flex" justifyContent={onFilterChange ? 'space-between' : 'right'}>
                        {onFilterChange ? (
                            <Box marginTop="10px" marginBottom="10px">
                                <Button onClick={onClickApply} color="primary" variant="contained">
                                    Apply
                                </Button>
                            </Box>
                        ) : (
                            <></>
                        )}
                        <Box marginTop="10px" marginBottom="10px">
                            <Button onClick={onClickReset} color="primary" variant="contained">
                                Reset
                            </Button>
                        </Box>
                    </Box>
                </PlatFormFiltersContainer>
            </Popover>
        </>
    );
};

export default PlatformFilters;
