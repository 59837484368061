import { AppThunkAction } from '..';
import { Action } from 'redux';
import {
    EnquiryLoadedAction,
    EnquirySubmittedAction,
    EnquiryNeedDetailLoadedAction,
    EnquiryNeedDetailCommentsLoadedAction,
    EnquiryNeedStatusDetailLoadedAction,
} from './types';
import { alertError, alertGenericError, alertSuccess, requestServer } from '../Session/actions';
import { EnquiryData } from '../../models/EnquiryData';
import { isEnquiryNeedStatusDetailLoading, isLoading, isSubmitting } from './selectors';
import { goToEnquiryCreated } from '../Router/actions';
import { productsDeselectAll } from '../EnquiryProducts/actions';
import {
    getEnquiriesClientsListAsync,
    getEnquiriesPharmaListAsync,
    postEnquiryAsync,
    getEnquiryNeedDetailAsync,
    getEnquiryNeedStatusDetailAsync,
    putEnquiryNeedPutAprovalAsync,
    getEnquiryNeedCommentsAsync,
    postEnquiryNeedCommentAsync,
} from '../../fetch/requests';
import { EnquiryFiltersClient } from '../../pages/EnquiriesListClients/EnquiriesListClients.types';
import { EnquiryNeed, EnquiryNeedComment, EnquiryNeedDetail, EnquiryNeedStatusDetail } from '../../models/EnquiryNeed';
import { SearchResult } from '../../models/SearchResult';
import { EnquiryFiltersInternal } from '../../pages/EnquiriesListInternal/EnquiriesListInternal.types';
import {
    AddEnquiryNeedComment,
    UpdateEnquiryNeedStatus,
} from '../../pages/EnquiriesListInternal/EnquiriesListInternal';

export const Actions = {
    enquiryLoading: '@@whichpharma.enquiry.loading',
    enquiryLoaded: '@@whichpharma.enquiry.loaded',
    enquiryLoadError: '@@whichpharma.enquiry.loadError',
    enquirySubmitting: '@@whichpharma.enquiry.submitting',
    enquirySubmitted: '@@whichpharma.enquiry.submitted',
    enquirySubmitError: '@@whichpharma.enquiry.submitError',
    enquiryNeedLoading: '@@whichpharma.enquiryNeed.loading',
    enquiryNeedLoaded: '@@whichpharma.enquiryNeed.loaded',
    enquiryNeedLoadError: '@@whichpharma.enquiryNeed.loadError',
    enquiryNeedLoadedComments: '@@whichpharma.enquiryNeed.loadedComments',
    enquiryNeedLoadingComments: '@@whichpharma.enquiryNeed.loadingComments',
    enquiryNeedLoadCommentsError: '@@whichpharma.enquiryNeed.loadCommentsError',
    enquiryNeedStatusDetailLoading: '@@whichpharma.enquiryNeed.statusDetailLoading',
    enquiryNeedStatusDetailLoaded: '@@whichpharma.enquiryNeed.statusDetailLoaded',
    enquiryNeedStatusDetailLoadError: '@@whichpharma.enquiryNeed.statusDetailLoadError',
};

const enquiryLoading = (): Action => ({
    type: Actions.enquiryLoading,
});

const enquiryNeedLoading = (): Action => ({
    type: Actions.enquiryNeedLoading,
});

const enquiryNeedLoadingComments = (): Action => ({
    type: Actions.enquiryNeedLoadingComments,
});

const enquiryLoaded = (result: SearchResult<EnquiryNeed>): EnquiryLoadedAction => {
    return {
        type: Actions.enquiryLoaded,
        result,
    };
};
const enquiryNeedLoaded = (result: EnquiryNeedDetail): EnquiryNeedDetailLoadedAction => {
    return {
        type: Actions.enquiryNeedLoaded,
        detail: result,
    };
};

const enquiryNeedLoadedComments = (result: EnquiryNeedComment[]): EnquiryNeedDetailCommentsLoadedAction => {
    return {
        type: Actions.enquiryNeedLoadedComments,
        comments: result,
    };
};

const enquiryLoadError = (): Action => ({
    type: Actions.enquiryLoadError,
});

const enquiryNeedLoadError = (): Action => ({
    type: Actions.enquiryNeedLoadError,
});

const enquiryNeedStatusDetailLoaded = (
    needStatusDetail: EnquiryNeedStatusDetail,
): EnquiryNeedStatusDetailLoadedAction => {
    return {
        type: Actions.enquiryNeedStatusDetailLoaded,
        needStatusDetail,
    };
};

const enquiryNeedStatusDetailLoading = (): Action => ({
    type: Actions.enquiryNeedStatusDetailLoading,
});

const enquiryNeedStatusDetailLoadError = (): Action => ({
    type: Actions.enquiryNeedStatusDetailLoadError,
});

const enquiryNeedLoadCommentsError = (): Action => ({
    type: Actions.enquiryNeedLoadCommentsError,
});

const enquirySubmitting = (): Action => ({ type: Actions.enquirySubmitting });

const enquirySubmitted = (result: { number: string }): EnquirySubmittedAction => ({
    type: Actions.enquirySubmitted,
    result,
});

const enquirySubmitError = (): Action => ({ type: Actions.enquirySubmitError });

export const fetchEnquiryDetailUnload = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(enquiryNeedLoaded({}));
    };
};

export const fetchEnquiryDetail = (id: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(enquiryNeedLoading());

            const result = await dispatch(
                requestServer((token, csrfToken) => getEnquiryNeedDetailAsync(token, csrfToken, id)),
            );
            dispatch(enquiryNeedLoaded(result));
        } catch (e) {
            console.log(e);
            dispatch(enquiryNeedLoadError());
        }
    };
};

export const unloadEnquiryDetailStatusDetail = (): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        dispatch(enquiryNeedStatusDetailLoaded({}));
    };
};

export const fetchEnquiryNeedStatusDetail = (id: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isEnquiryNeedStatusDetailLoading(getState())) {
                return;
            }
            dispatch(enquiryNeedStatusDetailLoading());

            const result = await dispatch(
                requestServer((token, csrfToken) => getEnquiryNeedStatusDetailAsync(token, csrfToken, id)),
            );
            dispatch(enquiryNeedStatusDetailLoaded(result));
        } catch (e) {
            console.log(e);
            dispatch(enquiryNeedStatusDetailLoadError());
        }
    };
};

export const fetchEnquiries = (filters: EnquiryFiltersClient): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoading(getState())) {
                return;
            }
            dispatch(enquiryLoading());

            const result = await dispatch(
                requestServer((token, csrfToken) => getEnquiriesClientsListAsync(token, csrfToken, filters)),
            );
            dispatch(enquiryLoaded(result));
        } catch (e) {
            console.log(e);
            dispatch(enquiryLoadError());
        }
    };
};

export const updateApprovalStatus = (
    needId: string,
    update: UpdateEnquiryNeedStatus,
): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            await dispatch(
                requestServer((token, csrfToken) => putEnquiryNeedPutAprovalAsync(needId, token, csrfToken, update)),
            );
            if (update.approvalStatus == 'Accepted') dispatch(alertSuccess('Enquiry need sucessfull approved'));
            else dispatch(alertSuccess('Enquiry need sucessfull declined'));
        } catch (e) {
            console.log(e);
            if (update.approvalStatus == 'Accepted') dispatch(alertError('Enquiry need approved failed'));
            else dispatch(alertError('Enquiry need declined failed'));
        }
    };
};

export const addNeedComment = (add: AddEnquiryNeedComment): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(enquiryNeedLoadedComments([]));
            await dispatch(requestServer((token, csrfToken) => postEnquiryNeedCommentAsync(token, csrfToken, add)));
        } catch (e) {
            console.log(e);
        }
    };
};

export const fetchEnquiryNeedComments = (needId: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(enquiryNeedLoadingComments());
            const comments = (
                await dispatch(
                    requestServer((token, csrfToken) => getEnquiryNeedCommentsAsync(token, csrfToken, needId)),
                )
            ).sort(
                (old, curr) =>
                    new Date(curr.createdAt?.toString()!).getTime() - new Date(old.createdAt?.toString()!).getTime(),
            );
            dispatch(enquiryNeedLoadedComments(comments));
        } catch (e) {
            console.log(e);
            dispatch(enquiryNeedLoadCommentsError());
        }
    };
};

export const fetchEnquiriesRbPharma = (filters: EnquiryFiltersInternal): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoading(getState())) {
                return;
            }
            dispatch(enquiryLoading());

            const result = await dispatch(
                requestServer((token, csrfToken) => getEnquiriesPharmaListAsync(token, csrfToken, filters)),
            );
            dispatch(enquiryLoaded(result));
        } catch (e) {
            console.log(e);
            dispatch(enquiryLoadError());
        }
    };
};

export const submitEnquiry = (data: EnquiryData): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isSubmitting(getState())) {
                return;
            }
            dispatch(enquirySubmitting());
            const result = await dispatch(
                requestServer((token, csrfToken) => postEnquiryAsync({ data, token, csrfToken })),
            );
            dispatch(enquirySubmitted(result));
            dispatch(goToEnquiryCreated());
            dispatch(productsDeselectAll());
        } catch (e) {
            dispatch(enquirySubmitError());
            dispatch(alertGenericError());
        }
    };
};
