import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';

const TermsAndConditionsDialog: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
    const t = useTranslations();

    return (
        <Dialog open={open} maxWidth="md" onClose={onClose}>
            <DialogTitle>{t(TK.termsAndConditions)}</DialogTitle>
            <DialogContent>
                <ol>
                    <li>
                        <Typography>
                            Acceptance of Terms
                            <br />
                            <br />
                            By accessing or using this platform (referred to as "the Platform"), you
                            acknowledge that you have read, understood, and agree to be bound by these
                            Terms and Conditions. If you do not agree to these terms, please do not use
                            the Platform.
                        </Typography>
                    </li>
                    <hr />
                    <li>
                        <Typography>
                            Use of the Platform
                            <br />
                            <br />
                            2.1 User Eligibility: You must be at least 18 years old and capable of forming a
                            legally binding contract to use the Platform. If you are using the Platform on
                            behalf of a company or organization, you represent that you have the
                            authority to bind that entity to these Terms and Conditions.
                            <br />
                            <br />
                            2.2 Account Registration: To use certain features of the Platform, you may be
                            required to register an account. You agree to provide accurate, complete, and
                            up-to-date information during the registration process and to maintain the
                            confidentiality of your account credentials.
                            <br />
                            <br />
                            2.3 Platform Purpose: The Platform is designed to facilitate the exchange of
                            quotes from suppliers to customers in response to customer inquiries. The
                            Platform does not endorse or guarantee the quality, accuracy, or suitability of
                            any quotes or services provided by suppliers.
                        </Typography>
                    </li>
                    <hr />
                    <li>
                        <Typography>
                            Supplier Quotes
                            <br />
                            <br />
                            3.1 Submission of Quotes: Suppliers may submit quotes to customer inquiries
                            through the Platform. Quotes should be accurate, complete, and in
                            compliance with all applicable laws and regulations.
                            <br />
                            <br />
                            3.2 Quote Acceptance: Customer acceptance of a supplier's quote constitutes
                            a legally binding agreement between the customer and the supplier. The
                            Platform is not a party to any such agreement.
                            <br />
                            <br />
                            3.3 Supplier Responsibility: Suppliers are solely responsible for the quotes they
                            submit and the services they provide. The Platform does not assume any
                            liability for disputes between suppliers and customers.
                        </Typography>
                    </li>
                    <hr />
                    <li>
                        <Typography>Customer Inquiries</Typography>
                        <br />
                        <Typography>
                            4.1 Inquiry Submission: Customers may submit inquiries through the Platform
                            seeking quotes from suppliers. Customers are responsible for providing
                            accurate and complete information in their inquiries.
                        </Typography>
                        <br />
                        <Typography>
                            4.2 Privacy: Customer inquiries may include personal or sensitive information.
                            Suppliers agree to handle such information in accordance with applicable
                            privacy laws and regulations and the Platform's Privacy Policy.
                        </Typography>

                    </li>
                    <hr />
                    <li>
                        <Typography>
                            Platform Fees
                        </Typography>
                        <br />
                        <Typography>
                            The Platform may charge fees for the use of certain features or services. Any
                            fees and payment terms will be communicated in advance.
                        </Typography>
                    </li>
                    <hr />
                    <li>
                        <Typography>Termination</Typography>
                        <br />
                        <Typography>
                            The Platform reserves the right to terminate or suspend user accounts or
                            access to the Platform for violations of these Terms and Conditions or any
                            unlawful or improper use of the Platform.
                        </Typography>
                        
                    </li>
                    <hr />
                    <li>
                        <Typography>Disclaimer of Liability</Typography>
                        <br />
                        <Typography>
                            The Platform is provided "as is" and "as available." We make no
                            representations or warranties regarding the accuracy, reliability, or availability
                            of the Platform.
                        </Typography>
                    </li>
                    <hr />
                    <li>
                        <Typography>
                            Governing Law
                        </Typography>
                        <br />
                        <Typography>
                            These Terms and Conditions are governed by the laws of Portugal. Any
                            disputes arising from the use of the Platform shall be subject to the exclusive
                            jurisdiction of the courts in Portugal.
                        </Typography>
                    </li>
                    <hr />
                    <li>
                        <Typography>
                            Contact Information
                        </Typography>
                        <br />
                        <Typography>
                            For questions or concerns regarding these Terms and Conditions, please
                            contact us at admin@whichpharma.pt.
                        </Typography>
                    </li>
                </ol>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button color="primary" onClick={onClose} variant="contained">
                    {t(TK.close)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TermsAndConditionsDialog;
