import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Input from '../../components/inputs/Input';

import { GridCenter } from '../Register/Register.styles';

import { alertGenericError, alertSuccess, requestServer } from '../../store/Session/actions';
import { putRegistCodePasswordAsync, verifyConfirmationCodeAsync } from '../../fetch/requests';
import { EnableUser } from '../../models/EnableUser';
import { goToLogin } from '../../store/Router/actions';

const RegistrationConfirmation: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const [codeIsValid, setCodeIsValid] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [validPass, setValidPass] = React.useState(true);
    const [disabled, setDisabled] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const _passwordStrengthRegexFunction = (value: string) => /^(?=.*[A-Za-z])(?=.*\d).{4,}$/g.test(value);

    const params = new URLSearchParams(window.location.search);
    const code = params.get('confirmationCode');

    const verifyCode = React.useCallback(async (code) => {
        const data = await verifyConfirmationCodeAsync(code);
        setCodeIsValid(data);
    }, []);

    React.useEffect(() => {
        code && verifyCode(code);
    }, [verifyCode, code]);

    const handlePassword = (password: string) => {
        setPassword(password);
    };

    const handleConfirmPassword = (confirmPassword: string) => {
        setConfirmPassword(confirmPassword);
    };

    const onBlurPassword = (e: any) => {
        setValidPass(_passwordStrengthRegexFunction(password));
    };

    const handleRegistration = () => {
        if (code && validPass && password === confirmPassword) {
            const user: EnableUser = { confirmationCode: code, password: password };
            setDisabled(true);
            enableUser(user);
        }
    };

    const enableUser = React.useCallback(async (user) => {
        try {
            await dispatch(requestServer(() => putRegistCodePasswordAsync(user)));
            dispatch(alertSuccess(t(TK.registrationCompletedSuccess)));
            setSuccess(true);
        } catch (e) {
            dispatch(alertGenericError());
        }
    }, []);
    const handleGoLogin = () => {
        dispatch(goToLogin());
    };

    return (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
            <h1>{t(TK.welcomeWixmed)}</h1>
            {codeIsValid && !success && (
                <>
                    <p>{t(TK.definePassword)}</p>
                    <p style={{ fontSize: '12px', marginTop: '-15px', fontStyle: 'italic' }}>
                        {t(TK.passwordRole)}
                    </p>

                    <Grid container spacing={2} style={{}}>
                        <GridCenter item xs={12}>
                            <GridCenter item xs={6}>
                                <div style={{ display: 'block', width: '100%' }}>
                                    <Input
                                        label={t(TK.password)}
                                        value={password}
                                        type="password"
                                        required
                                        error={!validPass ? t(TK.passwordError) : ''}
                                        onChange={handlePassword}
                                        onBlur={onBlurPassword}
                                        disabled={disabled}
                                    />
                                </div>
                            </GridCenter>
                        </GridCenter>
                        <GridCenter item xs={12}>
                            <GridCenter item xs={6}>
                                <Input
                                    label={t(TK.confirmPassword)}
                                    value={confirmPassword}
                                    type="password"
                                    required
                                    error={password !== confirmPassword ? t(TK.passwordMustMatch) : ''}
                                    onChange={handleConfirmPassword}
                                    disabled={disabled}
                                />
                            </GridCenter>
                        </GridCenter>
                    </Grid>
                    <br />
                    <Button
                        style={{ cursor: 'pointer' }}
                        onClick={handleRegistration}
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                    >
                        {t(TK.submit)}
                    </Button>
                </>
            )}
            {!codeIsValid && <p>{t(TK.confLinkNotValid)}.</p>}

            {success && (
                <>
                    <p>{t(TK.registrationCompletedSuccess)}!</p>
                    <Button style={{ cursor: 'pointer' }} onClick={handleGoLogin} variant="contained" color="primary">
                        {t(TK.login)}
                    </Button>
                </>
            )}
        </div>
    );
};

export default RegistrationConfirmation;
