import { AppThunkAction } from '..';
import { Action } from 'redux';
import { getPaginatedProductsAsync } from '../../fetch/requests';
import { EnquireProductsLoadedAction, EnquireProductAction } from './types';
import { SearchResult } from '../../models/SearchResult';
import { alertGenericError, requestServer } from '../Session/actions';
import { ProductV2 } from '../../models/ProductV2';
import { ProductsSearchFilters } from '../../components/ProductsSearchList/ProductsSearchList.types';

export const Actions = {
    productsLoading: '@@whichpharma.enquireProducts.loading',
    productsLoaded: '@@whichpharma.enquireProducts.loaded',
    productsLoadError: '@@whichpharma.enquireProducts.loadError',
    productsSelected: '@@whichpharma.enquireProducts.productsSelected',
    productsDeselected: '@@whichpharma.enquireProducts.productsDeselected',
    productsDeselectAll: '@@whichpharma.enquireProducts.productsDeselectAll',
};

const productsLoading = (): Action => ({
    type: Actions.productsLoading,
});

export const productsLoaded = (result: SearchResult<ProductV2>): EnquireProductsLoadedAction => ({
    type: Actions.productsLoaded,
    result,
});

const productsLoadError = (): Action => ({
    type: Actions.productsLoadError,
});

export const productsDeselectAll = (): Action => ({
    type: Actions.productsDeselectAll,
});

export const productsSelected = (products: ProductV2[]): EnquireProductAction => ({
    type: Actions.productsSelected,
    products,
});

export const productsDeselected = (products: ProductV2[]): EnquireProductAction => ({
    type: Actions.productsDeselected,
    products,
});

export const fetchProducts = (filters: ProductsSearchFilters, offset: number): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            if (!((filters.countries && filters.countries.length) || filters.free)) {
                console.log('Please provide a list of countries or free text');
                dispatch(productsLoaded({ items: [], total: 0, timeInSeconds: 0 }));
                return;
            }

            dispatch(productsLoading());

            const startTime = new Date().getTime();

            const result = await dispatch(
                requestServer((token, csrfToken) =>
                    getPaginatedProductsAsync({
                        isMarketing: filters.isMarketed!,
                        isEuropean:
                            filters.isEuropean === undefined || filters.isEuropean === 0
                                ? undefined
                                : filters.isEuropean === 1
                                ? true
                                : false,
                        countries: filters.countries,
                        refCountries: filters.refCountries,
                        offset,
                        pageSize: filters.pageSize,
                        orderBy: filters.orderBy,
                        free: filters.free || '',
                        filters: filters.filters,
                        token,
                        csrfToken,
                    }),
                ),
            );

            const endsTime = new Date().getTime();
            let timeInSeconds = 0;
            if (result.total > 0) {
                timeInSeconds = Math.round((endsTime - startTime) / 1000);
                result.timeInSeconds = timeInSeconds;
            }

            dispatch(productsLoaded(result || []));
        } catch (e) {
            console.log(e);
            dispatch(productsLoadError());
            dispatch(alertGenericError());
        }
    };
};
