import React, { useMemo } from 'react';
import { Dialog, DialogContentText, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { StatusDetailDialogProps } from './StatusDetailDialog.types';
import { DialogContentStyled, DialogTitleContainerStyled, DialogTitleStyled } from './StatusDetailDialog.styles';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { ChipNeedStatus } from '../../pages/EnquiriesListClients/EnquiriesListClients.styles';
import { EnquiryNeedStatus } from '../../models/EnquiryNeed';

const StatusDetailDialog: React.FC<StatusDetailDialogProps> = ({ statusDetail, handleStatusDetailDialogClose }) => {
    const t = useTranslations();

    const declineTypeValue = statusDetail.declineType
        ? statusDetail.declineType.charAt(0).toLowerCase() + statusDetail.declineType.slice(1)
        : undefined;

    const statusDetailMessage = useMemo(() => {
        switch (statusDetail.status) {
            case EnquiryNeedStatus.Open:
                return TK.statusDetailOpenMessage;
            case EnquiryNeedStatus.Accepted:
                return TK.statusDetailAcceptedMessage;
            default:
                return undefined;
        }
    }, [statusDetail]);

    return (
        <Dialog open={!!statusDetail.status} onClose={handleStatusDetailDialogClose} maxWidth="lg">
            <DialogTitleContainerStyled>
                <DialogTitleStyled id="alert-dialog-title">
                    <ChipNeedStatus status={statusDetail.status} label={statusDetail.status} size="small" />
                    <span>{statusDetail.needName}</span>
                </DialogTitleStyled>
                <IconButton onClick={handleStatusDetailDialogClose}>
                    <HighlightOffIcon color="primary" fontSize="large" />
                </IconButton>
            </DialogTitleContainerStyled>
            <DialogContentStyled>
                {statusDetailMessage ? <DialogContentText>{statusDetailMessage}</DialogContentText> : <></>}
                {declineTypeValue ? (
                    <DialogContentText>{`${t(TK.reason)}: ${t(
                        TK[declineTypeValue as keyof typeof TK],
                    )}`}</DialogContentText>
                ) : (
                    <></>
                )}
                {statusDetail.customerComments ? (
                    <DialogContentText>{`${t(TK.notes)}: ${statusDetail.customerComments}`}</DialogContentText>
                ) : (
                    <></>
                )}
            </DialogContentStyled>
        </Dialog>
    );
};

export default StatusDetailDialog;
