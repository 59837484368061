import React from 'react';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Button, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FC } from 'react';
import { SubmitConfirmationDialogProps } from './SubmitConfirmationDialog.types';
import {
    CloseIconWrapperStyled,
    DialogContentStyled,
    DialogTitleContainerStyled,
} from './SubmitConfirmationDialog.styles';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';

const SubmitConfirmationDialog: FC<SubmitConfirmationDialogProps> = ({
    open,
    setOpen,
    confirmationMessage,
    onConfirm,
    dialogTitle,
}) => {
    const t = useTranslations();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} style={{ width: '100%' }}>
            <DialogTitleContainerStyled>
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <CloseIconWrapperStyled>
                    <IconButton onClick={handleClose}>
                        <HighlightOffIcon color="primary" fontSize="large" />
                    </IconButton>
                </CloseIconWrapperStyled>
            </DialogTitleContainerStyled>
            <DialogContentStyled>
                <DialogContentText>{confirmationMessage}</DialogContentText>
            </DialogContentStyled>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        onConfirm();
                        handleClose();
                    }}
                >
                    {t(TK.yes)}
                </Button>
                <Button color="primary" variant="contained" onClick={handleClose}>
                    {t(TK.no)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SubmitConfirmationDialog;
