import { AppThunkAction } from '..';
import { getUsersAsync, putUserAsync, RequestError, postUserAsync, getUserAsync } from '../../fetch/requests';
import { requestServer, alertGenericError, alertError, alertSuccess } from '../Session/actions';
import { UsersUpdatedAction, UserAction } from './types';
import { User } from '../../models/User';
import { Action } from 'redux';
import { getTranslation } from '../Translations/selectors';
import { TK } from '../Translations/translationKeys';
import { HTTP_BAD_REQUEST } from '../../fetch/statusCodes';
import { UserChangeResult } from '../../models/UserChangeResult';
import { CreateUser, RegisterCompany } from '../../models/CreateUser';
import { getUser, getMyUser, isUpdatingUsers } from './selectors';
import { assignDefined } from '../../utils/utils';
import { formatUsers } from '../../utils/formatUsers';
import { goToHome } from '../Router/actions';

export const Actions = {
    usersUpdating: '@@whichpharma.users.usersUpdating',
    usersUpdated: '@@whichpharma.users.usersUpdated',
    userUpdated: '@@whichpharma.users.userUpdated',
    userCreated: '@@whichpharma.users.userCreated',
    usersError: '@@whichpharma.users.usersError',
};

const usersUpdating = (): Action => ({
    type: Actions.usersUpdating,
});

const usersError = (): Action => ({
    type: Actions.usersError,
});

export const usersUpdated = (users: User[]): UsersUpdatedAction => ({
    type: Actions.usersUpdated,
    users,
});

const userUpdated = (user: User): UserAction => ({
    type: Actions.userUpdated,
    user,
});

const userCreated = (user: User): UserAction => ({
    type: Actions.userCreated,
    user,
});

export const fetchUsers = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState) => {
        try {
            if (isUpdatingUsers(getState())) {
                return;
            }
            dispatch(usersUpdating());
            const result = await dispatch(requestServer((token, csrfToken) => getUsersAsync(token, csrfToken)));
            dispatch(usersUpdated(formatUsers(result)));
        } catch (e) {
            dispatch(usersError());
            dispatch(alertGenericError());
        }
    };
};

export const fetchUser = (username: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState) => {
        try {
            var user = getUser(getState(), username);

            if (!user) return;

            dispatch(usersUpdating());
            const result = await dispatch(
                requestServer((token, csrfToken) => getUserAsync(username, token, csrfToken)),
            );
            dispatch(usersUpdated(formatUsers([result])));
        } catch (e) {
            dispatch(usersError());
            dispatch(alertGenericError());
        }
    };
};

const getErrorMessage = (error: any): TK => {
    console.log('ERRROR', error);
    const result: UserChangeResult | undefined =
        (error instanceof RequestError &&
            (error as RequestError).statusCode === HTTP_BAD_REQUEST &&
            ((error as RequestError).body?.result as UserChangeResult)) ||
        undefined;

    switch (result) {
        case UserChangeResult.EmailInUse:
            return TK.emailInUse;
        case UserChangeResult.WeakPassword:
            return TK.weakPassword;
        case UserChangeResult.UsernameInUse:
            return TK.usernameInUse;
        case UserChangeResult.InvalidUsername:
            return TK.invalidUsername;
        case UserChangeResult.InvalidEmail:
            return TK.invalidEmail;
        default:
            return TK.somethingWentWrong;
    }
};

function delay(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

// TODO Repliment when API BackEndExists
export const registerCompanyAction = (create: RegisterCompany): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState) => {
        try {
            dispatch(usersUpdating());
            await delay(5000);
            dispatch(goToHome());
            dispatch(alertSuccess(getTranslation(getState(), TK.userCreatedSuccessfully)));
        } catch (e) {
            dispatch(alertError(getTranslation(getState(), getErrorMessage(e))));
            dispatch(usersError());
        }
    };
};

export const createUser = (create: CreateUser): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState) => {
        try {
            dispatch(usersUpdating());
            await dispatch(
                requestServer((token, csrfToken) =>
                    postUserAsync(formatUsers([create])[0] as CreateUser, token, csrfToken),
                ),
            );
            dispatch(userCreated({ ...create }));

            dispatch(alertSuccess(getTranslation(getState(), TK.userCreatedSuccessfully)));
        } catch (e) {
            dispatch(alertError(getTranslation(getState(), getErrorMessage(e))));
            dispatch(usersError());
        }
    };
};

export const updateUser = (update: CreateUser): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState) => {
        try {
            var user = getMyUser(getState());

            if (!user) return;

            dispatch(usersUpdating());
            await dispatch(
                requestServer((token, csrfToken) =>
                    putUserAsync(formatUsers([update])[0] as CreateUser, token, csrfToken),
                ),
            );
            dispatch(userUpdated(assignDefined(user, update)));

            dispatch(alertSuccess(getTranslation(getState(), TK.userUpdatedSuccessfully)));
        } catch (e) {
            dispatch(alertError(getTranslation(getState(), getErrorMessage(e))));
            dispatch(usersError());
        }
    };
};
