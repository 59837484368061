import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { PFormFullListTableContainer } from './CategoriesListTable.styles';
import { CategoriesListTableV3Props } from './CategoriesListTable.types';
import PlatformFilters from '../../../../components/Filter';
import { FilterFieldNames } from '../ProcessingSettingsPanel.types';
import { DEFAULT_PAGE_SIZE } from '../ProcessingSettingsPanel';

const CategoriesListTable: React.FC<CategoriesListTableV3Props> = ({
    categoriesList,
    onFilterChange,
    setTotalNumberOfPages,
}) => {
    React.useEffect(() => {
        setTotalNumberOfPages(Math.ceil(categoriesList.total / DEFAULT_PAGE_SIZE));
    }, [categoriesList]);

    return (
        <PFormFullListTableContainer>
            <div>Total: {categoriesList.total}</div>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Category
                            <PlatformFilters
                                elementId="category-popover"
                                onFilterChange={onFilterChange(FilterFieldNames.Category)}
                            />
                        </TableCell>
                        <TableCell>
                            Sub-Category
                            <PlatformFilters
                                elementId="subCategory-popover"
                                onFilterChange={onFilterChange(FilterFieldNames.SubCategory)}
                            />
                        </TableCell>
                        <TableCell>
                            Pharmaceutical Phorm
                            <PlatformFilters
                                elementId="pharmaceuticalPhorm-popover"
                                onFilterChange={onFilterChange(FilterFieldNames.PharmaceuticalPhorm)}
                            />
                        </TableCell>
                        <TableCell>
                            Administration Route
                            <PlatformFilters
                                elementId="administrationRoute-popover"
                                onFilterChange={onFilterChange(FilterFieldNames.AdministrationRoute)}
                            />
                        </TableCell>
                        <TableCell>Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categoriesList.items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.category}</TableCell>
                            <TableCell>{item.subCategory}</TableCell>
                            <TableCell>{item.pharmaceuticalPhorm}</TableCell>
                            <TableCell>{item.administrationRoute}</TableCell>
                            <TableCell>
                                Last updated by:
                                <br />
                                {item?.user}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </PFormFullListTableContainer>
    );
};

export default CategoriesListTable;
