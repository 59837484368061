import { User } from '../models/User';

export const formatUsers = (users: User[]) => {
    const newUsersArray = users.map((user) => {
        if (user.userName) {
            return { ...user, username: user.userName };
        } else {
            return user;
        }
    });
    return newUsersArray;
};
