import styled from 'styled-components';
import { Chip, ChipProps } from '@material-ui/core';
import { MapOf } from '../../utils/Types';

const needStatusColor = {
    Awaiting: { backgroundColor: '#f7ac3d', color: 'black' },
    Open: { backgroundColor: '#00bcd4', color: 'black' },
    Accepted: { backgroundColor: 'green', color: 'white' },
    'No proposal': { backgroundColor: 'black', color: 'white' },
    Declined: { backgroundColor: 'red', color: 'white' },
} as MapOf<any>;

const orderstatusColor = {
    'Pending Payment': { backgroundColor: '#f7ac3d', color: 'black' },
    Open: { backgroundColor: '#00bcd4', color: 'black' },
    Accepted: { backgroundColor: 'green', color: 'white' },
    Declined: { backgroundColor: 'red', color: 'white' },
} as MapOf<any>;

const propStatusColor = {
    Pending: { backgroundColor: '#f7ac3d', color: 'black' },
    Open: { backgroundColor: '#00bcd4', color: 'black' },
    Accepted: { backgroundColor: 'green', color: 'white' },
    Rejected: { backgroundColor: 'red', color: 'white' },
} as MapOf<any>;

const rfqStatusColor = {
    Pending: { backgroundColor: '#f7ac3d', color: 'black' },
    Analysis: { backgroundColor: '#00bcd4', color: 'black' },
    Open: { backgroundColor: 'green', color: 'white' },
    Rejected: { backgroundColor: 'red', color: 'white' },
} as MapOf<any>;

export const ChipNeedStatus = styled(Chip)<ChipProps & { status: any }>`
    ${(p) => needStatusColor[p.status]}
`;
export const ChipOrderStatus = styled(Chip)<ChipProps & { status: any }>`
    ${(p) => orderstatusColor[p.status]}
`;
export const ChipPropStatus = styled(Chip)<ChipProps & { status: any }>`
    ${(p) => propStatusColor[p.status]}
`;
export const ChipRFQStatus = styled(Chip)<ChipProps & { status: any }>`
    ${(p) => rfqStatusColor[p.status]}
`;
