import { ColDef } from 'ag-grid-community';
import { EnquiryNeed } from '../../models/EnquiryNeed';
import { TK } from '../../store/Translations/translationKeys';
import { Link } from '@material-ui/core';

import * as React from 'react';
import moment from 'moment';
import { DateTimeFormatWithotSeconds } from '../../components/Table/DataTypeFormatter';
import { ChipNeedStatus, ChipOrderStatus, ChipPropStatus } from './EnquiriesListClients.styles';

export function columnsArray(
    getNeedDetail: (id: string) => void,
    getEnquiryNeedStatusDetail: (id: string) => void,
): ColDef<EnquiryNeed>[] {
    return [
        {
            field: 'Enquiry',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerName: TK.enquiry,
            wrapText: true,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            autoHeight: true,
            menuTabs: [],
            sortable: true,
            headerCheckboxSelectionFilteredOnly: true,
            valueGetter: (p) => p.data?.enquiryNumber,
            cellRenderer: (p: any) => {
                if (!p.data.enquiryNumber) return <>-</>;
                return <>{'Enq. ' + p.data.enquiryNumber}</>;
            },
        },
        {
            field: 'Status',
            headerName: TK.status,
            wrapText: true,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            autoHeight: true,
            menuTabs: [],
            sortable: true,
            valueGetter: (p) => p.data?.needStatus,
            cellRenderer: (p: any) => {
                if (!p.data.needStatus) return <></>;
                return (
                    <ChipNeedStatus
                        onClick={() => {
                            getEnquiryNeedStatusDetail(p.data.id);
                        }}
                        status={p.data.needStatus}
                        label={p.data.needStatus}
                        size="small"
                    />
                );
            },
        },
        {
            field: 'Need',
            headerName: TK.need,
            wrapText: true,
            cellStyle: {
                paddingRight: '20px',
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
            },
            autoHeight: true,
            menuTabs: [],
            sortable: true,
            valueGetter: (p) => p.data?.needName,
            cellRenderer: (p: any) => {
                if (!p.data.needName) return <></>;
                return <Link onClick={() => getNeedDetail(p.data.id)}>{p.data.needName}</Link>;
            },
        },
        {
            field: 'Proposal',
            headerName: TK.proposal,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.proposalNumber,
            cellRenderer: (p: any) => {
                if (!p.data.proposalNumber) return <>-</>;
                return <>{'P. ' + p.data.proposalNumber}</>;
            },
        },
        {
            field: 'Proposal Status',
            headerName: TK.proposalStatus,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.proposalStatus,
            cellRenderer: (p: any) => {
                if (!p.data.proposalStatus) return <></>;
                return <ChipPropStatus status={p.data.proposalStatus} size="small" label={p.data.proposalStatus} />;
            },
        },
        {
            field: 'Order',
            headerName: TK.order,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.orderNumber,
            cellRenderer: (p: any) => {
                if (!p.data.orderNumber) return <>-</>;
                return <>{'O. ' + p.data.orderNumber}</>;
            },
        },
        {
            field: 'Order Status',
            headerName: TK.orderStatus,
            maxWidth: 180,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.orderStatus,
            cellRenderer: (p: any) => {
                if (!p.data.orderStatus) return <></>;
                return <ChipOrderStatus status={p.data.orderStatus} size="small" label={p.data.orderStatus} />;
            },
        },
        {
            field: 'Last Update',
            headerName: 'Last Update',
            autoHeight: true,
            wrapText: true,
            maxWidth: 210,
            cellStyle: {
                paddingRight: '20px',
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
            },
            menuTabs: [],
            sortable: true,
            valueGetter: (p) => p.data?.updatedAt,
            cellRenderer: (p: any) => {
                if (!p.data.updatedAt) return <></>;
                return <>{moment(p.data.updatedAt).format(DateTimeFormatWithotSeconds) + ', by ' + p.data.updatedBy}</>;
            },
        },
    ];
}
