import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { getLastEnquiryCreated } from '../../store/Enquiry/selectors';
import { ButtonPanel, SuccessIcon, SuccessPanel, SuccessParagraph } from './EnquiryCreated.styles';
import { Button, Typography } from '@material-ui/core';
import { goToEnquiriesList } from '../../store/Router/actions';

const EnquiryCreated: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const enquiryNumber = useSelector(getLastEnquiryCreated);

    return (
        <Page title={t(TK.enquiryCreated)}>
            <SuccessPanel>
                <SuccessParagraph>
                    <SuccessIcon />
                    <Typography variant="h5">{t(TK.enquiryCreatedMessage).replace('0000', enquiryNumber)}</Typography>
                </SuccessParagraph>
            </SuccessPanel>
            <ButtonPanel>
                <Button variant="contained" color="primary" onClick={() => dispatch(goToEnquiriesList())}>
                    {t(TK.goTo) + t(TK.myEnquiries)}
                </Button>
            </ButtonPanel>
        </Page>
    );
};

export default EnquiryCreated;
