import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Layout from './Layout';
import './custom.css';
import { routes } from '../store/Router/routes';
import { createMuiTheme, ThemeProvider as MaterialUiThemeProvider, StylesProvider } from '@material-ui/core';
import theme from '../theme';
import { useDispatch, useSelector } from 'react-redux';
import { alertDismiss } from '../store/Session/actions';
import { getAlerts } from '../store/Session/selectors';
import AlertBar from '../components/AlertBar';
import RestrictedRoute from '../components/RestrictedRoute';
import { UserRole } from '../models/UserRole';
import { fetchTranslations } from '../store/Translations/actions';
import { AppContextType } from '../context/@types/types';

import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import Profile from '../pages/Profile/Profile';
import Users from '../pages/Users/Users';
import Platform from '../pages/Platform/Platform';
import EnquireProducts from '../pages/EnquireProducts/EnquireProducts';
import EnquireCreationConfiguration from '../pages/EnquireCreationConfiguration/EnquireCreationConfiguration';
import RFQProducts from '../pages/RFQProducts/RFQProducts';
import SuppliersList from '../pages/SuppliersList/SuppliersList';
import CommunicationSetup from '../pages/CommunicationSetup/CommunicationSetup';
import RFQCreated from '../pages/RFQCreated/RFQCreated';
import RFQsList from '../pages/RFQsList/RFQsList';
import RFQDetails from '../pages/RFQDetails/RFQDetails';
import RFQEnquire from '../pages/RFQEnquire/RFQEnquire';
import SupplierReplyForm from '../pages/SupplierReplyForm/SupplierReplyForm';
import EnquiryCreated from '../pages/EnquiryCreated/EnquiryCreated';
import EnquiriesListClient from '../pages/EnquiriesListClients/EnquiriesListClients';
import EnquiriesListRBPharma from '../pages/EnquiriesListInternal/EnquiriesListInternal';
import Grey from '../pages/Grey/Grey';
import Register from '../pages/Register/Register';
import NotFound from '../pages/NotFound/NotFound';
import RegistrationConfirmation from '../pages/RegistrationConfirmation/RegistrationConfirmation';

const remToPx = (value: string): number => parseFloat((value.match(/(.\d)+/) as any)[0]) * theme.metrics.fontSize;

export const AppContext = React.createContext<AppContextType | null>({
    headerName: '',
    setHeaderName: (name: string) => {},
    isOpen: false,
    handleDrawerToggle: () => {},
});

const App: React.FC = () => {
    const [headerName, setHeaderName] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);

    const dispatch = useDispatch();
    const alerts = useSelector(getAlerts);
    const dismissAlert = (id: string) => dispatch(alertDismiss(id));

    const handleDrawerToggle = React.useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const providerValue = React.useMemo(
        () => ({
            headerName,
            setHeaderName,
            isOpen,
            handleDrawerToggle,
        }),
        [headerName, isOpen],
    );

    React.useEffect(() => {
        dispatch(fetchTranslations());
    }, [dispatch]);

    const materialUiTheme = createMuiTheme({
        typography: {
            fontFamily: theme.typography.fontFamily,
            h5: {
                fontWeight: 800,
            },
        },
        palette: {
            primary: {
                main: theme.colors.RBGreen,
                light: theme.colors.lightRBGreen,
                dark: theme.colors.darkenRBGreen,
                contrastText: theme.colors.white,
            },
            secondary: {
                main: theme.colors.grey,
                light: theme.colors.lightGrey,
                dark: theme.colors.darkGreen,
                contrastText: theme.colors.darkGreen,
            },
            text: {
                primary: theme.colors.black,
                secondary: theme.colors.darkGreen,
            },
        },
        breakpoints: {
            values: { ...theme.metrics.breakpoints },
        },
        shape: {
            borderRadius: remToPx(theme.metrics.border.radius),
        },
    });

    return (
        <StylesProvider injectFirst>
            <MaterialUiThemeProvider theme={materialUiTheme}>
                <AppContext.Provider value={providerValue}>
                    <AlertBar alerts={alerts} alertDismissed={dismissAlert} />
                    <Layout>
                        <Switch>
                            {/* TODO: Review requiredRoles logic for external access */}
                            <RestrictedRoute requiresLogin exact path={routes.home} component={Home} />
                            <RestrictedRoute requiresLogout path={routes.login} component={Login} />

                            <Route path={routes.logout} component={Logout} />
                            <Route path={routes.register} component={Register} />
                            {/* TODO: Test page for 2 multiple requests */}
                            <Route path={routes.grey} component={Grey} />
                            <RestrictedRoute requiresLogin path={routes.profile} component={Profile} />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.users}
                                component={Users}
                                requiresRoles={[UserRole.Administrator]}
                            />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.platform}
                                component={Platform}
                                requiresRoles={[UserRole.PlatformContributor]}
                            />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.enquiryProducts}
                                component={EnquireProducts}
                                requiresRoles={[UserRole.Collaborator, UserRole.Customer, UserRole.PlatformContributor]}
                            />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.enquiryConfigs}
                                component={EnquireCreationConfiguration}
                                requiresRoles={[UserRole.Collaborator, UserRole.Customer]}
                            />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.enquiryCreated}
                                component={EnquiryCreated}
                                requiresRoles={[UserRole.Collaborator, UserRole.Customer]}
                            />
                            <RestrictedRoute
                                requiresRoles={[UserRole.Customer]}
                                path={routes.enquiryList}
                                component={EnquiriesListClient}
                            />
                            <RestrictedRoute
                                requiresRoles={[UserRole.Collaborator]}
                                path={routes.enquiryListInternal}
                                component={EnquiriesListRBPharma}
                            />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.rfqProducts}
                                requiresRoles={[
                                    UserRole.Collaborator,
                                    UserRole.PlatformContributor,
                                    UserRole.Administrator,
                                ]}
                                component={RFQProducts}
                            />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.rfqSuppliers(':offset?')}
                                component={SuppliersList}
                            />
                            <RestrictedRoute requiresLogin path={routes.rfqConfigs} component={CommunicationSetup} />
                            <RestrictedRoute requiresLogin path={routes.rfqCreated} component={RFQCreated} />
                            <RestrictedRoute
                                exact
                                requiresLogin
                                path={routes.rfqList}
                                component={RFQsList}
                                requiresRoles={[UserRole.Collaborator]}
                            />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.rfqDetail(':rfqNrEncoded', false)}
                                component={RFQDetails}
                                requiresRoles={[UserRole.Collaborator]}
                            />
                            <RestrictedRoute
                                requiresLogin
                                path={routes.rfqSimulation(':rfqNrEncoded', false)}
                                component={RFQEnquire}
                                requiresRoles={[UserRole.Collaborator]}
                            />
                            <RestrictedRoute
                                requiresLogout
                                path={routes.registrationConfirmation}
                                component={RegistrationConfirmation}
                            />

                            <Route path={routes.rfqReply} component={SupplierReplyForm} />
                            <Route path={routes.notFound} component={NotFound} />
                            <Redirect to={routes.notFound} />
                        </Switch>
                    </Layout>
                </AppContext.Provider>
            </MaterialUiThemeProvider>
        </StylesProvider>
    );
};

export default App;
