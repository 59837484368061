export interface IRoutes {
    home: string;
    login: string;
    logout: string;
    profile: string;
    users: string;
    platform: string;

    /* Create Enquires */
    enquiryProducts: string;
    enquiryConfigs: string;
    enquiryCreated: string;

    /* List Enquires */
    enquiryList: string;
    enquiryListInternal: string;

    /* Create RFQs */
    rfqProducts: string;
    rfqSuppliers: (offset?: number | string) => string;
    rfqConfigs: string;
    rfqCreated: string;

    /* List RFQs */
    rfqList: string;
    rfqDetail: (rfqNr: string, encode?: boolean) => string;
    rfqSimulation: (rfqNr: string, encode?: boolean) => string;
    /* Register */
    register: string;
    /* Reply RFQs */
    rfqReply: string;

    /* Test Page*/
    grey: string;

    /* Not Found page*/
    notFound: string;

    /* Registration Confirmation page*/
    registrationConfirmation: string;
}

export const routes: IRoutes = {
    home: '/',
    /* Home - @src\pages\Home\Home.tsx */
    /* 
    goToHome - @src\store\Router\actions.ts
        RestritedComponent - @src\components\RestrictedComponent\index.tsx
            RestrictedRoute - @src\components\RestrictedRoute\index.tsx
                App - @src\app\App.tsx
    doLogin - @src\store\Session\actions.ts
        Login - @src\pages\Login\Login.tsx
     */
    login: '/login',
    /* Login - @src\pages\Login\Login.tsx */
    /* 
    goToLogin - @src\store\Router\actions.ts
        RestritedComponent - @src\components\RestrictedComponent\index.tsx
            RestrictedRoute - @src\components\RestrictedRoute\index.tsx
                App - @src\app\App.tsx
        doLogout - @src\store\Session\actions.ts
            Logout - @src\pages\Logout\Logout.tsx
    */
    logout: '/logout',
    /* Logout - @src\pages\Logout\Logout.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx
    */
    profile: '/profile',
    /* Profile - @src\pages\Profile\Profile.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx
    */
    users: '/internal/users',
    /* Users - @src\pages\Users\Users.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx
    */
    platform: '/internal/platform',
    /* Platform - @src\pages\Platform\Platform.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx
    */
    enquiryProducts: '/customer/enquiry/products',
    /* EnquireProducts - @src\pages\EnquireProducts\EnquireProducts.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx
    Home - @src\pages\Home\Home.tsx
    EnquireCreationProgress - @src\modues\EnquireCreationProgress\EnquireCreationProgress.tsx
    goToEnquireProducts - @src\store\Router\actions.ts
        EnquireProducts - @src\pages\EnquireProducts\EnquireProducts.tsx
    */
    enquiryConfigs: '/customer/enquiry/form',
    /* EnquireCreationConfiguration - @src\pages\EnquireCreationConfiguration\EnquireCreationConfiguration.tsx */
    /*
    goToCreateEnquireConfiguratiosn - @src\store\Router\actions.ts
        EnquireProducts - @src\pages\EnquireProducts\EnquireProducts.tsx
    */
    enquiryCreated: '/customer/enquiry/created',
    enquiryListInternal: '/internal/enquiry/list',
    enquiryList: '/customer/enquiry/list',
    /* EnquiriesList - @src\pages\EnquireListClients\EnquiriesListClients.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx
    */
    rfqProducts: '/internal/rfq/products',
    /* RFQProducts - @src\pages\RFQProducts\RFQProducts.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx
    goToPaginatedProducts - @src\store\Router\actions.ts
        Home - @src\pages\Home\Home.tsx
        RFQProducts - @src\pages\RFQProducts\RFQProducts.tsx
        RFQsListV3 - @src\pages\RFQsList\RFQsList.tsx
    */
    rfqSuppliers: (offset?: number | string) => `/internal/rfq/suppliers/${offset || ''}`,
    /* SuppliersList - @src\pages\SuppliersList\SuppliersList.tsx */
    /* 
    goToSuppliers - @src\store\Router\actions.ts
        RFQProducts - @src\pages\RFQProducts\RFQProducts.tsx
    */
    rfqConfigs: '/internal/rfq/form',
    /* CommunicationSetup - @src\pages\CommunicationSetup\CommunicationSetup.tsx */
    /* 
    goToCommunicationConfig - @src\store\Router\actions.ts
        SuppliersList - @src\pages\SuppliersList\SuppliersList.tsx
    */
    rfqCreated: '/internal/rfq/created',
    /* RFQCreated - @src\pages\RFQCreated\RFQCreated.tsx */
    /* 
    goToRFQCreated - @src\store\Router\actions.ts
        submitRFQs - @src\store\RFQs\actions.ts
            CommunicationSetup - @src\pages\CommunicationSetup\CommunicationSetup.tsx
    */
    rfqList: '/internal/rfq/list',
    /* RFQsList - @src\pages\RFQsList\RFQsList.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx
    goToRFQsList - @src\store\Router\actions.ts
        RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx
        RFQsList - @src\pages\RFQsList\RFQsList.tsx
    */
    rfqDetail: (rfqNr: string, encode: boolean = true) =>
        `/internal/rfq/detail/${encode ? encodeURIComponent(rfqNr) : rfqNr}`,
    /* RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx */
    /* 
    goToRFQDetails - @src\store\Router\actions.ts
        RFQsList - @src\pages\RFQsList\RFQsList.tsx
    */
    rfqSimulation: (rfqNr: string, encode: boolean = true) =>
        `/internal/rfq/simulation/${encode ? encodeURIComponent(rfqNr) : rfqNr}`,
    /* RFQEnquire - @src\pages\RFQEnquire\RFQEnquire.tsx */
    /* 
    goToRFQEnquire - @src\store\Router\actions.ts
        RFQsList - @src\pages\RFQsList\RFQsList.tsx
    */
    rfqReply: '/supplier/rfq/reply',
    /* SupplierReplyForm - @src\pages\SupplierReplyForm\SupplierReplyForm.tsx */
    /* 
    NavMenu - @src\app\NavMenu\NavMenu.tsx // TODO: delete?
    Home - @src\pages\Home\Home.tsx
    HttpReplyForm - @src\pages\RFQDetails\HttpReplyForm
        tableColsDefinition - @src\pages\RFQDetails\columns.tsx
            RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx
    */

    grey: '/grey',

    /* NotFound - @src\pages\NotFound\index.tsx */
    notFound: '/notfound',

    register: '/register',

    /* RegistrationConfirmation - @src\pages\RegistrationConfirmation\index.tsx */
    registrationConfirmation: '/registrationconfirmation',
};

export const standaloneRoutes = [routes.rfqReply, routes.notFound, routes.register];
