import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import {
    EnquiryLoadedAction,
    EnquirySubmittedAction,
    EnquiryNeedDetailLoadedAction,
    EnquiryNeedDetailCommentsLoadedAction,
    EnquiryNeedStatusDetailLoadedAction,
} from './types';
import { EnquiryState } from './state';

const unloadedState: EnquiryState = {
    items: [],
    total: 0,
    isLoading: false,
    isSubmitting: false,
    detail: {},
    isLoadingDetail: false,
    isLoadingComments: false,
    comments: [],
    isLoadingNeedStatusDetail: false,
    needStatusDetail: {},
};

export const persistor = (state: EnquiryState): EnquiryState => ({
    ...unloadedState,
});

export const reconciler = (stored: EnquiryState): EnquiryState => ({
    ...stored,
    isLoading: false,
    isSubmitting: false,
    isLoadingDetail: false,
    isLoadingComments: false,
    isLoadingNeedStatusDetail: false,
});

const handleRFQEnquireLoading = (state: EnquiryState): EnquiryState => ({
    ...state,
    isLoading: true,
});

const handleNeedDetailCommentsLoading = (state: EnquiryState): EnquiryState => ({
    ...state,
    isLoadingComments: true,
});

const handleRFQEnquiryLoadError = (state: EnquiryState): EnquiryState => ({
    ...state,
    isLoading: false,
});

const handleNeedDetailCommentsLoadError = (state: EnquiryState): EnquiryState => ({
    ...state,
    isLoadingComments: false,
});

const handleRFQEnquiryLoaded = (state: EnquiryState, action: EnquiryLoadedAction): EnquiryState => ({
    ...state,
    isLoading: false,
    items: action.result.items,
    total: action.result.total,
});



const handleNeedDetailLoading = (state: EnquiryState): EnquiryState => ({
    ...state,
    isLoadingDetail: true,
});

const handleNeedDetailLoadError = (state: EnquiryState): EnquiryState => ({
    ...state,
    isLoadingDetail: false,
});

const handleNeedDetailLoaded = (state: EnquiryState, action: EnquiryNeedDetailLoadedAction): EnquiryState => ({
    ...state,
    isLoadingDetail: false,
    detail: action.detail,
});
const handleNeedDetailCommentsLoaded = (
    state: EnquiryState,
    action: EnquiryNeedDetailCommentsLoadedAction,
): EnquiryState => ({
    ...state,
    isLoadingComments: false,
    comments: action.comments,
});

const handleNeedStatusDetailLoading = (state: EnquiryState): EnquiryState => ({
    ...state,
    isLoadingNeedStatusDetail: true,
});

const handleNeedStatusDetailLoadError = (state: EnquiryState): EnquiryState => ({
    ...state,
    isLoadingNeedStatusDetail: false,
});

const handleNeedStatusDetailLoaded = (
    state: EnquiryState,
    action: EnquiryNeedStatusDetailLoadedAction,
): EnquiryState => ({
    ...state,
    isLoadingNeedStatusDetail: false,
    needStatusDetail: action.needStatusDetail,
});

const handleEnquirySubmitting = (state: EnquiryState): EnquiryState => ({
    ...state,
    isSubmitting: true,
});

const handleEnquirySubmitError = (state: EnquiryState): EnquiryState => ({
    ...state,
    isSubmitting: false,
});

const handleEnquirySubmitted = (state: EnquiryState, action: EnquirySubmittedAction): EnquiryState => ({
    ...state,
    isSubmitting: false,
    lastEnquiryCreated: action.result.number,
});

export const reducer: Reducer<EnquiryState> = (state: EnquiryState = unloadedState, action: Action): EnquiryState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.enquiryLoading:
            return handleRFQEnquireLoading(state);
        case Actions.enquiryLoadError:
            return handleRFQEnquiryLoadError(state);
        case Actions.enquiryLoaded:
            return handleRFQEnquiryLoaded(state, action as EnquiryLoadedAction);
        case Actions.enquirySubmitting:
            return handleEnquirySubmitting(state);
        case Actions.enquirySubmitError:
            return handleEnquirySubmitError(state);
        case Actions.enquirySubmitted:
            return handleEnquirySubmitted(state, action as EnquirySubmittedAction);
        case Actions.enquiryNeedLoading:
            return handleNeedDetailLoading(state);
        case Actions.enquiryNeedLoadError:
            return handleNeedDetailLoadError(state);
        case Actions.enquiryNeedLoaded:
            return handleNeedDetailLoaded(state, action as EnquiryNeedDetailLoadedAction);
        case Actions.enquiryNeedLoadingComments:
            return handleNeedDetailCommentsLoading(state);
        case Actions.enquiryNeedLoadCommentsError:
            return handleNeedDetailCommentsLoadError(state);
        case Actions.enquiryNeedLoadedComments:
            return handleNeedDetailCommentsLoaded(state, action as EnquiryNeedDetailCommentsLoadedAction);
        case Actions.enquiryNeedStatusDetailLoading:
            return handleNeedStatusDetailLoading(state);
        case Actions.enquiryNeedStatusDetailLoadError:
            return handleNeedStatusDetailLoadError(state);
        case Actions.enquiryNeedStatusDetailLoaded:
            return handleNeedStatusDetailLoaded(state, action as EnquiryNeedStatusDetailLoadedAction);
        default:
            return state;
    }
};
