import * as React from 'react';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import ProcessingSettingsPanel from './ProcessingSettingsPanel/ProcessingSettingsPanel';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';

const Platform: React.FC = () => {
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();

    React.useEffect(() => {
        setHeaderName(t(TK.platformV3));
    }, []);

    return (
        <Page title={t(TK.platformV3)} style={{ marginTop: '10rem' }}>
            <ProcessingSettingsPanel />
        </Page>
    );
};

export default Platform;
