import { AppThunkAction } from '..';
import { getAdditionalDocumentsAsync, getCountriesAsync, getTransportersAsync } from '../../fetch/requests';
import { DomainInfoUpdatedAction, DomainInfoAction } from './types';
import { needsUpdate } from './selectors';
import { DomainInfoName } from './state';
import { requestServer } from '../Session/actions';

export const Actions = {
    domainInfoUpdating: '@@whichpharma.domainInfo.domainInfoUpdating',
    domainInfoUpdated: '@@whichpharma.domainInfo.domainInfoUpdated',
    domainInfoUpdateError: '@@whichpharma.domainInfo.domainInfoUpdateError',
};

const domainInfoUpdating = (domainInfoName: DomainInfoName): DomainInfoAction => ({
    type: Actions.domainInfoUpdating,
    domainInfoName,
});

const domainInfoUpdated = (domainInfoName: DomainInfoName, values: any[]): DomainInfoUpdatedAction => {
    return {
        type: Actions.domainInfoUpdated,
        domainInfoName,
        values,
    };
};

const domainInfoUpdateError = (domainInfoName: DomainInfoName): DomainInfoAction => ({
    type: Actions.domainInfoUpdateError,
    domainInfoName,
});

// Same logic as the old products sets route, but with multiple routes. Add as needed.
export const fetchDomainInfo = (domainInfoName: DomainInfoName): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            const state = getState();

            if (needsUpdate(state, domainInfoName)) {
                dispatch(domainInfoUpdating(domainInfoName));
                const result = await dispatch(
                    requestServer((token, csrfToken) => {
                        switch (domainInfoName) {
                            case DomainInfoName.Countries:
                                return getCountriesAsync(token, csrfToken);
                            /* case DomainInfoName.Transporters:
                                return getTransportersAsync(token, csrfToken);
                            case DomainInfoName.AdditionalDocuments:
                                return getAdditionalDocumentsAsync(token, csrfToken); */
                            default:
                                return Promise.resolve([] as any[]);
                        }
                    }),
                );
                dispatch(domainInfoUpdated(domainInfoName, result as any[]));
            }
        } catch (e) {
            console.log(e);
            dispatch(domainInfoUpdateError(domainInfoName));
        }
    };
};
