import { DialogContent, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';

export const DialogTitleContainerStyled = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.brightGreen};
    font-weight: 200;
    color: ${({ theme }) => theme.colors.textRBGreen};
`;

export const DialogTitleStyled = styled(DialogTitle)`
    & h2 {
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;

export const DialogContentStyled = styled(DialogContent)`
    margin-top: 10px;
`;
