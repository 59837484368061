import { RFQQuote } from '../../models/RFQQuote';

export interface RFQ {
    id: string;
    autoId: string;
    useExistingRfq: boolean;
    description: string;
    dueDate: string;
    packSize: number;
    unitQuant: string;
    isAlternative: boolean;
    items: string[];
}

export interface EmailData {
    countryCode: string;
    subject: string;
    table: RFQQuote[];
}

export enum FiltersKey {
    Free = 'free',
    Countries = 'countries',
    Name = 'name',
    ActiveSubstances = 'activeSubstances',
    Atc = 'atc',
    PharmaceuticalForms = 'drugForms',
    AdministrationForms = 'administrationForms',
    ProductCode = 'productCode',
    Holder = 'holder',
    Origins = 'origins',
    IsAuthorised = 'isAuthorised',
    IsMarketed = 'isMarketed',
}

export interface Filters {
    [filterKey: string]: string | string[] | null | undefined;
}

