import { ProcessingPformsSettings } from '../../../models/ProcessingPformsSettings';
import { ProcessingCatSettings } from '../../../models/ProcessingCatSettings';
import { ProcessingDciFullSettings } from '../../../models/ProcessingDciFullSettings';
import { ProcessingATCSettings } from '../../../models/ProcessingATCSettings';

export enum FilterFieldNames {
    Original = 'original',
    Translated = 'translated',
    Final = 'final',
    User = 'user',
    Category = 'category',
    SubCategory = 'subCategory',
    PharmaceuticalPhorm = 'pharmaceuticalPhorm',
    AdministrationRoute = 'administrationRoute',
    ActiveSubs = 'activeSubs',
}

export interface FilterByField {
    type: string;
    value: string;
}

export type MapArray = (
    | ProcessingPformsSettings
    | ProcessingCatSettings
    | ProcessingDciFullSettings
    | ProcessingATCSettings
)[];
