import { Action, Reducer } from 'redux';
import { TranslationsState } from './state';
import { Actions } from './actions';
import { TranslationsUpdatedAction } from './types';

const initialState: TranslationsState = {
    locale: 'en-US',
    translations: {
        or: 'or',
        from: 'From',
        until: 'Until',
        additionalNotes: 'Notes',
        next: 'Next',
        authorised: 'Authorised',
        marketedProducts: 'Marketed Products',
        euProducts: 'EU Products',
        nonEuProducts: 'Non EU Products',
        marketed: 'Marketed',
        unavailable: 'Unavailable',
        discontinued: 'Discontinued',
        toQuote: 'To Quote',
        authorisedeu: 'Authorised EU',
        parallelimport: 'Parallel Import',
        previous: 'Previous',
        loading: 'Loading',
        country: 'Country',
        scrapingOrigin: 'Origin',
        scrapingOriginId: 'Origin ID',
        lastUpdate: 'Last update',
        name: 'Name',
        documents: 'Documents',
        activeSubstances: 'Active Substance(s)',
        atc: 'ATC',
        errorRequest: 'Could not process your request. Please try again later.',
        manufacturer: 'Manufacturer',
        drugForm: 'Pharmaceutical Form',
        pharmaceuticalForm: 'Pharmaceutical form',
        administration: 'Administration',
        administrationForm: 'Administration Form',
        drugFormCategories: 'Pharmaceutical Form (Categories)',
        administrationFormCategories: 'Administration (Categories)',
        shortage: 'Shortage',
        offers: 'Offers',
        shortages: 'Shortages',
        strength: 'Strength',
        package: 'Package',
        maNumber: 'MA Number',
        maHolder: 'MA Holder',
        status: 'Status',
        statusNotes: 'Notes',
        currencyCode: 'Currency',
        retailPrice: 'Retail Price',
        reimbursementPrice: 'Reimbursement Price',
        wholesalePrice: 'Wholesale Price',
        factoryPrice: 'Factory Price',
        exFactoryPrice: 'ExFactory Price',
        all: 'All',
        filters: 'Filters',
        countries: 'Countries',
        none: 'None',
        selectedProducts: 'Selected products',
        noProductSelected: 'No product selected',
        productsSelection: 'Products selection',
        proceedToRFQCreation: 'Create RFQs',
        proceedToSuppliersSelection: 'Proceed to Suppliers selection',
        productCode: 'Product code',
        welcome: 'Welcome',
        username: 'Username',
        password: 'Password',
        confirmPassword: 'Confirm password',
        haveYouForgottenYourPassword: 'Forgot your password?',
        packSize: 'Pack size',
        enter: 'Enter',
        areYouSure: 'Are you sure?',
        newPasswordWillBeSent: 'Instruction to reset your password will be sent to your e-mail.',
        resetPassword: 'Reset password',
        cancel: 'Cancel',
        somethingWentWrong: 'Oops, something went wrong.',
        invalidCredentials: 'Invalid username or password.',
        products: 'Products',
        logout: 'Logout',
        login: 'Login',
        users: 'Users',
        roles: 'Roles',
        weakPassword: 'Password is too weak',
        usernameInUse: 'Username in use',
        emailInUse: 'E-mail in use',
        invalidUsername: 'Invalid username',
        invalidEmail: 'Invalid E-mail',
        invalidPassword: 'Invalid password',
        addNew: 'Add new',
        update: 'Update',
        create: 'Create',
        userUpdatedSuccessfully: 'User updated successfully',
        userCreatedSuccessfully: 'User created successfully',
        accessRestricted: 'Access restricted!',
        loggedOut: 'Logged out!',
        suppliersSelection: 'Suppliers selection',
        id: 'ID',
        contacts: 'Contacts',
        classification: 'Classification',
        type: 'Type',
        isEurope: 'Is Europe',
        notes: 'Notes',
        expiryDate: 'Expiry Date',
        lastVerification: 'Last Verification',
        creationDate: 'Created',
        selectedSuppliers: 'Selected suppliers',
        noSupplierSelected: 'No supplier selected',
        emailsSelection: 'E-mails selection',
        proceedToEmailConfiguration: 'Proceed to Configurations',
        configurations: 'Configurations',
        confirmation: 'Confirmation',
        deselectAll: 'Deselect all',
        rfqs: 'RFQs',
        productsList: 'Products List',
        createRequestForQuote: 'Request Quote',
        rfqsList: 'RFQs List',
        profile: 'Profile',
        dontHaveRfqsYetCreateYourFirst:
            'Seem you have no open RFQs yet, click on the plus sign up there to create your first one!',
        suppliers: 'Suppliers',
        active: 'Active',
        inactive: 'Inactive',
        closed: 'Closed',
        noQuote: 'No Quote',
        unviewedReplies: 'Unread messages',
        emailContent: 'E-mail body',
        user: 'User',
        supplier: 'Supplier',
        product: 'Product',
        others: 'Others',
        configureEmailContentMessage: 'Configure the content of the e-mail.',
        price: 'Price',
        prices: 'Prices',
        quantity: 'Quantity',
        quantityRequested: 'Quantity requested (units)',
        availableAt: 'Available from',
        lotNumber: 'Lot Number',
        quoteReplyForm: 'Quote Reply Form',
        configureQuoteReplyForm: 'Configure the fields which will be requested to the supplier on the reply form.',
        mandatoryFields: 'Mandatory fields',
        optionalFields: 'Optional fields',
        noOptions: 'No options',
        add: 'Add',
        send: 'Send',
        createRfqAndSendTheEmails: 'Create RFQ and send all e-mails now',
        sendUsingOtherAccount: 'Send using other account',
        rfqCreated: 'RFQ Created',
        original: 'Original',
        withVAT: 'With VAT',
        withoutVAT: 'Without VAT',
        noData: 'No data',
        noColumns: 'No columns visible',
        hideColumns: 'Hide columns',
        fromToOf: '{0}-{1} of {2}',
        rowsPerPage: 'Rows per page',
        showAll: 'Show all',
        export: 'Export',
        exportVisibleItems: 'Export visible items',
        exportSelectedItems: 'Export selected items',
        dragColumnToGroup: 'Drag a column header here to group by that column',
        sort: 'Sort',
        search: 'Search',
        advancedSearch: 'Advanced Search',
        clearAllFilters: 'Clear all filters',
        clearFilters: 'Clear filters',
        yes: 'Yes',
        no: 'No',
        productNotes: 'Product notes',
        start: 'Start',
        end: 'End',
        minStart: 'Minimum Start date',
        maxStart: 'Maximum Start date',
        minEnd: 'Minimum End date',
        maxEnd: 'Maximum End date',
        onlyActive: 'Only active',
        partialshortage: 'Partial Shortage',
        showHideTablePageFilters: 'Show/Hide page results filters',
        contains: 'Contains',
        notContains: 'Does not contain',
        startsWith: 'Starts width',
        endsWith: 'Ends width',
        equal: 'Equals',
        notEqual: 'Does not equal',
        greaterThan: 'Greater than',
        greaterThanOrEqual: 'Greater than or equal',
        lessThan: 'Less than',
        lessThanOrEqual: 'Less than or equal',
        categories: 'Categories',
        pil: 'PIL',
        spc: 'SPC',
        file: 'File',
        label: 'Label',
        resetTableSettings: 'Reset table settings',
        freeTextSearch: 'Free text search',
        freeTextSearchDescription: 'Search for anything in (name, atc, active ingredient)',
        productCodeOrMANumber: 'Product code / MA Number',
        manufacturerOrMAHolder: 'Manufacturer / MA Holder',
        empty: 'Empty',
        close: 'Close',
        ok: 'OK',
        defaultBehavior: 'Default behavior',
        freeTextSearchDefaultBehaviorInfo:
            "This filter allows you to search for anything on any field. By default, it will search for all individual words separately. For example, if you search by '100 mg', it will find all items containing '100' or 'mg'. Note that it will search by word, not part of it, for that kind of search please use the advanced search.",
        mandatoryWords: 'Mandatory words',
        freeTextSearchMandatoryWordsInfo:
            "If you want to make a word mandatory on the results, you can put in inside quotes. For example, if you search by '100 200 \"mg\"', it will get all items that obligatorily contain 'mg', and also contain '100' or '200'.",
        exactPhrase: 'Exact phrase',
        freeTextSearchExactPhraseInfo:
            'To find an exact phrase put it inside quotes. For example, \'"100 mg"\' will get all items that contain the exact words for the exact order.',
        excludeWords: 'Exclude words',
        freeTextSearchExcludeWordsInfo:
            "You can also exclude words using the minus operator. For example, if you search by '100 -mg', it will find all items containing the word '100' and do not contain the word 'mg'.",
        email: 'E-mail',
        accountSettings: 'Account settings',
        rfqNr: 'RFQ No',
        unitQuant: 'Quantity',
        totalUnits: 'Total units',
        exwNetPriceEuro: 'Pack Price',
        netPriceEuro: 'Net Price',
        priceEuro: 'Price (€)',
        availabilityPacks: 'Availability',
        availabilityVisuals: 'Av. graph',
        leadTimeToDeliver: 'Lead time',
        expDate: 'Exp. date',
        countryOfOrigin: 'Origin',
        precForStorage: 'T(ºC)',
        comments: 'Comments',
        productsTables: 'Products tables',
        seeAndConfigureTheTablesInEmails: 'Validate and configure the products tables sent by Country.',
        sendingTo: 'Sending to',
        markAsValidated: 'Mark as validated',
        productsToAssociate: 'Products to associate',
        pleaseMarkAllProductsTablesAsVerified: 'Please mark all products tables as verified',
        pleaseMakeSureAllRFQsHaveAValidNumber: 'Please make sure all RFQs have a valid number',
        pleaseAssociateAllProductsToAnRFQ: 'Please associate all products to a RFQ',
        pleaseMakeSureAllRFQsHaveAValidDescription: 'Please make sure all RFQs have a valid description',
        rfqAssociation: 'RFQs association',
        dragAndDropToAssignProductsToARFQ: 'Drag and drop products to assign to a RFQ',
        allProductsAreAssociated: 'All products are associated',
        theEmailBodyCannotBeEmpty: 'The e-mail body cannot be empty',
        subject: 'Subject',
        default: 'Default',
        remove: 'Remove',
        saveThisTemplate: 'Save this template',
        removeThisTemplate: 'Remove this template from the saved templates',
        reset: 'Reset',
        autoFillFields: 'Auto fill fields',
        selectAccount: 'Select account',
        addAccount: 'Add account',
        firstName: 'First name',
        lastName: 'Last name',
        creatingRFQsAndSendingEmails: 'Creating RFQ(s) and sending e-mail(s)...',
        rfqsCreatedAndEmailsSent: 'created and e-mail(s) sent',
        pleaseVerifyYourOutbox: 'Please confirm in your outbox',
        mailbox: 'Mailbox',
        title: 'Title',
        supplierFirstNameMissing: 'Supplier first name is missing',
        supplierLastNameMissing: 'Supplier last name is missing',
        userFirstNameMissing: 'User first name is missing. Please configure it in your profile settings',
        userLastNameMissing: 'User last name is missing. Please configure it in your profile settings',
        userEmailMissing: 'User e-mail is missing. Please configure it in your profile settings',
        userTitleMissing: 'User title is missing. Please configure it in your profile settings',
        productsTableMissing: 'Products table is missing',
        supplierNotFoundInDatabase: 'Supplier not found in database. Please contact your Administrator',
        recipientNotFoundInSupplierData:
            'Recipient e-mail not found in supplier data. Please contact your Administrator',
        userNotFoundInDatabase: 'User not found in database. Please contact your Administrator',
        emailBodyContainsUnknownPlaceholder:
            'There is an unknown placeholder in the e-mail body. Note that you cannot use the characters "{" and "}" except for placholders',
        unableToConnectToYourEmailAccount: 'Unable to connect to your e-mail. Please make sure the password is correct',
        platform: 'Platform',
        platformV3: 'PlatformV3',
        processingSettings: 'Processing settings',
        fields: 'Fields',
        specifyProductFieldsWhereInfoCanBeFound:
            'Specify in which product fields the corresponding information can be found',
        specifyTheKeywordsForTheCorrespondingValues:
            'Specify the keywords for the corresponding values to search in the products fields',
        units: 'Units',
        save: 'Save',
        culture: 'Culture',
        decimalSeparator: 'Decimal separator',
        numberGroupSeparator: 'Number group separator',
        general: 'General',
        copied: 'Copied',
        pleaseSelectSupplierForCountry: 'Please select a supplier for country: {0}',
        pleaseSelectContactEmailForTheSupplier: 'Please select a contact e-mail for the supplier: {0}',
        minimumCharacters: 'Minimum {0} characters',
        reprocessProducts: 'Reprocess products',
        reprocessRequestSubmitted: 'Reprocess request successfully submitted',
        pFormCreated: 'New PForm successfully created',
        noProductsSelectedError: 'No products selected. Please return to Products Selection',
        streakApiKey: 'Streak API key',
        open: 'Open',
        supplierWaitingReply: 'Supplier waiting reply',
        openQuotes: 'Open quotes',
        closedQuotes: 'Closed quotes',
        unknown: 'Unknown',
        onlyOpen: 'Only open',
        onlyMine: 'Only mine',
        userIsNotACollaborator: 'User is not a collaborator',
        assigneeSuccessfullyUpdated: 'Assignee successfully updated',
        conversations: 'Conversations',
        quotes: 'Quotes',
        saveChanges: 'Save changes',
        userIsNotAllowed: 'User is not allowed',
        threadNotFound: "Thread wasn't found",
        tableDataSuccessfullyUpdated: 'Table data successfully updated',
        emailsSentButErrorIntegrationWithStreak: 'E-mail(s) sent but something went wrong with Streak integration',
        emailsSentButErrorSavingInDatabase: 'E-mails(s) sent but something went wrong saving in Database',
        errorAccessingDatabase: 'Error accessing to database. Try again or contact our support team',
        errorSendingEmails: 'Error sending emails. Try again or contact our support team',
        copyFromOther: 'Copy from other',
        rfqReplyForm: 'RFQ reply form',
        requestsForQuote: 'Requests for quote',
        thisReplyFormIsNoLongerAvailable: 'This reply form is not longer avaible!',
        submit: 'Submit',
        successfullySubmited: 'Successfully submited',
        contact: 'Contact',
        days: 'Days',
        externalLinkForSupplierReplyForm: 'External link for Supplier reply form',
        description: 'Description',
        desc: 'Desc.',
        rfqDescription: 'RFQ Description',
        unitPrice: 'Unit price €',
        totalPrice: 'Total price',
        packsTotal: 'Packs total',
        numberOfPacks: 'Number of Packs',
        requestedPacks: 'Requested quantites',
        addFieldsToBeAutomaticallyFilledInProductsTables:
            'Add fields to be automatically filled in the products tables',
        fillProductTables: 'Fill product tables',
        codes: 'Codes',
        code: 'Code',
        solid: 'Solid',
        semiSolid: 'Semi-solid',
        liquid: 'Liquid',
        gas: 'Gas',
        pleaseMakeSureAllContactsHaveAValidEmail: 'Please make sure all contacts have a valid e-mail',
        pleaseMakeSureVerifiedAllProducts: 'Please approve all products to proceed',
        youAreSelectingASupplierForACountryWithoutSelectedProducts:
            "You're selecting a supplier from {0} but there's no product for that Country",
        linkWithGoogle: 'Link with Google',
        availableUntil: 'Available until',
        imInterested: "I'm interested",
        notInterested: 'Not interested',
        details: 'Details',
        offerAlternative: 'Your offer has been submitted. Would you also like to submit an alternative product?',
        offerAlternativeOnDecline: 'Would you like to submit an alternative product?',
        declineQuote: "Are you sure you don't want to quote?",
        submitOffer: 'Submit offer',
        decline: 'Decline',
        iveChangedMyMindAndImInterested: 'I changed my mind, I would like to quote',
        accept: 'Accept',
        platTermsAndConditions: 'Platform Terms and Conditions',
        receiveACopyOfMyOfferByEmail: 'Receive a copy of my offer by e-mail',
        new: 'New',
        interested: 'Interested',
        inProgress: 'In progress',
        quoted: 'Quoted',
        declined: 'Declined',
        changeQuote: 'Change quote',
        editQuote: 'EDIT QUOTE',
        createdBy: 'Created by',
        updatedBy: 'Updated by',
        expires: 'Expires',
        seeAllYourRFQs: 'See all your RFQs',
        viewAll: 'View all',
        dueDate: 'Due date',
        alternative: 'Alternative',
        assignToExistingRfq: 'Assign to an existing RFQ',
        youreTryingToAssignToAnExistingRfqWhichWasntFound:
            "You're trying to assign to an existing RFQ but it wasn't found. Please make sure the RFQ number is correct.",
        someoneHasCreatedRfqWithTheSameNumberPleaseTryAgain:
            'Meanwhile, someone has created RFQ(s) with the same number(s). The automatic RFQ numbers were updated. Please try again now.',
        requestAnAccessLink: 'Request access',
        accessLinkWasSentToYourInbox: 'An access link was sent to your inbox. Thank you!',
        yourRequestHasBeenRegistered: 'Your request has been registered.',
        thisLinkIsNoLongerAvailable: 'This link is no longer available.',
        language: 'Language',
        noResults: 'No results',
        quoteLater: 'Quote later',
        yourQuote: 'Your quote',
        whatWeNeed: 'What we need',
        why: 'Why?',
        duration: 'Duration',
        ifApplicable: 'if applicable',
        hide: 'Hide',
        includeExprice: 'Include ExPrice',
        viewOpen: 'View Open',
        notInOurRange: 'Not in our range',
        onShortage: 'On shortage',
        notCommercialized: 'Not commercialized',
        authorizationCeased: 'Authorization ceased',
        exportBan: 'Export ban',
        otherPleaseIndicate: 'Other (please indicate in the comments field)',
        suspended: 'Suspended',
        qualified: 'Qualified',
        qualify: 'Qualify',
        generic: 'Generic',
        psychotropic: 'Psychotropic',
        biological: 'Biological',
        additionalMonitoring: 'Additional Monitoring',
        prescription: 'Prescription',
        hospitalar: 'Hospitalar',
        precautionsForStorage: 'Precautions for Storage',
        reason: 'Reason',
        productDescription: 'Product description',
        additionalCost: 'Additional costs',
        weightedPrice: 'Weighted price',
        comparison: 'Price simulation',
        pleaseSelectProducts: 'Please select products to proceed',
        rfqUpdated: 'RFQ has been updated',
        savePlatformSettings: 'Platform settings has been saved successfully',
        saving: 'Saving . . .',
    },
};

export const persistor = (state: TranslationsState): TranslationsState => ({
    locale: state.locale,
    translations: {},
});

export const reconciler = (stored: TranslationsState): TranslationsState => ({
    ...stored,
    translations: {
        ...initialState.translations,
        ...stored.translations,
    },
});

const handleTranslationsUpdated = (state: TranslationsState, action: TranslationsUpdatedAction) => ({
    ...state,
    locale: action.locale,
    translations: {
        ...state.translations,
        ...action.translations,
    },
});

export const reducer: Reducer<TranslationsState> = (
    state: TranslationsState = initialState,
    action: Action,
): TranslationsState => {
    switch (action.type) {
        case Actions.updated:
            return handleTranslationsUpdated(state, action as TranslationsUpdatedAction);
        default:
            return state;
    }
};
