import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Typography, IconButton, DialogContent, Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSelector } from 'react-redux';
import { RowSelectedEvent } from 'ag-grid-community';

import { SelectedProductsDialogProps } from './SelectedProductsDialog.types';
import { DialogTitleContainer, CloseIconWrapper } from './SelectedProductsDialog.styles';
import Table from '../../AGTable';
import { getSelectedProducts } from '../../../store/RFQProducts/selectors';
import { getColumns } from './columns';
import { ProductV2 } from '../../../models/ProductV2';
import { PanelButtonsContainer } from '../../../components/Panel';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';

const SelectedProductsDialog: React.FC<SelectedProductsDialogProps> = ({
    selectedProducts,
    isDialogOpen,
    closeDialog,
    save,
}) => {
    const t = useTranslations();

    const [selectedRows, setSelectedRows] = useState<ProductV2[]>([]);

    useEffect(() => {
        selectedProducts && setSelectedRows(selectedProducts);
    }, [selectedProducts, isDialogOpen]);

    const defaultColDef = {
        flex: 1,
        sortable: true,
        wrapHeaderText: true,
        minWidth: 125,
        resizable: true,
        autoHeaderHeight: true,
        suppressMovable: true,
    };

    const onRowDataUpdated = (params: any) => {
        if (selectedRows.length) {
            params.api.forEachNode((node: any) => {
                const isProductPresent = selectedRows.find((product) => product.id === node.data.id);
                if (isProductPresent) {
                    node.setSelected(true);
                }
            });
        }
    };

    const handleRowSelectionChange = (product: ProductV2, selected: boolean) => {
        let newArr: ProductV2[] = [];
        if (selected) {
            newArr = [...selectedRows].filter((row) => row.id !== product.id).concat([product]);
        } else {
            newArr = [...selectedRows].filter((row) => row.id !== product.id);
        }
        setSelectedRows(newArr);
    };

    return (
        <Dialog open={isDialogOpen} fullWidth maxWidth="xl">
            <DialogTitle style={{ padding: 0 }}>
                <DialogTitleContainer>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                            <Typography style={{ marginLeft: '5px' }} variant="h5">
                                {t(TK.selectedProducts)}
                            </Typography>
                        </div>
                    </div>
                    <CloseIconWrapper>
                        <IconButton onClick={closeDialog} style={{ outline: 'none' }}>
                            <HighlightOffIcon color="primary" fontSize="large" />
                        </IconButton>
                    </CloseIconWrapper>
                </DialogTitleContainer>

                <hr style={{ margin: 0 }} />
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <Table
                    columnsDefinition={getColumns()}
                    data={selectedProducts}
                    isExportable={false}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    onRowDataUpdated={onRowDataUpdated}
                    onRowSelected={(event: RowSelectedEvent) => {
                        handleRowSelectionChange(event.node.data, !!event.node.isSelected());
                    }}
                />
                <PanelButtonsContainer>
                    <Button
                        disabled={selectedProducts.length === selectedRows.length}
                        variant="contained"
                        color="primary"
                        onClick={() => save(selectedRows)}
                    >
                        {t(TK.save)}
                    </Button>
                </PanelButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};

export default SelectedProductsDialog;
