import * as React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import PageviewIcon from '@material-ui/icons/Pageview';

import { TK } from '../../../store/Translations/translationKeys';
import MultipleSelectInput from '../../../components/inputs/MultipleSelectInput';
import { Country } from '../../../models/Country';
import TextInput from '../../../components/inputs/TextInput';
import { useTranslations } from '../../../store/Translations/hooks';
import { useCountries } from '../../../store/DomainInfo/hooks';
import { Filters, FiltersKey } from './SuppliersFilters.types';
import { SupplierState } from '../../../models/SupplierState';
import { FiltersInputsContainer } from './SuppliersFilters.styles';

const getAsArray = (value: string | string[] | null | undefined): string[] =>
    value === undefined ? [] : typeof value === 'string' ? [value as string] : (value as string[]);

export interface SuppliersFiltersProps {
    initialValues: Filters;
    isLoading: boolean;
    onChange: (filters: Filters) => void;
}

const SuppliersFilters: React.FC<SuppliersFiltersProps> = ({ initialValues, isLoading, onChange }) => {
    const t = useTranslations();

    const countries = useCountries();

    const [filters, setFilters] = React.useState(initialValues);
    const [searchInput, setSearchInput] = React.useState<string>('');
    const statuses = React.useMemo(
        () => [
            SupplierState.Suspended,
            SupplierState.Qualify,
            SupplierState.Qualified,
            SupplierState.Others,
            SupplierState.New,
        ],
        [],
    );

    const updateFilters = (property: string, value: string | string[]) => {
        const newFilters = { ...filters, [property]: value || undefined };
        setFilters(newFilters);
        onChange(newFilters);
    };

    return (
        <div>
            <FiltersInputsContainer style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <MultipleSelectInput
                    label={t(TK.country)}
                    placeholder={t(TK.all)}
                    limitTags={10}
                    options={countries}
                    values={countries.filter((country) =>
                        (filters[FiltersKey.Countries] || []).includes(country.alpha2Code),
                    )}
                    onChange={(v) =>
                        updateFilters(
                            FiltersKey.Countries,
                            v.map((country: Country) => country.alpha2Code),
                        )
                    }
                    disabled={isLoading}
                    getOptionLabel={(option: Country) => option?.name || ''}
                    renderOption={(option: Country) => `${option.name} (${option.alpha2Code})`}
                    style={{ width: '450px' }}
                />
                <MultipleSelectInput
                    label={t(TK.status)}
                    placeholder={t(TK.all)}
                    limitTags={10}
                    disabled={isLoading}
                    options={statuses}
                    values={getAsArray(filters[FiltersKey.Statuses])}
                    onChange={(v): void => updateFilters(FiltersKey.Statuses, v)}
                    style={{ width: '450px' }}
                />
                <TextInput
                    label={t(TK.name)}
                    placeholder={t(TK.all)}
                    disabled={isLoading}
                    value={searchInput}
                    onChange={(v) => setSearchInput(v)}
                    style={{ width: '450px' }}
                    endAdorment={
                        <InputAdornment position="end">
                            {isLoading ? (
                                <Spinner size="sm" style={{ marginLeft: '5px' }} />
                            ) : (
                                <IconButton
                                    onClick={() => updateFilters(FiltersKey.Name, searchInput)}
                                    style={{ outline: 'none', height: '20px', padding: '0px', marginRight: '-18px' }}
                                    aria-label="free text filter info"
                                    edge="end"
                                >
                                    <PageviewIcon color="primary" style={{ fontSize: '3.7875rem' }} />
                                </IconButton>
                            )}
                        </InputAdornment>
                    }
                />
            </FiltersInputsContainer>
        </div>
    );
};

export default SuppliersFilters;
