import { Accordion, Box, BoxProps, Card, Typography, TypographyProps } from '@material-ui/core';
import styled from 'styled-components';

export const StikyDiv = styled.div`
    position: sticky;
    top: 0;
`;

export const EnquireHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
`;

export const EnquireHeaderH = styled(Typography)`
    width: 50%;
    align-content: center;
`;
export const EnquireSpan = styled.span`
    font-weight: bolder;
`;

export const EnquireAccordion = styled(Accordion)`
    border: 2px;
    background-color: white;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
`;

export const EnquireCardStyled = styled(Card)`
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    margin: 1%;
`;

export const EnquireStatus = styled(Box)<BoxProps & { $color: string }>`
    border-radius: 25px;
    border: 2px solid ${(p) => p.$color};
    background-color: ${(p) => p.$color};
    color: white;
`;

export const EnquireLabel = styled.label`
    margin: 1px 5px 0px 0px;
`;

export const EnquireH = styled(Typography)<TypographyProps & { $color: string }>`
    color: ${(p) => p.$color};
`;
export const EnquireBoxFlex = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
`;
export const EnquireBoxFlexStart = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1%;
`;
