import React, { useState, useRef, useCallback, useMemo } from 'react';
import {
    ColDef,
    ColGroupDef,
    RowClassRules,
    RowSelectedEvent,
    FirstDataRenderedEvent,
    RowDataUpdatedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { useTranslations } from '../../store/Translations/hooks';
import { Item, TableSettings } from './types';
import { INavigationProps, AGCustomPagination } from './AGCustomPagination';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { setTimeout } from 'timers';
import { AGToolbar } from './AGToolbar';
import '../../app/css/agridCustom.css';
import '../../app/css/quotesGrid.css';
import { RFQQuoteTableItem } from '../../pages/RFQDetails/RFQDetails';
import OverlayComponent from './OverlayComponent';
import { buildSort } from './Filters';

LicenseManager.setLicenseKey(
    'For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-1_October_2022_[v2]_MTY2NDU3ODgwMDAwMA==5f7c547bfa346b114d2e599f9df6045b',
);

type SelectionTypes = 'single' | 'multiple' | undefined;

export interface TableProps<T extends Item> {
    columnsDefinition: ColDef[] | ColGroupDef[];
    data: T[];
    selectedItems?: (string | number)[];
    pageSize: number;
    currentPage: number;
    totalPages: number;
    totalElements: number;
    gridType?: string;
    timeInSeconds?: number;
    rowHeight?: number;
    pagination?: boolean;
    isLoading?: boolean;
    sortBy?: string;
    sideBar?: string;
    isCreateRfq?: boolean;
    isExportable: boolean;
    suppressClickEdit?: boolean;
    stopEditingWhenCellsLoseFocus?: boolean;
    enablePagination?: boolean;
    enableSearch?: boolean;
    suppressRowClickSelection?: boolean;
    captionAnalytics?: string | undefined;
    rowGroupPanelShow?: string;
    selectedQuotes?: RFQQuoteTableItem[];
    sortDirection?: 'asc' | 'desc';
    exportFileName?: string;
    settings?: TableSettings;
    hideSelectionColumn?: boolean;
    rowSelection?: SelectionTypes;
    searchColumns?: string[];
    deselectRow?: string;
    defaultColDef?: {
        flex?: number;
        filter?: boolean;
        sortable: boolean;
        wrapHeaderText: boolean;
        resizable: boolean;
        autoHeaderHeight: boolean;
        suppressMovable: boolean;
    };
    rowClassRules?: RowClassRules<any>;
    tableFilters?: { [key: string]: any };
    selectedRows?: any[];

    onChangeData?: (data: T) => void;
    onRowSelected?: (event: RowSelectedEvent) => void;
    onChangePage?: (page: number) => void;
    onChangeItemsSelection?: (selectedItems: Array<number | string>) => void;
    onChangeItemSelection?: (itemId: number | string, isSelected: boolean) => void;
    onChangeSettings?: (settings: TableSettings) => void;
    onChangeSorting: (sortField: string, sortOrder: string) => void;
    onResetSettings?: () => void;
    onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
    onRowDataUpdated?: (event: RowDataUpdatedEvent) => void;
    onCreateRfq?: () => void;
    onPageChange: (pageNumber: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    onFilterChanged: (filterQuery: string) => void;
    onFreeTextChange: (value: string) => void;
}

const AGTable: React.FC<TableProps<Item>> = ({
    columnsDefinition,
    data,
    pageSize,
    currentPage,
    totalPages,
    totalElements,
    isLoading,
    defaultColDef,
    searchColumns,
    deselectRow,
    rowSelection,
    isExportable,
    captionAnalytics,
    enablePagination,
    enableSearch,
    rowHeight,
    suppressClickEdit,
    suppressRowClickSelection,
    stopEditingWhenCellsLoseFocus,
    selectedQuotes,
    gridType,
    sideBar,
    rowGroupPanelShow,
    tableFilters,
    selectedRows,

    onCreateRfq,
    onRowSelected,
    onFirstDataRendered,
    onRowDataUpdated,
    onChangeData,

    onPageChange,
    onPageSizeChange,
    onFilterChanged,
    onChangeSorting,
    onFreeTextChange,
}) => {
    const colDefs = useMemo(() => columnsDefinition, []);
    const t = useTranslations();
    const { isOpen } = React.useContext(AppContext) as AppContextType;
    let gridRef = useRef<AgGridReact>(null);

    const [navigation, setNavigation] = useState<INavigationProps>({
        prevBtn: false,
        nextBtn: true,
        currentPage: 1,
        pageSize: pageSize,
        totalPages: totalPages,
    });
    const [rows, setRows] = React.useState(data);
    const [gridRowsCount, setGridRowsCount] = React.useState(0);
    const [gridTimeInSeconds, setGridTimeInSeconds] = React.useState(0);

    React.useEffect(() => {
        setNavigation({ ...navigation, totalPages: totalPages });
    }, [totalPages]);

    React.useEffect(() => {
        gridRef.current!.api?.setFilterModel(tableFilters);
        gridRef.current!.columnApi?.resetColumnState();
    }, [tableFilters]);

    React.useEffect(() => {
        gridRef.current!.api?.forEachNode((node: any) => {
            const isProductPresent = selectedRows?.find((product) => product.id === node.data.id);
            if (isProductPresent) {
                node.setSelected(true);
            } else {
                node.setSelected(false);
            }
        });
    }, [selectedRows]);

    React.useEffect(() => {
        setGridRowsCount(totalElements || 0);
    }, [setGridRowsCount, totalElements, setGridTimeInSeconds]);

    React.useEffect(() => {
        setRows(data);
    }, [setRows, data]);

    React.useEffect(() => {
        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }, [isOpen]);

    React.useEffect(() => {
        if (isLoading) {
            gridRef.current!.api?.showLoadingOverlay();
        } else {
            gridRef.current!.api?.hideOverlay();
        }
    }, [isLoading]);

    React.useEffect(() => {
        gridRef.current!.api?.forEachNode(function (node) {
            const itemIdArray = deselectRow?.split('_');
            const itemId = (itemIdArray && itemIdArray[0]) || 0;
            const supplierId = (itemIdArray && itemIdArray[1]) || 0;
            if (node.isSelected() && node.data.id === itemId && node.data.supplierId === supplierId) {
                node.setSelected(false);
            }
        });
    }, [deselectRow]);

    React.useEffect(() => {
        gridRef.current!.api?.forEachNode(function (node) {
            selectedQuotes?.forEach((item) => {
                if (node.data.id === item.id && node.data.supplierId === item.supplierId) {
                    node.setSelected(true);
                }
            });
        });
    }, [selectedQuotes]);

    const onGridReady = useCallback((params) => {
        params.api?.sizeColumnsToFit();

        window.addEventListener('resize', function () {
            setTimeout(function () {
                params.api?.sizeColumnsToFit();
            }, 100);
        });
    }, []);

    const onPaginationChanged = useCallback(() => {
        setNavigation({
            ...navigation,
            pageSize,
            currentPage,
            totalPages,
            prevBtn: currentPage + 1 > 1 && currentPage <= totalPages ? false : true,
            nextBtn: currentPage + 1 === totalPages ? true : false,
        });
    }, [pageSize, currentPage, totalPages]);

    const onBtnFirst = useCallback(() => {
        onPageChange(0);
    }, []);

    const onBtnLast = useCallback(() => {
        onPageChange(totalPages - 1);
    }, [totalPages]);

    const onBtnNext = useCallback(() => {
        onPageChange(currentPage + 1);
    }, [currentPage]);

    const onBtnPrevious = useCallback(() => {
        onPageChange(currentPage - 1);
    }, [currentPage]);

    const handleSortChanged = useCallback(() => {
        const sortedColumn = gridRef.current!.columnApi.getColumnState().find((col) => Boolean(col.sort));
        onChangeSorting(sortedColumn?.colId || '', sortedColumn?.sort || '');
    }, []);

    const handleFilterChanged = useCallback(() => {
        const filterModel = gridRef.current!.api.getFilterModel();
        onFilterChanged(buildSort(filterModel));
    }, []);

    const onInputSearchChange = useCallback(
        (val: string) => {
            onFreeTextChange(val);
        },
        [onFreeTextChange],
    );

    const onBtnExport = useCallback(() => {
        gridRef.current!.api.exportDataAsExcel({
            onlySelected: true,
        });
    }, []);

    const onCellValueChanged = (event: { data: Item }) => {
        if (gridRef) {
            gridRef.current!.api.stopEditing(true);
        }

        onChangeData && onChangeData(event.data);
        return;
    };

    const autoGroupColumnDef = useMemo<ColDef | ColGroupDef>(() => {
        return {
            minWidth: 200,
        };
    }, []);

    const loadingOverlayComponent = useMemo(() => {
        return OverlayComponent;
    }, []);

    return (
        <div className="ag-theme-alpine" style={{ width: '100%', height: 'auto' }}>
            <AGToolbar
                onBtnExport={onBtnExport}
                data={data}
                isExportable={isExportable}
                onInputSearchChange={onInputSearchChange}
                searchColumns={searchColumns || []}
                gridType={gridType}
                onCreateRfq={onCreateRfq}
                paginationComponent={
                    data && data.length > 0 ? (
                        <AGCustomPagination
                            {...navigation}
                            caption={captionAnalytics}
                            data={data}
                            enablePagination={enablePagination}
                            enableSearch={enableSearch}
                            onInputSearchChange={onInputSearchChange}
                            gridType={gridType}
                            totalRecords={gridRowsCount}
                            timeInSeconds={gridTimeInSeconds}
                            onBtnFirst={onBtnFirst}
                            onBtnLast={onBtnLast}
                            onBtnNext={onBtnNext}
                            onBtnPrevious={onBtnPrevious}
                            onPageSizeChanged={onPageSizeChange}
                            onPgNumberChange={onPageChange}
                        />
                    ) : (
                        ''
                    )
                }
            />

            <AgGridReact
                ref={gridRef}
                className="ag-theme-alpine"
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                animateRows={true}
                rowData={rows}
                rowHeight={rowHeight ? rowHeight : 60}
                domLayout="autoHeight"
                suppressPaginationPanel={true}
                onCellValueChanged={onCellValueChanged}
                rowGroupPanelShow={rowGroupPanelShow ?? ''}
                suppressRowClickSelection={suppressRowClickSelection ? true : false}
                stopEditingWhenCellsLoseFocus={stopEditingWhenCellsLoseFocus ? true : false}
                rowMultiSelectWithClick={true}
                columnDefs={colDefs}
                paginationPageSize={pageSize}
                tooltipShowDelay={100}
                valueCache={true}
                sideBar={sideBar ? 'filters' : ''}
                suppressClickEdit={suppressClickEdit || false}
                valueCacheNeverExpires={true}
                pagination={true}
                rowSelection={rowSelection}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onRowDataUpdated={onRowDataUpdated}
                onRowSelected={onRowSelected}
                onPaginationChanged={onPaginationChanged}
                onSortChanged={handleSortChanged}
                onFilterChanged={handleFilterChanged}
                loadingOverlayComponent={loadingOverlayComponent}
            />
            {data && data.length > 0 ? (
                <AGCustomPagination
                    {...navigation}
                    caption={captionAnalytics}
                    enablePagination={enablePagination}
                    totalRecords={gridRowsCount}
                    timeInSeconds={gridTimeInSeconds}
                    onBtnFirst={onBtnFirst}
                    onBtnLast={onBtnLast}
                    onBtnNext={onBtnNext}
                    onBtnPrevious={onBtnPrevious}
                    onPageSizeChanged={onPageSizeChange}
                    onPgNumberChange={onPageChange}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default AGTable;
