import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Box,
    Switch,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    Dialog,
    DialogContent,
    Button,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckIcon from '@material-ui/icons/Check';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import { PharmaceuticalFormTableV3Props } from './PharmaceuticalFormTable.types';
import { PFormMappingTableContainer } from './PharmaceuticalFormTable.styles';
import { useTranslations } from '../../../../store/Translations/hooks';
import { TK } from '../../../../store/Translations/translationKeys';
import { ProcessingPformsSettings } from '../../../../models/ProcessingPformsSettings';
import { DEFAULT_PAGE_SIZE, NO_INDEX } from '../ProcessingSettingsPanel';
import PlatformFilters from '../../../../components/Filter';
import MultipleSelectInput from '../../../../components/inputs/MultipleSelectInput';
import { FilterFieldNames } from '../ProcessingSettingsPanel.types';
import { CloseIconWrapper, DialogTitleContainer } from '../ProcessingSettingsPanel.styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SingleSelectInput from '../../../../components/inputs/SingleSelectInput';
import TextInput from '../../../../components/inputs/TextInput';
import { ProcessingCatSettings } from '../../../../models/ProcessingCatSettings';
import { savePlatformV3NewItem } from '../../../../store/Platform/actions';
import { MappingName } from '../../../../models/ProcessingSettingsV3';
import { getMyUser } from '../../../../store/Users/selectors';

const EMPTY_CATEGORY = { subCategory: '', category: '', pharmaceuticalPhorm: '', administrationRoute: '' };

const PharmaceuticalFormTable: React.FC<PharmaceuticalFormTableV3Props> = ({
    pharmaceuticalForms,
    categoriesList,
    originCountry,
    setChanged,
    setTotalNumberOfPages,
    indexToEdit,
    setIndexToEdit,
    onFilterChange,
    setDataToSave,
    isApproved,
    setIsApproved,
}) => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const user = useSelector(getMyUser);

    const [pForms, setPForms] = React.useState<ProcessingPformsSettings[]>([]);
    const [originalPForms, setOriginalPForms] = React.useState<ProcessingPformsSettings[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [categories, setCategories] = React.useState<ProcessingCatSettings[]>([]);
    const [newCategory, setNewCategory] = React.useState<ProcessingCatSettings>(EMPTY_CATEGORY);
    const [categoriesArrays, setCategoriesArrays] = React.useState<{ [key: string]: string[] }>();

    React.useEffect(() => {
        setPForms(pharmaceuticalForms.items);
        setOriginalPForms(pharmaceuticalForms.items);
        setTotalNumberOfPages(Math.ceil(pharmaceuticalForms.total / DEFAULT_PAGE_SIZE));
    }, [pharmaceuticalForms]);

    React.useEffect(() => {
        setCategories(categoriesList?.items);
        const categories = categoriesList?.items?.reduce<string[]>((acc, val) => {
            !acc.includes(val.category) && acc.push(val.category);
            return acc;
        }, []);
        const subCategories = categoriesList?.items?.reduce<string[]>((acc, val) => {
            !acc.includes(val.subCategory) && acc.push(val.subCategory);
            return acc;
        }, []);
        const adminRoutes = categoriesList?.items?.reduce<string[]>((acc, val) => {
            !acc.includes(val.administrationRoute) && acc.push(val.administrationRoute);
            return acc;
        }, []);
        setCategoriesArrays({ categories, subCategories, adminRoutes });
    }, [categoriesList?.items]);

    React.useEffect(() => {
        const data = pForms.filter((pForm, index) => {
            return pForm?.final?.toString() !== originalPForms[index]?.final?.toString();
        });
        if (data.length > 0) {
            setChanged(true);
            setDataToSave(data);
        } else {
            setChanged(false);
        }
    }, [pForms]);

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsApproved(event.target.checked);
    };

    const onClickEditable = (index: number) => {
        setIndexToEdit(index);
    };

    const onClickToAppove = () => {
        setIndexToEdit(NO_INDEX);
    };

    const onClickToUndo = React.useCallback(
        (index: number) => {
            const originalPForm = { ...originalPForms[index] };
            const pFormsCopy = [...pForms];
            const newPFormsArr = [...pFormsCopy.slice(0, index), originalPForm, ...pFormsCopy.slice(index + 1)];
            setPForms(newPFormsArr);
        },
        [pForms, originalPForms],
    );

    const handleChange = React.useCallback(
        (index: number) => (newValues: string[]) => {
            const itemIndex = newValues.findIndex((i) => i == 'Add new');
            if (itemIndex >= 0) {
                setOpen(true);
                return;
            }

            const newPForm = { ...pForms[index], final: newValues };
            const pFormsCopy = [...pForms];
            const newPFormsArr = [...pFormsCopy.slice(0, index), newPForm, ...pFormsCopy.slice(index + 1)];
            setPForms(newPFormsArr);
        },
        [pForms],
    );

    const showUndo = React.useCallback(
        (index: number): boolean => {
            if (pForms[index].final?.toString() !== originalPForms[index].final?.toString()) {
                return true;
            } else {
                return false;
            }
        },
        [pForms, originalPForms],
    );

    const handleClose = () => {
        setNewCategory(EMPTY_CATEGORY);
        setOpen(false);
    };

    const handleDialogChange = React.useCallback(
        (newProp: { [key: string]: string }) => {
            setNewCategory({ ...newCategory, ...newProp });
        },
        [newCategory],
    );

    const createNew = React.useCallback(() => {
        const data = { ...newCategory, updated: new Date(), user: user?.userName };

        dispatch(
            savePlatformV3NewItem({
                origin: originCountry,
                mappingName: MappingName.CategoriesList,
                data,
            }),
        );

        setNewCategory(EMPTY_CATEGORY);
        setOpen(false);
    }, [newCategory, user, originCountry]);

    return (
        <PFormMappingTableContainer>
            <Typography variant="caption">* {t(TK.specifyTheKeywordsForTheCorrespondingValues)}.</Typography>
            <Box display="flex" justifyContent="end">
                <Box mr={9} display="flex" alignItems="center">
                    <Typography>Show only new terms</Typography>
                    <Switch
                        color="primary"
                        checked={isApproved}
                        onChange={handleSwitchChange}
                        name="notCommercial"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <Typography>Total: {pharmaceuticalForms.total} records</Typography>
                </Box>
            </Box>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width="500px">
                            <Box display="flex">
                                <Typography>Original</Typography>
                                <PlatformFilters
                                    elementId="original-popover"
                                    onFilterChange={onFilterChange(FilterFieldNames.Original)}
                                />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display="flex">
                                <Typography>Translated</Typography>
                                <PlatformFilters
                                    elementId="translated-popover"
                                    onFilterChange={onFilterChange(FilterFieldNames.Translated)}
                                />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display="flex">
                                <Typography>Final</Typography>
                                <PlatformFilters
                                    elementId="final-popover"
                                    onFilterChange={onFilterChange(FilterFieldNames.Final)}
                                />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display="flex">
                                <Typography>Details</Typography>
                                <PlatformFilters
                                    elementId="details-popover"
                                    onFilterChange={onFilterChange(FilterFieldNames.User)}
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pForms?.map((value, index) => (
                        <TableRow key={value.original}>
                            <TableCell>{value.original}</TableCell>
                            <TableCell>{value?.translated}</TableCell>
                            <TableCell>
                                <Box display="flex">
                                    <MultipleSelectInput
                                        values={value?.final}
                                        options={categories?.map((item) => item?.pharmaceuticalPhorm)}
                                        disabled={index !== indexToEdit}
                                        renderOption={(option, { selected }) => {
                                            if (option == 'Add new') {
                                                return (
                                                    <div>
                                                        <hr style={{ width: '200px' }} />
                                                        {option}
                                                    </div>
                                                );
                                            }
                                            return (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option}
                                                </React.Fragment>
                                            );
                                        }}
                                        showAdd
                                        freeSolo
                                        onChange={handleChange(index)}
                                        style={{
                                            width: '80%',
                                            backgroundColor: value.edited
                                                ? '#c8e1cc'
                                                : value.closed
                                                ? '#8080800d'
                                                : '#ffff0066',
                                        }}
                                    />
                                    {index !== indexToEdit && (
                                        <IconButton
                                            style={{ marginTop: '6px' }}
                                            aria-label="edit"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                onClickEditable(index);
                                            }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    )}

                                    {index === indexToEdit && (
                                        <IconButton
                                            style={{ marginTop: '6px' }}
                                            aria-label="approve"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                onClickToAppove();
                                            }}
                                        >
                                            <CheckIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                    {index === indexToEdit && showUndo(index) && (
                                        <IconButton
                                            style={{ marginTop: '6px' }}
                                            aria-label="approve"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                onClickToUndo(index);
                                            }}
                                        >
                                            <UndoIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell>
                                Last updated by:
                                <br />
                                {value?.user}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dialog open={open} onClose={handleClose} style={{ width: '100%' }}>
                <DialogTitleContainer>
                    <CloseIconWrapper>
                        <IconButton onClick={handleClose} style={{ outline: 'none' }}>
                            <HighlightOffIcon color="primary" fontSize="large" />
                        </IconButton>
                    </CloseIconWrapper>
                </DialogTitleContainer>
                <DialogContent style={{ width: '500px' }}>
                    <div>
                        <b>Category</b>
                        <SingleSelectInput
                            options={categoriesArrays?.categories || []}
                            value={newCategory.category}
                            onChange={(val) => {
                                handleDialogChange({ category: val });
                            }}
                        />
                    </div>
                    <div>
                        <b>Subcategory</b>
                        <SingleSelectInput
                            options={categoriesArrays?.subCategories || []}
                            value={newCategory.subCategory}
                            onChange={(val) => handleDialogChange({ subCategory: val })}
                        />
                    </div>
                    <div>
                        <b>Pharmaceutical form</b>
                        <TextInput
                            style={{ width: '425px' }}
                            value={newCategory.pharmaceuticalPhorm}
                            onChange={(val) => handleDialogChange({ pharmaceuticalPhorm: val })}
                        />
                    </div>
                    <div>
                        <b>Administration route</b>
                        <SingleSelectInput
                            options={categoriesArrays?.adminRoutes || []}
                            value={newCategory.administrationRoute}
                            onChange={(val) => handleDialogChange({ administrationRoute: val })}
                        />
                    </div>
                    <Box display="flex" justifyContent="right">
                        <Box marginTop="10px" marginBottom="10px">
                            <Button
                                disabled={
                                    !newCategory.category.length ||
                                    !newCategory.subCategory.length ||
                                    !newCategory.administrationRoute.length ||
                                    !newCategory.pharmaceuticalPhorm.length
                                }
                                onClick={createNew}
                                color="primary"
                                variant="contained"
                            >
                                Create
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </PFormMappingTableContainer>
    );
};

export default PharmaceuticalFormTable;
