import { AdditionalDocumentsState } from './state';
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import {  AdditionalDocumentsLoadedAction } from './types';

const unloadedState: AdditionalDocumentsState = {
    AdditionalDocumentsInfo: [],
    isLoading: false,
};

export const persistor = (state: AdditionalDocumentsState): AdditionalDocumentsState => ({
    ...unloadedState,
});

export const reconciler = (stored: AdditionalDocumentsState): AdditionalDocumentsState => ({
    ...stored,
    isLoading: false,
});

const handleAdditionalDocumentsLoading = (state: AdditionalDocumentsState): AdditionalDocumentsState => ({
    ...state,
    isLoading: true,
});

const handleAdditionalDocumentsLoadError = (state: AdditionalDocumentsState): AdditionalDocumentsState => ({
    ...state,
    isLoading: false,
});

const handleAdditionalDocumentsLoaded = (state: AdditionalDocumentsState, action: AdditionalDocumentsLoadedAction): AdditionalDocumentsState => ({
    ...state,
    isLoading: false,
    AdditionalDocumentsInfo: action.result,
});

export const reducer: Reducer<AdditionalDocumentsState> = (
    state: AdditionalDocumentsState = unloadedState,
    action: Action,
): AdditionalDocumentsState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.additionalDocumentsLoading:
            return handleAdditionalDocumentsLoading(state);
        case Actions.additionalDocumentsLoadError:
            return handleAdditionalDocumentsLoadError(state);
        case Actions.additionalDocumentsLoaded:
            return handleAdditionalDocumentsLoaded(state, action as AdditionalDocumentsLoadedAction);
        default:
            return state;
    }
};
