import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import { useHistory } from 'react-router-dom';

import { Stepper, Step, StepLabel } from '@material-ui/core';
import { TK } from '../../store/Translations/translationKeys';
import { getSelectedProducts } from '../../store/RFQProducts/selectors';

import { useSelector, useDispatch } from 'react-redux';
import { getSelectedSuppliersEmails } from '../../store/Suppliers/selectors';
import { push } from 'connected-react-router';
import Panel from '../../components/Panel';
import { routes } from '../../store/Router/routes';

export enum RFQStep {
    SelectProducts = 0,
    SelectSuppliers,
    CommunicationSetup,
    EmailsSent,
}

export interface RFQProgress {
    activeStep: RFQStep;
}

const RFQProgress: React.FC<RFQProgress> = ({ activeStep }) => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const h = useHistory();

    const selectedProducts = useSelector(getSelectedProducts);
    const selectedEmails = useSelector(getSelectedSuppliersEmails);

    const stepsLabel: string[] = [
        t(TK.productsSelection) + (activeStep > RFQStep.SelectProducts ? ` (${selectedProducts.length})` : ''),
        t(TK.suppliersSelection) + (activeStep > RFQStep.SelectSuppliers ? ` (${selectedEmails.length})` : ''),
        t(TK.configurations),
        t(TK.confirmation),
    ];

    const isStepFailed = (step: RFQStep): boolean => {
        if (activeStep <= step) return false;
        switch (step) {
            case RFQStep.SelectProducts:
                return selectedProducts.length === 0;
            case RFQStep.SelectSuppliers:
                return selectedEmails.length === 0;
            default:
                return false;
        }
    };

    const handleStepClick = (step: RFQStep): void => {
        if (activeStep > step) {
            switch (step) {
                case RFQStep.SelectProducts:
                    let productsListPath =
                        routes.rfqProducts + '/' + window?.localStorage.getItem('queryString');
                    h.push(productsListPath);
                    break;
                case RFQStep.SelectSuppliers:
                    dispatch(push({ pathname: routes.rfqSuppliers() }));
                    break;
            }
        }
    };

    return (
        <Panel>
            <Stepper activeStep={activeStep} alternativeLabel>
                {stepsLabel.map((label, idx) => (
                    <Step key={label} style={{ cursor: 'pointer' }} onClick={() => handleStepClick(idx)}>
                        <StepLabel error={isStepFailed(idx)}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Panel>
    );
};

export default RFQProgress;
