import * as React from 'react';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import EnquireCreationProgress from '../../modules/EnquireCreationProgress/EnquireCreationProgress';
import { EnquireCreationStep } from '../../modules/EnquireCreationProgress/EnquireCreationProgress';
import { Box, Button } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import EnquireCard from '../../components/EnquireCard/EnquireCard';
import { getAdditionalDocuments } from '../../store/AdditionalDocuments/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdditionalDocuments } from '../../store/AdditionalDocuments/actions';
import { useCountries } from '../../store/DomainInfo/hooks';
import { getSelectedProducts } from '../../store/EnquiryProducts/selectors';
import { Enquiry } from '../../models/Enquiry';
import SubmitConfirmationDialog from '../../components/SubmitConfirmationDialog/SubmitConfirmationDialog';
import { submitEnquiry } from '../../store/Enquiry/actions';

const EnquireCreationConfiguration: React.FC = () => {
    const dispatch = useDispatch();
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();

    const additionalDocuments = useSelector(getAdditionalDocuments);
    const countries = useCountries();
    const selectedProducts = useSelector(getSelectedProducts);

    const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);
    const [enquiryNeeds, setEnquiryNeeds] = React.useState<Enquiry[]>([] as Enquiry[]);
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState<boolean>(false);

    React.useEffect(() => {
        setHeaderName(t(TK.createEnquiry));
    }, []);

    React.useEffect(() => {
        dispatch(fetchAdditionalDocuments());
    }, []);

    React.useEffect(() => {
        const defaultEnquiryNeeds = selectedProducts.map((product) => ({
            productCode: product.productCode,
            countryCode: product.countryCode,
            name: product.name,
            unitQuant: 0,
            acceptAlternative: false,
            acceptOtherPackSizes: false,
            acceptGenerics: false,
            acceptDifferentCountries: false,
            isSingleLot: false,
            hasAdditionalDocuments: false,
            comments: '',
        }));
        setEnquiryNeeds(defaultEnquiryNeeds);
    }, [selectedProducts]);

    React.useEffect(() => {
        const errorIndex = enquiryNeeds.findIndex((need) => {
            return (
                !!!need.unitQuant ||
                need.unitQuant < 1 ||
                (!!need.hasAdditionalDocuments && !!!need.additionalDocuments?.length) ||
                (need.additionalDocuments &&
                    !!!(need.additionalDocuments?.findIndex((doc) => doc.id === 10) < 0) &&
                    !!!need.otherDocumentDescription)
            );
        });
        if (errorIndex >= 0) {
            setIsSubmitDisabled(true);
        } else {
            setIsSubmitDisabled(false);
        }
    }, [enquiryNeeds]);

    const handleSubmit = React.useCallback(() => {
        dispatch(submitEnquiry({ needs: enquiryNeeds }));
    }, [enquiryNeeds]);

    return (
        <Page title={t(TK.createEnquiry)} style={{ marginTop: '10rem' }}>
            <EnquireCreationProgress activeStep={EnquireCreationStep.Configurations} />
            {selectedProducts?.map((product, index) => (
                <EnquireCard
                    index={index}
                    setEnquiryNeeds={setEnquiryNeeds}
                    enquiryNeeds={enquiryNeeds}
                    product={product}
                    countries={countries}
                    additionalDocuments={additionalDocuments}
                />
            ))}

            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1%',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    disabled={isSubmitDisabled}
                    onClick={() => setOpenConfirmationDialog(true)}
                    variant="contained"
                    color="primary"
                    endIcon={<NavigateNext />}
                >
                    {t(TK.submit)}
                </Button>
            </Box>
            <SubmitConfirmationDialog
                open={openConfirmationDialog}
                setOpen={setOpenConfirmationDialog}
                confirmationMessage={t(TK.enquiryCreationConfirmationMessage)}
                onConfirm={handleSubmit}
                dialogTitle={t(TK.submitEnquiry)}
            />
        </Page>
    );
};

export default EnquireCreationConfiguration;
