export enum FiltersKey {
    Free = 'free',
    Countries = 'countries',
    IsMarketed = 'isMarketed',
    IsEuropean = 'isEuropean',
}

export interface Filters {
    free: string;
    countries: string[];
    isMarketed: boolean;
    isEuropean: number;
}
