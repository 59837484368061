import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { Chip, IconButton, Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Product } from '../../models/Product';
import { useTranslations } from '../../store/Translations/hooks';
import { extractPriceByType, groupBy, tryGetNameFromOriginal } from '../../utils/utils';
import Price from '../../components/PriceV2';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { TK } from '../../store/Translations/translationKeys';
import { CircleBox, TriangleBox } from '../ProductDetailsV2/styled';
import AvailabilityCell from '../Marketed';
import { Link } from 'react-router-dom';
import ShowMore from './ShowMore';
import { TagProps } from 'reactstrap';

interface CellDataProps {
    rowData: Product;
}

interface CellProps {
    children: React.ReactNode;
    fontSize?: string;
    subValue?: string;
    lineHeight?: string;
    marginTop?: string;
}

export const Cell: React.FC<CellProps> = ({ children, subValue, lineHeight, marginTop, fontSize }) => {
    return (
        <div
            style={{
                lineHeight: lineHeight || '1.9rem',
                marginTop: marginTop || '0.5rem',
                fontSize: fontSize || '0.9rem',
            }}
        >
            {children}
            {subValue && subValue !== children && (
                <Typography style={{ fontSize: '0.7rem' }} color="secondary" variant="subtitle2">
                    {subValue}
                </Typography>
            )}
        </div>
    );
};

export const ActiveSubtanceCell: React.FC<CellDataProps & { limit: number }> = ({ rowData, limit }) => {
    return (
        <div style={{ lineHeight: '2.2rem', marginTop: '0.1rem' }}>
            <ShowMore limit={limit} data={rowData.data.activeSubstances} />
        </div>
    );
};

export const CellTags: React.FC<{ tags: string[] }> = (info) => {
    const t = useTranslations();
    return (
        <>
            <Cell marginTop="0.2rem" fontSize="0.9rem">
                <ShowMore limit={2} data={info.tags || []} />
            </Cell>
        </>
    );
};

export const YesNo: React.FC<{ value?: boolean }> = ({ value }) =>
    value === undefined ? (
        <HelpOutlineIcon style={{ color: 'goldenrod' }} />
    ) : value ? (
        <CheckIcon color="primary" />
    ) : (
        <CloseIcon color="error" />
    );

export const Administration: React.FC<CellDataProps> = ({ rowData }) => {
    const t = useTranslations();
    const { administrationRoute, administrationCategories } = rowData.data;
    return (
        <Cell subValue={administrationRoute}>
            {(administrationCategories?.length &&
                administrationCategories.map((c) => (
                    <Chip size="small" key={c} label={t(((TK as any)[c] || c) as TK)} />
                ))) ||
                '-'}
        </Cell>
    );
};
