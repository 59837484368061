import * as React from 'react';
import { TK } from '../../store/Translations/translationKeys';
import { useTranslations } from '../../store/Translations/hooks';
import RFQProgress, { RFQStep } from '../../modules/RFQProgress/RFQProgress';
import Page from '../../components/Page';
import { Button, Typography, LinearProgress, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { PanelButtonsContainer } from '../../components/Panel';
import SendIcon from '@material-ui/icons/Send';
import { useDispatch, useSelector } from 'react-redux';
import ProductsEmailTables from './ProductsEmailTables/ProductsEmailTables';
import ProductsRFQsAssociation from './ProductsRFQsAssociation/ProductsRFQsAssociation';
import { RFQ, EmailData } from './CommunicationSetup.types';
import { MapOf } from '../../utils/Types';
import { RFQRequestV3 } from '../../models/RFQRequest';
import {
    getProductSelectedSuppliers,
    getProductSelectedSupplierSelectedEmails,
    getSelectedSuppliers,
    getSelectedSuppliersEmails,
} from '../../store/Suppliers/selectors';
import { Supplier } from '../../models/Supplier';
import { submitRFQs } from '../../store/RFQ/actions';
import { isSubmittingRFQs } from '../../store/RFQ/selectors';
import { getSelectedProducts } from '../../store/RFQProducts/selectors';
import { RFQQuote } from '../../models/RFQQuote';
import { Email } from '../../store/Suppliers/state';
import { escapeHtmlSpecialChars } from '../../utils/utils';

const validateRFQQuote = (quote: RFQQuote): string | undefined => {
    if (!quote.name?.length) return 'Product name is mandatory';
    if (!quote.activeSubstances?.length) return 'Active substances are mandatory';
    if (!quote.unitQuant?.length) return '"Req. Quant." information is mandatory';
    return undefined;
};
const findRFQ = (productId: string, rfqs: RFQ[]) => rfqs.find((rfq) => rfq.items.includes(productId));

const CommunicationSetup: React.FC = () => {
    const dispatch = useDispatch();
    const t = useTranslations();

    const isSubmitting = useSelector(isSubmittingRFQs);
    const selectedProducts = useSelector(getSelectedProducts) || [];
    const selectedSuppliers = useSelector(getSelectedSuppliers) || [];
    const selectedSuppliersEmails = useSelector(getSelectedSuppliersEmails) || [];
    const selectedProductSuppliers = useSelector(getProductSelectedSuppliers) || {};
    const selectedProductSuppliersEmails = useSelector(getProductSelectedSupplierSelectedEmails) || {};

    const [rfqsAssociations, setRfqsAssociations] = React.useState<RFQ[]>([]);
    const [dataByCountry, setDataByCountry] = React.useState<MapOf<EmailData>>({});
    const [emailTemplates, setEmailTemplates] = React.useState<MapOf<string>>({});

    const [allTablesVerified, setAllTablesVerified] = React.useState(false);
    const [allProductsAssociated, setAllProductsAssociated] = React.useState(false);

    const [submittionError, setSubmittinError] = React.useState<string | undefined>(undefined);

    const allRFQsHaveId = !rfqsAssociations.some((rfq) => (rfq?.id?.length || 0) < 4);

    const errorMessage = React.useMemo(
        () =>
            !selectedProducts.length
                ? t(TK.noProductsSelectedError)
                : !allProductsAssociated
                ? t(TK.pleaseAssociateAllProductsToAnRFQ)
                : !allTablesVerified
                ? t(TK.pleaseMarkAllProductsTablesAsVerified)
                : submittionError,
        [selectedProducts, allProductsAssociated, allRFQsHaveId, allTablesVerified, submittionError],
    );

    const handleSubmition = React.useCallback(
        async (email?: string, password?: string, accessCode?: string) => {
            setSubmittinError(undefined);
            const productEmails: Email[] = [];
            Object.keys(selectedProductSuppliersEmails).forEach((key) =>
                selectedProductSuppliersEmails[key].forEach((item) => {
                    //create array of unique contacts not the email ones
                    if (productEmails.findIndex((obj) => obj.value === item.value) < 0) {
                        productEmails.push({ value: item.value, isCC: item.isCC });
                    }
                }),
            );

            const requestV3: RFQRequestV3 = {
                supplierQuote: productEmails
                    .filter((e) => !e.isCC)
                    .map((productSpEmail) => {
                        const supplier = selectedSuppliers.find(
                            (s) => s.contacts.findIndex((c) => c.email === productSpEmail.value) >= 0,
                        ) as Supplier;
                        const data = dataByCountry[supplier.countryCode];

                        return {
                            id: supplier.id,
                            email: productSpEmail.value,
                            quotes: data.table.map<RFQQuote>((v) => {
                                var rfq = findRFQ(v['id'], rfqsAssociations);
                                return {
                                    ...v,
                                    [TK.rfqNr]: rfq!.id || rfq!.autoId,
                                };
                            }),
                        };
                    }),
            };

            var dataErrors = requestV3.supplierQuote
                .map((data) => ({
                    data,
                    errors: data.quotes.map(validateRFQQuote).filter((error) => error?.length),
                }))
                .filter((data) => data.errors?.length);

            if (dataErrors.length) {
                setSubmittinError(`Invalid data for "${dataErrors[0].data.email}" - ${dataErrors[0].errors[0]}`);
            } else {
                dispatch(submitRFQs(requestV3));
            }
        },
        [
            dispatch,
            setSubmittinError,
            dataByCountry,
            emailTemplates,
            selectedSuppliers,
            selectedSuppliersEmails,
            selectedProducts,
            selectedProductSuppliers,
            selectedProductSuppliersEmails,
            rfqsAssociations,
        ],
    );

    React.useEffect(() => setSubmittinError(undefined), [setSubmittinError, dataByCountry]);

    const handleAutoFillField = React.useCallback(
        (rfqNr: string, field: string, value: string) => {
            setDataByCountry((prev) =>
                Object.keys(prev)
                    .map<MapOf<EmailData>>((country) => ({
                        [country]: {
                            ...prev[country],
                            table: prev[country].table.map<RFQQuote>((row) =>
                                row.rfqNr !== rfqNr
                                    ? row
                                    : {
                                          ...row,
                                          name:
                                              field === TK.name
                                                  ? escapeHtmlSpecialChars(value)
                                                  : escapeHtmlSpecialChars(row.name),
                                          activeSubstances:
                                              field === TK.activeSubstances
                                                  ? escapeHtmlSpecialChars(value)
                                                  : escapeHtmlSpecialChars(row.activeSubstances || ''),
                                          productCode:
                                              field === TK.productCode
                                                  ? escapeHtmlSpecialChars(value)
                                                  : escapeHtmlSpecialChars(row.productCode || ''),
                                          packSize:
                                              field === TK.packSize
                                                  ? escapeHtmlSpecialChars(value)
                                                  : escapeHtmlSpecialChars(row.packSize || ''),
                                          unitQuant:
                                              field === TK.unitQuant
                                                  ? escapeHtmlSpecialChars(value)
                                                  : escapeHtmlSpecialChars(row.unitQuant || ''),
                                          countryOfOrigin:
                                              field === TK.countryOfOrigin
                                                  ? escapeHtmlSpecialChars(value)
                                                  : escapeHtmlSpecialChars(row.countryOfOrigin || ''),
                                          maHolder:
                                              field === TK.maHolder
                                                  ? escapeHtmlSpecialChars(value)
                                                  : escapeHtmlSpecialChars(row.maHolder || ''),
                                      },
                            ),
                        },
                    }))
                    .reduce((prev, curr) => Object.assign(prev, curr), {}),
            );
        },
        [setDataByCountry],
    );

    return (
        <Page title={t(TK.configurations)}>
            <Dialog open={isSubmitting}>
                <DialogTitle>{t(TK.creatingRFQsAndSendingEmails)}</DialogTitle>
                <DialogContent>
                    <LinearProgress />
                </DialogContent>
            </Dialog>

            <RFQProgress activeStep={RFQStep.CommunicationSetup} />

            <ProductsRFQsAssociation
                rfqs={rfqsAssociations}
                setRfqs={setRfqsAssociations}
                onAllProductsAssociatedChanged={setAllProductsAssociated}
                onAutoFillField={handleAutoFillField}
            />

            <ProductsEmailTables
                rfqs={rfqsAssociations}
                dataByCountry={dataByCountry}
                setDataByCountry={setDataByCountry}
                onVerifiedChanged={setAllTablesVerified}
            />

            <PanelButtonsContainer>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <Button
                    disabled={!!errorMessage}
                    onClick={() => handleSubmition(undefined, undefined, undefined)}
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                >
                    {t(TK.createRfqAndSendTheEmails)}
                </Button>
            </PanelButtonsContainer>
        </Page>
    );
};

export default CommunicationSetup;
