import { push } from 'react-router-redux';
import { routes } from './routes';
import queryString from 'query-string';
import { ProductsSearchFilters } from '../../components/ProductsSearchList/ProductsSearchList.types';

export const goTo = (url: string) => push({ pathname: url });

export const goToHome = () => push({ pathname: routes.home });

export const goToLogin = (redirect?: string) => {
    return push({
        pathname: routes.login,
        search: (redirect && queryString.stringify({ redirect })) || window.location.search,
    });
};

export const goToEnquireProducts = (filters?: ProductsSearchFilters) => {
    return push({
        pathname: routes.enquiryProducts,
        search: filters ? queryString.stringify({ ...queryString.parse(window.location.search), ...filters }) : '',
    });
};

export const goToCreateEnquireConfiguration = () => push({ pathname: routes.enquiryConfigs });

export const goToPaginatedProducts = (filters?: ProductsSearchFilters) => {
    return push({
        pathname: routes.rfqProducts,
        search: filters ? queryString.stringify({ ...queryString.parse(window.location.search), ...filters }) : '',
    });
};

export const goToSuppliers = (offset?: number, filters?: any) => {
    return push({
        pathname: routes.rfqSuppliers(offset),
        search: filters ? queryString.stringify({ ...queryString.parse(window.location.search), ...filters }) : '',
    });
};

export const goToCommunicationConfig = (filters?: any) =>
    push({
        pathname: routes.rfqConfigs,
        search: filters ? queryString.stringify({ ...queryString.parse(window.location.search), ...filters }) : '',
    });

export const goToRFQCreated = () => push({ pathname: routes.rfqCreated });

export const goToRFQsList = (filters?: any) => {
    return push({
        pathname: routes.rfqList,
        search: filters ? queryString.stringify({ ...queryString.parse(window.location.search), ...filters }) : '',
    });
};

export const goToRFQDetails = (rfqNr: string) => push({ pathname: routes.rfqDetail(rfqNr) });

export const goToRFQEnquire = (rfqNr: string) => push({ pathname: routes.rfqSimulation(rfqNr) });

export const goToEnquiryCreated = () => push({ pathname: routes.enquiryCreated });

export const goToEnquiriesList = () => push({ pathname: routes.enquiryList });
