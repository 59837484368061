import { EnquireProductsState } from './state';
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { EnquireProductsLoadedAction, EnquireProductAction } from './types';

const unloadedState: EnquireProductsState = {
    items: {},
    selectedProducts: [],
    searchTotal: 0,
    isLoading: false,
    timeInSeconds: 0,
};

export const persistor = (state: EnquireProductsState): EnquireProductsState => ({
    ...state,
    items: {},
    searchTotal: 0,
    timeInSeconds: 0,
    isLoading: false,
});

export const reconciler = (stored: EnquireProductsState): EnquireProductsState => ({
    ...stored,
    isLoading: false,
});

const handleProductsLoading = (state: EnquireProductsState): EnquireProductsState => ({
    ...state,
    isLoading: true,
});

const handleProductsLoadError = (state: EnquireProductsState): EnquireProductsState => ({
    ...state,
    isLoading: false,
});

const handleProductsLoaded = (
    state: EnquireProductsState,
    action: EnquireProductsLoadedAction,
): EnquireProductsState => ({
    ...state,
    isLoading: false,
    items: Object.assign({}, ...action.result.items.map((p) => ({ [p.id]: p }))),
    searchTotal: action.result.total,
    timeInSeconds: action.result.timeInSeconds || 0,
});

const handleProductsSelected = (state: EnquireProductsState, action: EnquireProductAction): EnquireProductsState => {
    const productsToAdd = action.products;
    const newProductsArray = [...state.selectedProducts]
        .filter((oldObj) => !productsToAdd.some((newObj) => newObj.id === oldObj.id))
        .concat(productsToAdd);
    return {
        ...state,
        selectedProducts: newProductsArray,
    };
};

const handleProductsDeselected = (state: EnquireProductsState, action: EnquireProductAction): EnquireProductsState => {
    const productsToDelete = action.products;
    const newProductsArray = [...state.selectedProducts].filter(
        (oldObj) => !productsToDelete.some((newObj) => newObj.id === oldObj.id),
    );
    return {
        ...state,
        selectedProducts: newProductsArray,
    };
};

const handleProductsDeselectAll = (state: EnquireProductsState): EnquireProductsState => ({
    ...state,
    selectedProducts: [],
});

export const reducer: Reducer<EnquireProductsState> = (
    state: EnquireProductsState = unloadedState,
    action: Action,
): EnquireProductsState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return reconciler(state);
        case Actions.productsLoading:
            return handleProductsLoading(state);
        case Actions.productsLoadError:
            return handleProductsLoadError(state);
        case Actions.productsLoaded:
            return handleProductsLoaded(state, action as EnquireProductsLoadedAction);
        case Actions.productsSelected:
            return handleProductsSelected(state, action as EnquireProductAction);
        case Actions.productsDeselected:
            return handleProductsDeselected(state, action as EnquireProductAction);
        case Actions.productsDeselectAll:
            return handleProductsDeselectAll(state);
        default:
            return state;
    }
};
