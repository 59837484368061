import { AdditionalDocuments } from '../../models/AdditionalDocuments';
import { Country } from '../../models/Country';
import { RFQSimulationTransporterDto } from '../../models/RFQSimulationTransporterDto';

export interface DomainInfoData<T> {
    items: T[];
    isUpdating: boolean;
    lastUpdate: Date;
}

export enum DomainInfoName {
    Countries = 'countries',
    Transporters = 'transporters',
    AdditionalDocuments = 'additionalDocuments',
}

export interface DomainInfoState {
    [DomainInfoName.Countries]: DomainInfoData<Country>;
    [DomainInfoName.Transporters]: DomainInfoData<RFQSimulationTransporterDto>;
    [DomainInfoName.AdditionalDocuments]: DomainInfoData<AdditionalDocuments>;
}
