import React from "react";

const RBPharmaIcon: React.FC<{
    style?: React.CSSProperties, 
    color?: string,
    width?: number|string,
    height?: number|string,
}> = ({
    style, 
    width = "500",
    height = "500",
    color = '#1ab19e'
}) => (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 500 500">
        <defs>
            <clipPath clipPathUnits="userSpaceOnUse">
                <path d="M0 595.28h841.89V0H0v595.28z"></path>
            </clipPath>
        </defs>
        <path fill="none" d="M-1 -1H501V501H-1z"></path>
        <g transform="matrix(1.50949 0 0 -1.50949 -2.61 833.359)">
            <g>
            <g>
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M253.653 435.957h-66.087v73.69h66.087c20.32 0 36.85-16.53 36.85-36.847 0-20.286-16.478-36.793-36.758-36.853-.03 0-.063.01-.092.01m36.85-76.962c0-20.32-16.53-36.85-36.85-36.85h-66.087v73.69h66.087c.03 0 .062.009.092.009 20.28-.056 36.758-16.563 36.758-36.85M147.46 435.967H80.605c-20.319 0-36.848 16.529-36.85 36.848 0 20.322 16.531 36.849 36.85 36.849h66.855v-73.697zM331.4 472.79v.009c0 42.504-35.243 76.956-77.747 76.956h-66.87l-106.178.023c-42.505 0-76.965-34.464-76.965-76.965.005-42.502 34.46-76.961 76.965-76.961L3.64 282.675h48.472l76.994 113.178h18.354V282.03h106.193c42.504 0 77.747 34.463 77.747 76.965 0 22.583-10.568 42.819-26.055 56.897 15.487 14.087 26.055 34.324 26.055 56.899"
                ></path>
            </g>
            <g>
                <g>
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M237.025 263.353l-9.737-22.946-9.727 22.946h-7.302v-40.15h7.302v24.705l9.727-24.706 9.737 22.937v-22.937h7.3v40.151h-7.3z"
                ></path>
                </g>
                <g>
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M110.179 263.353v-17.037H96.795v17.037h-7.307v-40.15h7.307v15.812h13.384v-15.813h7.298v40.151h-7.298z"
                ></path>
                </g>
                <g>
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M139.467 235.368l3.427 10.897 4.046-10.897h-7.473zm6.77 21.545l-3.288 8.87-3.505-10.223-11.098-32.358h7.303l1.53 4.864h12.473l1.81-4.864h7.296l-12.52 33.71z"
                ></path>
                </g>
                <g>
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M265.585 235.368l3.42 10.897 4.049-10.897h-7.47zm6.77 21.545l-3.291 8.87-3.508-10.223-11.1-32.358h7.307l1.525 4.864h12.476l1.809-4.864h7.302l-12.52 33.71z"
                ></path>
                </g>
                <g>
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M67.196 246.316h-9.735v9.736h9.735a4.868 4.868 0 000-9.736m0 17.037h-17.03v-40.15h7.295v15.812h9.735c6.718 0 12.166 5.452 12.166 12.175 0 6.711-5.448 12.163-12.166 12.163"
                ></path>
                </g>
                <g>
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M176.191 256.053h9.733a4.865 4.865 0 004.866-4.872 4.863 4.863 0 00-4.866-4.864h-9.733v9.736zm21.9-4.872c0 6.72-5.443 12.173-12.167 12.173h-17.033v-40.152h7.3v15.814h6.697l7.902-15.814h7.301l-8.239 16.465c4.791 1.634 8.24 6.172 8.24 11.514"
                ></path>
                </g>
            </g>
            </g>
        </g>
    </svg>
);

export default RBPharmaIcon;
