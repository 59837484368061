import styled from 'styled-components';
import colors from '../../../theme/colors';
import {
    Button,
    ButtonProps,
    SvgIconProps,
    TextField,
    TextFieldProps,
    Typography,
    TypographyProps,
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import SingleSelectInput, { SingleSelectInputProps } from '../../../components/inputs/SingleSelectInput';

export const DialogTitleContainer = styled.div`
    display: inline-flex;
    background-color: ${colors.darkenRBGreen};
    padding: 30px 20px 15px;
    font-weight: 200;
    color: ${colors.textRBGreen};
    width: 100%;
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
`;

export const SingleSelectInputDialog = styled(SingleSelectInput)<SingleSelectInputProps<any> & { color?: string }>`
    margin: 0px 16px;
    color: ${(props) => props.color};
`;
export const StyledTextField = styled(TextField)<
    TextFieldProps & { margins?: string; width?: string; resize?: string }
>`
    margin: ${(props) => props.margins};
    width: ${(props) => props.width};
    resize: ${(props) => props.resize};
`;

export const ColorHighlightOff = styled(HighlightOff)<SvgIconProps & { colors?: string }>`
    color: ${(props) => props.colors};
`;

export const ButtonColor = styled(Button)<ButtonProps & { colorRGB?: boolean }>`
    background-color: ${(props) => (props.colorRGB ? '' : colors.error)};
    border-color: ${(props) => (props.colorRGB ? colors.RBGreen : colors.error)};
    color: ${(props) => (props.colorRGB ? colors.RBGreen : colors.white)};
`;

export const TypographyBold = styled(Typography)`
    font-weight: 600;
    white-space: pre-wrap;
    margin: 0px 16px 0px 0px;
`;

export const TypographyPreWhiteSpace = styled(Typography)<TypographyProps & { bold?: boolean }>`
    white-space: pre-wrap;
    margin: 0px 16px;
    ${(props) => (props.bold ? 'font-weight: 600;' : '')};
`;
