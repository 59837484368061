import * as React from 'react';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/AGPaginatedTable';
import { columnsArray } from './columns';
import Panel from '../../components/Panel';

import { EnquiryFiltersInternal } from './EnquiriesListInternal.types';

import {
    addNeedComment,
    fetchEnquiries,
    fetchEnquiriesRbPharma,
    fetchEnquiryDetail,
    fetchEnquiryDetailUnload,
    fetchEnquiryNeedComments,
    updateApprovalStatus,
} from '../../store/Enquiry/actions';
import {
    getItems as getEnquiries,
    isLoading as isLoadingEnquiries,
    getTotal,
    getComments,
    isLoadingComments,
    getDetail,
} from '../../store/Enquiry/selectors';
import { goToRFQDetails } from '../../store/Router/actions';
import {
    EnquiryNeedDeclineType,
    EnquiryNeedDetail,
    EnquiryNeedStatus,
    OrderStatus,
    ProposalStatus,
    RFQStatus,
} from '../../models/EnquiryNeed';
import SingleSelectInput from '../../components/inputs/SingleSelectInput';
import { Box, Button, Grid, Switch, Typography } from '@material-ui/core';
import TextInput from '../../components/inputs/TextInput';
import { EnquiryAcceptDeclineDialog } from './EnquiryAcceptDeclineDialog/EnquiryAcceptDeclineDialog';
import { EnquiryCommentsList } from './EnquiryCommentsList/EnquiryCommentsList';
import { MapOf } from '../../utils/Types';
import { EnquiryDetailClientDialog } from '../EnquiriesListClients/EnquiryDetailDialog/EnquiryDetailDialog';

export interface UpdateEnquiryNeedStatus {
    declineType?: string;
    customerComments?: string;
    approvalStatus?: string;
    internalComments?: string;
}

const orderBy = {
    Enquiry: 'EnquiryNumber',
    Status: 'Status',
    Client: 'CreatedBy',
    Need: 'Name',
    'Accept/Decline': 'Status',
    'Last Update': 'UpdatedAt',
} as MapOf<string>;

export interface AddEnquiryNeedComment {
    id?: string;
    comment?: string;
}

const EnquiriesList: React.FC = () => {
    const dispatch = useDispatch();
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();
    const [filters, setFilters] = React.useState<EnquiryFiltersInternal>({
        pageSize: 10,
        offset: 0,
    });
    const items = useSelector(getEnquiries);
    const total = useSelector(getTotal);
    const comments = useSelector(getComments);
    const isLoadingComment = useSelector(isLoadingComments);
    const isLoading = useSelector(isLoadingEnquiries);
    const [cPage, setCpage] = React.useState(0);
    const [onlyMine, setOnlyMine] = React.useState(false);
    const [toAccept, setToAccept] = React.useState(false);
    const [enquiryNeedDetail, setEnquiryNeedDetail] = React.useState<EnquiryNeedDetail>({});
    const [opemCommentsDialog, setOpenCommentsDialog] = React.useState(false);
    const needDetail = useSelector(getDetail);
    const [commentsNeedId, setCommentsNeedId] = React.useState('');
    React.useEffect(() => {
        dispatch(fetchEnquiriesRbPharma(filters));
    }, []);
    const goToRFQ = (itemId: string) => {
        dispatch(goToRFQDetails(itemId));
    };

    const getNeedDetail = (id: string) => {
        dispatch(fetchEnquiryDetail(id));
    };

    const handleDetailDialogClose = () => {
        dispatch(fetchEnquiryDetailUnload());
    };
    const handleAcceptRejectClick = (item: any, toAccept: boolean) => {
        setToAccept(toAccept);
        setEnquiryNeedDetail({ id: item.id, needName: item.needName });
    };

    const onClickCommentsIcon = (item: any) => {
        dispatch(fetchEnquiryNeedComments(item.id));
        setCommentsNeedId(item.id);
        setOpenCommentsDialog(true);
    };
    const colDef = columnsArray(t, goToRFQ, handleAcceptRejectClick, onClickCommentsIcon,getNeedDetail);
    React.useEffect(() => {
        setHeaderName(t(TK.enquiriesListPharma));
    }, [filters]);

    React.useEffect(() => {
        setCpage(filters.offset! / filters.pageSize);
    }, [filters.offset, filters.pageSize]);

    const startSearch = React.useCallback(() => {
        if (onlyMine) {
            dispatch(fetchEnquiries(filters));
            return;
        }
        dispatch(fetchEnquiriesRbPharma(filters));
    }, [filters, onlyMine]);

    const handlePageChange = React.useCallback(
        (page: number) => {
            filters.offset = page * filters.pageSize;
            setFilters({ ...filters });
            startSearch();
        },
        [filters],
    );

    const onSortChanged = React.useCallback(
        (sortField: string, sortOrder: string) => {
            if (sortField && sortOrder) {
                filters.orderBy = orderBy[sortField] + ' ' + sortOrder;
                setFilters({ ...filters });
                startSearch();
                return;
            }
            filters.orderBy = undefined;
            setFilters({ ...filters });
            startSearch();
            return;
        },
        [filters, onlyMine],
    );
    const handleAcceptRejectClick2 = (
        id: string,
        toAccept: boolean,
        reason?: EnquiryNeedDeclineType,
        comment?: string,
        internal?: string,
    ) => {
        setEnquiryNeedDetail({});
        dispatch(
            updateApprovalStatus(id, {
                approvalStatus: toAccept ? 'Accepted' : 'Declined',
                customerComments: comment,
                declineType: reason,
                internalComments: internal,
            }),
        );
    };

    const handleAddComments = (add: AddEnquiryNeedComment) => {
        dispatch(addNeedComment(add));
        setOpenCommentsDialog(false);
    };

    const handlePageSizeChange = React.useCallback(
        (pageSize: number) => {
            filters.offset = 0;
            filters.pageSize = pageSize;
            setFilters({ ...filters });
            startSearch();
        },
        [filters],
    );

    const handleSearch = React.useCallback(() => {
        filters.offset = 0;
        startSearch();
    }, [filters, onlyMine]);

    const handleEnquiryNeedStatus = React.useCallback(
        (status: string) => {
            filters.enquiryStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    const handleOrderStatus = React.useCallback(
        (status: string) => {
            filters.orderStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    const handleProposalStatus = React.useCallback(
        (status: string) => {
            filters.proposalStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    const handleRfqStatus = React.useCallback(
        (status: string) => {
            filters.rfqStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    return (
        <Page
            title={t(TK.enquiriesListPharma)}
            style={{ marginTop: '10rem' }}
            actionPanel={
                <Panel>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled={isLoading}
                                options={Object.values(EnquiryNeedStatus)}
                                value={filters.enquiryStatus}
                                placeholder={t(TK.enquiryStatus)}
                                onChange={handleEnquiryNeedStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={2}>
                            <TextInput
                                disabled={isLoading}
                                fullWidth={true}
                                value={filters.search}
                                onChange={(v) => {
                                    filters.search = v ?? undefined;
                                    setFilters({ ...filters });
                                }}
                                placeholder={t(TK.search)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled
                                options={Object.values(RFQStatus)}
                                placeholder={t(TK.rfqStatus)}
                                value={filters.rfqStatus}
                                onChange={handleRfqStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled
                                options={Object.values(ProposalStatus)}
                                placeholder={t(TK.proposalStatus)}
                                value={filters.proposalStatus}
                                onChange={handleProposalStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled
                                options={Object.values(OrderStatus)}
                                placeholder={t(TK.orderStatus)}
                                value={filters.orderStatus}
                                onChange={handleOrderStatus}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={1}>
                            <Box hidden display="flex" alignItems="center">
                                <Switch
                                    color="primary"
                                    disabled={isLoading}
                                    name="onlyMine"
                                    size="small"
                                    checked={onlyMine}
                                    onClick={() => setOnlyMine(!onlyMine)}
                                />
                                <Typography>{t(TK.onlyCreatedbyMe)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={1}>
                            <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
                                {t(TK.search)}
                            </Button>
                        </Grid>
                    </Grid>
                </Panel>
            }
        >
            <EnquiryDetailClientDialog handleDialogClose={handleDetailDialogClose} needOpen={needDetail} />
            <EnquiryCommentsList
                comments={comments}
                isLoading={isLoadingComment}
                openCommentsDialog={opemCommentsDialog}
                needId={commentsNeedId}
                handleDialogClose={() => setOpenCommentsDialog(false)}
                handleCilck={handleAddComments}
            />
            <EnquiryAcceptDeclineDialog
                needOpen={enquiryNeedDetail}
                toAccept={toAccept}
                handleDialogClose={() => {
                    setEnquiryNeedDetail({});
                }}
                handleCilck={handleAcceptRejectClick2}
            ></EnquiryAcceptDeclineDialog>
            <Panel>
                <Table
                    columnsDefinition={colDef}
                    data={items}
                    currentPage={cPage}
                    enablePagination={true}
                    pagination={true}
                    pageSize={filters.pageSize}
                    totalElements={total}
                    totalPages={Math.ceil(total / filters.pageSize)}
                    isExportable={false}
                    isLoading={isLoading}
                    onChangeSorting={onSortChanged}
                    onPageChange={handlePageChange}
                    onFilterChanged={() => {}}
                    onPageSizeChange={handlePageSizeChange}
                    onFreeTextChange={() => {}}
                />
            </Panel>
        </Page>
    );
};

export default EnquiriesList;
