import { SearchResult } from './SearchResult';
import { ProcessingPformsSettings } from './ProcessingPformsSettings';
import { ProcessingCatSettings } from './ProcessingCatSettings';
import { ProcessingDciFullSettings } from './ProcessingDciFullSettings';
import { ProcessingATCSettings } from './ProcessingATCSettings';

export enum MappingName {
    PharmaceuticalForms = 'pharmaceuticalForms',
    CategoriesList = 'categoriesList',
    ActiveIngredients = 'activeIngredients',
    ActiveIngredientsFinal = 'activeIngredientsFinal',
    ATCs = 'aTCs',
}

export interface ProcessingSettingsV3 {
    [MappingName.PharmaceuticalForms]: SearchResult<ProcessingPformsSettings>;
    [MappingName.CategoriesList]: SearchResult<ProcessingCatSettings>;
    [MappingName.ActiveIngredients]: SearchResult<ProcessingPformsSettings>;
    [MappingName.ActiveIngredientsFinal]: SearchResult<ProcessingDciFullSettings>;
    [MappingName.ATCs]: SearchResult<ProcessingATCSettings>;
}
