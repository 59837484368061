import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

interface RBTooltipProps {
    text: string;
    padding?: string;
}

export const RBTooltip: React.FC<RBTooltipProps> = ({ text, padding = '1px' }) => {
    return (
        <Tooltip style={{ alignSelf: 'baseline', padding: padding }} interactive color="primary" title={text}>
            <IconButton size="small">
                <InfoIcon />
            </IconButton>
        </Tooltip>
    );
};
