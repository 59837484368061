import * as React from 'react';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { useParams } from 'react-router';
import {
    AccordionDetails,
    Box,
    CardContent,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {
    ChatBubbleOutline,
    CheckOutlined,
    Create,
    ExpandMore,
    PostAddOutlined,
    Save,
    SendOutlined,
} from '@material-ui/icons';
import { RFQAccordion, RFQBoxFlex, RFQCard, RFQH, RFQHeader, RFQHeaderH, RFQNeed, RFQSpan, RFQStatus } from './RFQEnquire.styles';
import TextInput from '../../components/inputs/TextInput';
import { capitalizeFirstLetter } from '../../utils/utils';
import { getRFQEnquire, isLoadingRFQEnquire } from '../../store/RFQEnquire/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRFQEnquire } from '../../store/RFQEnquire/actions';
import { getColor, getTK } from '../../utils/StateColors';
import DocumentIcons from '../../components/DocumentIcons';
import { getTransporters } from '../../store/Transporters/selectors';
import { fetchTransporters } from '../../store/Transporters/actions';

const RFQEnquire: React.FC = () => {
    const dispatch = useDispatch();
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();
    const { rfqNrEncoded } = useParams<{ rfqNrEncoded: string }>();
    const rfqInformation = useSelector(getRFQEnquire);
    const isLoading = useSelector(isLoadingRFQEnquire);
    const transporters = useSelector(getTransporters);

    const rfqNr = React.useMemo(() => decodeURIComponent(rfqNrEncoded), [rfqNrEncoded]);

    React.useEffect(() => {
        dispatch(fetchRFQEnquire(rfqNr));
        dispatch(fetchTransporters());
    }, [rfqNr]);

    React.useEffect(() => {
        setHeaderName(t(TK.RFQEnquiry) + ' ' + rfqInformation.rfqDescription);
    }, [rfqInformation]);

    return (
        <Page title={t(TK.RFQEnquiry) + rfqInformation.rfqDescription} style={{ marginTop: '10rem' }}>
            <RFQHeader>
                <RFQHeaderH variant="h4">
                    {t(TK.RFQEnquiry)} - {rfqNr}{' '}
                </RFQHeaderH>
                <Box width="50%" justifyContent="right">
                    <Box marginRight={1} textAlign={'end'}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Closing date"
                                format="dd/MM/yyyy"
                                value={'11/11/2021'}
                                disabled
                                onChange={() => console.log()}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                </Box>
            </RFQHeader>
            {!isLoading &&
                rfqInformation &&
                rfqInformation.needs.map((rfq) => {
                    return (
                        <RFQNeed>
                            <RFQH variant="h5" $color="green">
                                {t(TK.need)}
                            </RFQH>
                            <Typography variant="h6">
                                {rfq.header.name}
                                {rfq.header.documents.map((doc) => {
                                    return <DocumentIcons doc={doc} />;
                                })}
                            </Typography>
                            <RFQSpan>{t(TK.RFQProductCode)}: </RFQSpan> {rfq.header.productCode}{' '}
                            <RFQSpan>{t(TK.RFQQuantity)}: </RFQSpan>
                            {rfq.header.unitQuant} <RFQSpan>{t(TK.createdDate)}: </RFQSpan>{' '}
                            {new Date(rfq.header.createdAt).toLocaleString()}
                            <br />
                            <RFQSpan>{t(TK.packSize)}: </RFQSpan> {rfq.header.package} {t(TK.units)}
                            <RFQAccordion defaultExpanded style={{ margin: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <RFQH variant="h5" $color="green">
                                        {capitalizeFirstLetter(t(TK.offers))}
                                    </RFQH>
                                </AccordionSummary>
                                {rfq.offers.map((rfqOffer) => {
                                    const simul = rfqOffer.simulation;
                                    return (
                                        <AccordionDetails>
                                            <RFQCard style={{ width: '50%', margin: '0 1%' }}>
                                                <CardContent style={{ paddingBottom: '0px' }}>
                                                    <RFQBoxFlex>
                                                        <RFQH variant="h5" $color="green">
                                                            {t(TK.RFQOfferInfo)}
                                                        </RFQH>
                                                        <RFQStatus $color={getColor(rfqOffer.status)}>
                                                            {getTK(rfqOffer.status)}
                                                        </RFQStatus>
                                                    </RFQBoxFlex>
                                                    <RFQBoxFlex>
                                                        <Box style={{ width: '50%' }}>
                                                            <RFQSpan>{t(TK.supplier)}: </RFQSpan>{' '}
                                                            {rfqOffer.supplierName}
                                                        </Box>
                                                        <Box style={{ width: '50%' }}>
                                                            <RFQSpan>{t(TK.RFQOrigin)}: </RFQSpan>
                                                            {rfqOffer.countryCode}
                                                        </Box>
                                                    </RFQBoxFlex>
                                                    <RFQBoxFlex>
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                width: '50%',
                                                            }}
                                                        >
                                                            <RFQSpan>{t(TK.availability)}:&nbsp;</RFQSpan>
                                                            <TextInput
                                                                width={100}
                                                                value={
                                                                    rfqOffer.availabilityPacks
                                                                        ? rfqOffer.availabilityPacks.toString()
                                                                        : '0'
                                                                }
                                                            ></TextInput>
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                width: '50%',
                                                            }}
                                                        >
                                                            <RFQSpan>{t(TK.RFQLeadTime)}:&nbsp;</RFQSpan>{' '}
                                                            <TextInput
                                                                value={
                                                                    rfqOffer.leadTimeToDeliver
                                                                        ? rfqOffer.leadTimeToDeliver.toString()
                                                                        : '0'
                                                                }
                                                            ></TextInput>
                                                        </Box>
                                                    </RFQBoxFlex>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            margin: '1%',
                                                        }}
                                                    >
                                                        <RFQSpan>{t(TK.RFQPackPrice)}:&nbsp;</RFQSpan>
                                                        <TextInput
                                                            width={100}
                                                            value={
                                                                rfqOffer.exwNetPrice
                                                                    ? rfqOffer.exwNetPrice.toString()
                                                                    : '0'
                                                            }
                                                        ></TextInput>
                                                    </Box>
                                                    <RFQBoxFlex>
                                                        <Box style={{ width: '50%' }}>
                                                            <RFQSpan>{t(TK.RFQUnitPriceEuro)}:&nbsp;</RFQSpan>{' '}
                                                            {rfqOffer.exwNetPrice
                                                                ? rfqOffer.exwNetPrice.toString()
                                                                : '0'}{' '}
                                                            {t(TK.EuroSimbol)}
                                                        </Box>
                                                        <Box style={{ width: '50%' }}>
                                                            <RFQSpan>{t(TK.RFQExpDate)}: </RFQSpan>
                                                            {rfqOffer.expDate
                                                                ? new Date(rfqOffer.expDate).toLocaleString()
                                                                : ''}
                                                        </Box>
                                                    </RFQBoxFlex>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            margin: '1%',
                                                        }}
                                                    >
                                                        <RFQSpan>{t(TK.RFQComments)}:&nbsp;</RFQSpan>
                                                        <TextField
                                                            variant={'outlined'}
                                                            value={rfqOffer.comments}
                                                            type={'text'}
                                                            multiline
                                                            rows={3}
                                                            InputLabelProps={{ shrink: true }}
                                                            fullWidth
                                                            autoComplete="off"
                                                        ></TextField>
                                                    </Box>
                                                    <br />
                                                    <RFQBoxFlex>
                                                        <Typography variant="inherit">
                                                            {t(TK.RFQLastUpdatedBy)} {rfqOffer.modifiedBy} {t(TK.on)}{' '}
                                                            {new Date(rfqOffer.updatedAt).toLocaleString()}
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="h6" style={{ color: '#00c4aa' }}>
                                                                <Tooltip title={<h6 style={{ font: '30px' }}>Save</h6>}>
                                                                    <Save />
                                                                </Tooltip>
                                                            </Typography>
                                                        </Box>
                                                    </RFQBoxFlex>
                                                </CardContent>
                                            </RFQCard>
                                            {simul && (
                                                <RFQCard style={{ width: '50%', margin: '0 1%' }}>
                                                    <CardContent style={{ paddingBottom: '0px' }}>
                                                        <RFQBoxFlex>
                                                            <RFQH variant="h5" $color="#eead2d">
                                                                {t(TK.priceSimulation)}
                                                            </RFQH>
                                                            <RFQStatus $color={getColor(simul.status)}>
                                                                {getTK(simul.status)}
                                                            </RFQStatus>
                                                        </RFQBoxFlex>
                                                        <RFQBoxFlex>
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '50%',
                                                                }}
                                                            >
                                                                <RFQSpan>{t(TK.RFQAvailableQTT)}:&nbsp;</RFQSpan>
                                                                <TextInput
                                                                    width={100}
                                                                    value={
                                                                        simul.simulAvailabilityPacks
                                                                            ? simul.simulAvailabilityPacks.toString()
                                                                            : '0'
                                                                    }
                                                                ></TextInput>{' '}
                                                                <span>&nbsp;{t(TK.packs)}</span>
                                                            </Box>
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '50%',
                                                                }}
                                                            >
                                                                <RFQSpan>{t(TK.RFQPackPrice)}:&nbsp;</RFQSpan>{' '}
                                                                <TextInput
                                                                    value={
                                                                        simul.simulExwNetPrice
                                                                            ? simul.simulExwNetPrice.toString()
                                                                            : '0'
                                                                    }
                                                                ></TextInput>
                                                                <span>&nbsp;{t(TK.EuroSimbol)}</span>
                                                            </Box>
                                                        </RFQBoxFlex>
                                                        <RFQBoxFlex>
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '50%',
                                                                }}
                                                            >
                                                                <RFQSpan>{t(TK.Trasporter)}:&nbsp;</RFQSpan>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ margin: '1%', minWidth: 120 }}
                                                                >
                                                                    <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        onChange={(p) => {
                                                                            console.log(p);
                                                                        }}
                                                                    >
                                                                        <MenuItem value="None">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        {transporters &&
                                                                            transporters.map((item) => {
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={item.name}
                                                                                        key={item.name}
                                                                                    >
                                                                                        {item.name}
                                                                                    </MenuItem>
                                                                                );
                                                                            })}
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '50%',
                                                                }}
                                                            >
                                                                <RFQSpan>{t(TK.RFQAdditionalCost)}:&nbsp;</RFQSpan>
                                                                <span>
                                                                    {simul.additionalCosts.length > 0 ? 1 : '0'}&nbsp;
                                                                    {t(TK.EuroSimbol)}
                                                                </span>
                                                                <Typography variant="h6">
                                                                    <Create />
                                                                </Typography>
                                                            </Box>
                                                        </RFQBoxFlex>
                                                        <RFQBoxFlex>
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '50%',
                                                                }}
                                                            >
                                                                <RFQSpan>{t(TK.weightedPrice)}:&nbsp;</RFQSpan>
                                                                <span>
                                                                    {simul.simulSellingPrice
                                                                        ? simul.simulSellingPrice.toString()
                                                                        : '0'}{' '}
                                                                    &nbsp;{t(TK.EuroSimbol)}
                                                                </span>
                                                            </Box>
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '50%',
                                                                }}
                                                            >
                                                                <RFQSpan>{t(TK.RFQComments)}:&nbsp;</RFQSpan>
                                                                <Typography variant="h6">
                                                                    <ChatBubbleOutline />
                                                                </Typography>
                                                            </Box>
                                                        </RFQBoxFlex>
                                                        <RFQBoxFlex>
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '50%',
                                                                }}
                                                            >
                                                                <RFQSpan>{t(TK.SellingPackPrice)}:&nbsp;</RFQSpan>
                                                                <TextInput
                                                                    width={100}
                                                                    value={
                                                                        simul.simulSellingPrice
                                                                            ? simul.simulSellingPrice.toString()
                                                                            : '0'
                                                                    }
                                                                ></TextInput>
                                                                <span>&nbsp;{t(TK.EuroSimbol)}</span>
                                                            </Box>
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '50%',
                                                                }}
                                                            >
                                                                <RFQSpan>{t(TK.TotalSellingPrice)}:&nbsp;</RFQSpan>
                                                                <span>0.00 &nbsp;{t(TK.EuroSimbol)}</span>
                                                            </Box>
                                                        </RFQBoxFlex>
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                margin: '1%',
                                                            }}
                                                        >
                                                            <RFQSpan>{t(TK.Margin)}:&nbsp;</RFQSpan>
                                                            <span>0.00 &nbsp;{t(TK.EuroSimbol)} (0.00%)</span>
                                                        </Box>
                                                        <br />
                                                        <RFQBoxFlex>
                                                            <Typography variant="inherit">
                                                                {t(TK.RFQLastUpdatedBy)} User ABC {t(TK.on)} 13/11/2022
                                                                17:10:10
                                                            </Typography>
                                                            <Box>
                                                                <Typography variant="h6" style={{ color: '#00c4aa' }}>
                                                                    <PostAddOutlined />
                                                                    <CheckOutlined />
                                                                    <Save />
                                                                    <SendOutlined />
                                                                </Typography>
                                                            </Box>
                                                        </RFQBoxFlex>
                                                    </CardContent>
                                                </RFQCard>
                                            )}
                                        </AccordionDetails>
                                    );
                                })}
                            </RFQAccordion>
                        </RFQNeed>
                    );
                })}
        </Page>
    );
};

export default RFQEnquire;
