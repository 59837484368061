import { AppThunkAction } from '..';
import { postLoginAsync, RequestError } from '../../fetch/requests';
import { AuthenticatedUser } from '../../models/AuthenticatedUser';
import { LoggedInAction, AlertAction, AlertDismissAction } from './types';
import { Action } from 'redux';
import { AlertType } from '../../components/AlertBar/AlertBar';
import { getTranslation } from '../Translations/selectors';
import { TK } from '../Translations/translationKeys';
import { HTTP_BAD_REQUEST, HTTP_UNAUTHORIZED, HTTP_INTERNAL_SERVER_ERROR } from '../../fetch/statusCodes';
import { getLocation, getLocationRedirectParam } from '../Router/selectors';
import { getAccessToken, getCsrfToken, isLoggedOutOrSessionExpired } from './selectors';
import { routes } from '../Router/routes';
import { goTo, goToLogin } from '../Router/actions';
import { DomainInfoName } from '../DomainInfo/state';
import { fetchDomainInfo } from '../DomainInfo/actions';
import { fetchUsers } from '../Users/actions';
import { UserRole } from '../../models/UserRole';

export const Actions = {
    loggingIn: '@@whichpharma.session.loggingIn',
    loginCancelled: '@@whichpharma.session.loginCancelled',
    loggedIn: '@@whichpharma.session.loggedIn',
    loggedInWithGoogle: '@@whichpharma.session.loggedInWithGoogle',
    loggedOut: '@@whichpharma.session.loggedOut',
    loginFailed: '@@whichpharma.session.loginFailed',
    alert: '@@whichpharma.session.alert',
    alertDismiss: '@@whichpharma.session.alertDismiss',
    updateCustomSetting: '@@whichpharma.session.updateCustomSetting',
};

const loggingIn = (): Action => ({
    type: Actions.loggingIn,
});

const loggedIn = (user: AuthenticatedUser): LoggedInAction => ({
    type: Actions.loggedIn,
    user,
});

const getAllUserRoles = (role: string) =>
    role === UserRole.Administrator || role === UserRole.Collaborator || role === UserRole.PlatformContributor;

const loggedOut = (): Action => ({
    type: Actions.loggedOut,
});

const loginFailed = (): Action => ({
    type: Actions.loginFailed,
});

export const doLogin = (username: string, password: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState) => {
        try {
            dispatch(loggingIn());
            const user = await postLoginAsync({ username, password });
            dispatch(loggedIn(user));

            Object.keys(DomainInfoName)
                .map((k) => (DomainInfoName as any)[k] as DomainInfoName)
                .forEach((v) => dispatch(fetchDomainInfo(v as DomainInfoName)));
            const requestUsers: boolean = user.roles.some(getAllUserRoles);
            if (requestUsers) {
                dispatch(fetchUsers());
            }
        } catch (e) {
            dispatch(loginFailed());

            if (e instanceof RequestError && (e as RequestError).statusCode === HTTP_BAD_REQUEST) {
                const unableToLoginText = getTranslation(getState(), TK.invalidCredentials);
                dispatch(alertError(unableToLoginText));
            } else {
                dispatch(alertGenericError());
            }
        }
    };
};

export const doLogout = (): AppThunkAction<void> => {
    return (dispatch, getState) => {
        const state = getState();

        if (!isLoggedOutOrSessionExpired(state)) {
            dispatch(loggedOut());
        }
        const location = getLocation(state);

        if (location.pathname !== routes.login && location.pathname !== routes.logout) {
            dispatch(goToLogin(location.pathname));
        }

        if (location.pathname === routes.logout) {
            dispatch(goToLogin());
        }
    };
};

const httpStatusCodeWhichRedirectToLogin = [HTTP_UNAUTHORIZED, HTTP_INTERNAL_SERVER_ERROR];

export const requestServer = <T>(
    request: (token: string | undefined, csrfToken: string | undefined) => Promise<T>,
): AppThunkAction<Promise<T>> => {
    return async (dispatch, getState) => {
        try {
            return await request(getAccessToken(getState()), getCsrfToken(getState()));
        } catch (e) {
            const isRequestError = e instanceof RequestError;
            if (
                !isRequestError ||
                (isRequestError && httpStatusCodeWhichRedirectToLogin.includes((e as RequestError).statusCode))
            ) {
                dispatch(alertError(getTranslation(getState(), TK.errorRequest)));
            }
            throw e;
        }
    };
};

const alertsDuration = (nrChars: number) => 1000 + nrChars * 40;

let alertNextId = 0;

export const alertDispatch = (type: AlertType, content: React.ReactNode, durationMs?: number): AlertAction => ({
    type: Actions.alert,
    alert: {
        id: (alertNextId++).toString(),
        type,
        content,
        durationMs,
    },
});

export const alertDismiss = (id: string): AlertDismissAction => ({
    type: Actions.alertDismiss,
    id,
});

export const alertGenericError = (): AppThunkAction<void> => {
    return (dispatch, getState) => {
        const msg = getTranslation(getState(), TK.somethingWentWrong);
        dispatch(alertDispatch(AlertType.Error, msg, alertsDuration(msg.length)));
    };
};

export const alertError = (message: string): AppThunkAction<void> => {
    return (dispatch) => {
        dispatch(alertDispatch(AlertType.Error, message, alertsDuration(message.length)));
    };
};

export const alertSuccess = (message: string): AppThunkAction<void> => {
    return (dispatch) => {
        dispatch(alertDispatch(AlertType.Success, message, alertsDuration(message.length)));
    };
};

export const alertSaving = (message: string): AppThunkAction<void> => {
    return (dispatch) => {
        dispatch(alertDispatch(AlertType.Success, message));
    };
};
