import React from 'react';
import { Typography } from '@material-ui/core';
import { Sidebar, Container, Logo, AppInfoContainer, LogoBetaNotLogged } from '../styled';
import { IAppLayoutProps } from '../Hoc/AppLayout';
import { TK } from '../../../store/Translations/translationKeys';
import { useTranslations } from '../../../store/Translations/hooks';

export const LoginLayoutComponent: React.FC<IAppLayoutProps> = (props) => {
    const { hideSidebar, isLoggedOut, scrollRef } = props;
    const t = useTranslations();

    const SidebarInfo = () => (
        <AppInfoContainer>
            <Typography variant="subtitle1">{t(TK.findMedicines)}.</Typography>
            <Typography variant="subtitle1">{t(TK.getQuotes)}.</Typography>
            <Typography variant="subtitle1">{t(TK.trackStock)}.</Typography>
            {/* <Typography variant="subtitle1">{t(TK.connectWithGlobalSuppliers)}.</Typography>
            <Typography variant="subtitle1">{t(TK.allInOnePlatform)}.</Typography> */}
        </AppInfoContainer>
    );

    return (
        <>
            <Sidebar hidden={hideSidebar}>
                <Logo isLogged={!isLoggedOut} style={{paddingLeft:"165px"}} /><LogoBetaNotLogged isLogged={!isLoggedOut} />
                <hr />
                {!isLoggedOut ? '' : <SidebarInfo />}
            </Sidebar>{' '}
            <Container ref={scrollRef} isLogged={!isLoggedOut} $fullWidth={hideSidebar}>
                {props.children}
            </Container>
        </>
    );
};
