import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import Page from '../../components/Page';
import { TK } from '../../store/Translations/translationKeys';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import Panel from '../../components/Panel';
import { fetchPing, fetchPing2, fetchPing3, fetchPing4 } from '../../store/RFQProducts/actions';

const Grey: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    React.useEffect(() => {
        setHeaderName('Gray');
    }, []);

    const onClick = React.useCallback(() => {
        dispatch(fetchPing());
        dispatch(fetchPing2());
        // dispatch(fetchPing3());
    }, []);

    const onClickTST = React.useCallback(() => {
        dispatch(fetchPing3());
        dispatch(fetchPing4());
    }, []);

    return (
        <Page title={t(TK.welcome)}>
            <div style={{ marginTop: '5rem' }}>
                <Panel>
                    <Button onClick={onClick} color="primary" variant="contained" placeholder="Teste">
                        Shades
                    </Button>
                </Panel>
            </div>
            <div style={{ marginTop: '5rem' }}>
                <Panel>
                    <Button onClick={onClickTST} color="primary" variant="contained" placeholder="Teste">
                        Darker
                    </Button>
                </Panel>
            </div>
        </Page>
    );
};

export default Grey;
