import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { isLoggingIn } from '../../store/Session/selectors';
import { TK } from '../../store/Translations/translationKeys';
import { doLogin } from '../../store/Session/actions';
import { ifEnter } from '../../utils/utils';
import { InnerContent, LanguageInput, LoginContainer, OrTitle, StrechDiv, WellcomeTitle } from './Login.styles';
import { CustomButton } from '../../components/Styled';
import { Spinner } from 'reactstrap';
import Input from '../../components/inputs/Input';
import Button from '@material-ui/core/Button';
import FormDialog from '../../components/FormDialog';
import { getLocale } from '../../store/Translations/selectors';
import { fetchTranslations } from '../../store/Translations/actions';
import { escapeHtmlSpecialChars } from '../../utils/utils';
import appSettings from '../../appSettings';

const Login: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const loggingIn = useSelector(isLoggingIn);
    const locale = useSelector(getLocale);

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [requestAccessLink, setRequestAccessLink] = React.useState(false);

    const canSubmit = !loggingIn && username.length >= 3 && password.length >= 3;

    const submitPress = React.useCallback((): void => {
        dispatch(doLogin(escapeHtmlSpecialChars(username), escapeHtmlSpecialChars(password)));
    }, [dispatch, username, password]);

    const handleLocaleChange = React.useCallback(
        (locale: string) => {
            dispatch(fetchTranslations(locale));
        },
        [dispatch],
    );

    React.useEffect(() => {
        console.log('var env appSettings ' + appSettings.valor_de_teste);
        console.log('var env process ' + process.env.REACT_APP_ENV);
    }, []);

    return (
        <InnerContent>
            <LanguageInput hideClearButton value={locale} options={['en-US', 'pt']} onChange={handleLocaleChange} />

            <LoginContainer>
                <WellcomeTitle className="title" variant="h3" color="textPrimary">
                    {t(TK.welcome)+ " TO YOUR FREE TRIAL"}
                </WellcomeTitle>
                <Input
                    type="text"
                    label={t(TK.username)}
                    placeholder={t(TK.username)}
                    value={username}
                    onChange={setUsername}
                    onKeyUp={(keyCode): void => ifEnter(keyCode, submitPress)}
                />
                <Input
                    label={t(TK.password)}
                    type="password"
                    placeholder={t(TK.password)}
                    value={password}
                    onChange={setPassword}
                    onKeyUp={(e): void => ifEnter(e, submitPress)}
                />

                <StrechDiv style={{ display: 'block' }}>
                    {/* <CustomButton variant="text" onClick={(): void => {}}>
                        {t(TK.haveYouForgottenYourPassword)}
                    </CustomButton> */}
                    <CustomButton variant="rounded" onClick={submitPress} disabled={!canSubmit}>
                        {loggingIn && <Spinner size="sm" />}
                        {t(TK.enter)}
                    </CustomButton>
                </StrechDiv>
            </LoginContainer>
        </InnerContent>
    );
};

export default Login;
