import React from 'react';
import { RFQOfferDocumentsDto } from '../../models/RFQOfferDocumentsDto';
import { Tooltip } from '@material-ui/core';
import { Description, InsertDriveFile, Language } from '@material-ui/icons';
export interface documentIconsProps {
    doc: RFQOfferDocumentsDto;
}

const DocumentIcons: React.FC<documentIconsProps> = (props) => {
    const { doc } = props;

    const getIcon = () => {
        switch (doc.type) {
            case 'PIL':
                return <InsertDriveFile style={{ color: '#00c4aa' }} />;
            case 'Web':
                return <Language style={{ color: '#00c4aa' }} />;
            default:
                return <Description style={{ color: '#00c4aa' }} />;
        }
    };

    return (
        <a key={doc.url} href={doc.url} target="_blank" rel="noopener noreferrer">
            <Tooltip title={<h6>{doc.type}</h6>}>{getIcon()}</Tooltip>
        </a>
    );
};

export default DocumentIcons;
