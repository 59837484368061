import styled from 'styled-components';
import colors from '../../../theme/colors';
import { Typography } from '@material-ui/core';

export const DialogTitleContainer = styled.div`
    display: inline-flex;
    background-color: ${colors.brightGreen};
    padding: 30px 20px 15px;
    font-weight: 200;
    color: ${colors.textRBGreen};
    width: 100%;
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
`;

export const TypographyBold = styled(Typography) `
    font-weight: 600;
    white-space : pre;
    margin :0px 16px 0px 0px;
`;

export const TypographyPreWhiteSpace = styled(Typography) `    
    white-space : pre;
    margin :0px 16px;
`;
