export enum TK {
    enquiryCreated = 'Enquiry Created',
    enquiryCreatedMessage = 'Enquiry Number 0000 was created successfully.',
    goTo = 'Go to ',
    myEnquiries = 'My Enquiries',
    or = 'or',
    from = 'from',
    enquiry = 'Enquiry',
    until = 'until',
    enquiryStatus = 'Enquiry Status',
    additionalNotes = 'additionalNotes',
    next = 'next',
    createOrderforSelectedItems = 'Create Order for Selected Items',
    createNewEnquiry = 'Create New Enquiry',
    authorised = 'authorised',
    marketed = 'marketed',
    monitor = 'Monitor',
    unavailable = 'unavailable',
    discontinued = 'discontinued',
    authorisedeu = 'authorisedeu',
    parallelimport = 'parallelimport',
    previous = 'previous',
    loading = 'loading',
    offers = 'offers',
    shortages = 'shortages',
    exchangeRate = 'exchangeRate',
    country = 'Origin',
    countryV2 = 'Country',
    companyName = 'Company Name',
    acessType = 'Acess Type',
    refineCountry = 'Filter Origin',
    errorRequest = 'errorRequest',
    countries = 'countries',
    scrapingOrigin = 'Scraping Origin',
    scrapingOriginId = 'Scraping OriginId',
    lastUpdate = 'lastUpdate',
    name = 'Name',
    nameV2 = 'name',
    documents = 'Documents',
    otherDocuments = 'Other Documents',
    supplierReply = 'Supplier reply',
    activeSubstances = 'Active Ingredient',
    atc = 'ATC',
    manufacturer = 'Manufacturer',
    drugForm = 'drugForm',
    pharmaceuticalForm = 'Pharmaceutical Form',
    administration = 'administration',
    administrationForm = 'Administration Route',
    drugFormCategories = 'drugFormCategories',
    administrationFormCategories = 'administrationFormCategories',
    strength = 'Strength',
    package = 'Pack size',
    maNumber = 'maNumber',
    registerConfirmMessage = 'By proceeding, I declare that I am duly authorized by the entity I represent to open an account with wixmed on its behalf',
    maHolder = 'MA holder',
    status = 'Status',
    state = 'state',
    spcFileUrl = 'spcFileUrl',
    pilFileUrl = 'pilFileUrl',
    retailPrice = 'retailPrice',
    reimbursementPrice = 'reimbursementPrice',
    wholesalePrice = 'wholesalePrice',
    factoryPrice = 'factoryPrice',
    exFactoryPrice = 'exFactoryPrice',
    currencyCode = 'currencyCode',
    all = 'all',
    filters = 'filters',
    none = 'None',
    selectedProducts = 'selectedProducts',
    noProductSelected = 'noProductSelected',
    productsSelection = 'productsSelection',
    proceedToRFQCreation = 'proceedToRFQCreation',
    proceedToEnquiryCreation = 'Create Enquiry',
    marketedProducts = 'marketedProducts',
    euProducts = 'euProducts',
    nonEuProducts = 'nonEuProducts',
    proceedToSuppliersSelection = 'proceedToSuppliersSelection',
    proceedToEmailConfiguration = 'proceedToEmailConfiguration',
    productCode = 'productCode',
    packSize = 'packSize',
    welcome = 'welcome',
    username = 'username',
    password = 'password',
    confirmPassword = 'confirmPassword',
    haveYouForgottenYourPassword = 'haveYouForgottenYourPassword',
    enter = 'enter',
    areYouSure = 'areYouSure',
    newPasswordWillBeSent = 'newPasswordWillBeSent',
    resetPassword = 'resetPassword',
    cancel = 'cancel',
    somethingWentWrong = 'somethingWentWrong',
    invalidCredentials = 'invalidCredentials',
    products = 'products',
    productsProdV = 'Products (stable)',
    productsProdV1 = 'Products (stable V1.0)',
    productsV3 = 'Products (v3.0)',
    logout = 'logout',
    login = 'login',
    users = 'users',
    roles = 'roles',
    userUpdatedSuccessfully = 'userUpdatedSuccessfully',
    userCreatedSuccessfully = 'userCreatedSuccessfully',
    weakPassword = 'weakPassword',
    usernameInUse = 'usernameInUse',
    emailInUse = 'emailInUse',
    invalidUsername = 'invalidUsername',
    invalidEmail = 'invalidEmail',
    invalidPassword = 'invalidPassword',
    addNew = 'addNew',
    update = 'update',
    create = 'create',
    accessRestricted = 'accessRestricted',
    loggedOut = 'loggedOut',
    suppliersSelection = 'suppliersSelection',
    emailsSelection = 'emailsSelection',
    pleaseSelectSupplierForCountry = 'pleaseSelectSupplierForCountry',
    pleaseSelectContactEmailForTheSupplier = 'pleaseSelectContactEmailForTheSupplier',
    id = 'id',
    contacts = 'Contacts',
    classification = 'Classification',
    type = 'Type',
    isEurope = 'isEurope',
    notes = 'Notes',
    expiryDate = 'expiryDate',
    expiryDateSupplier = 'Expiry date',
    creationDateSupplier = 'Creation date',
    lastVerification = 'lastVerification',
    creationDate = 'creationDate',
    selectedSuppliers = 'selectedSuppliers',
    noSupplierSelected = 'noSupplierSelected',
    configurations = 'configurations',
    confirmation = 'Confirmation',
    deselectAll = 'deselectAll',
    rfqs = 'rfqs',

    rfqsV2 = 'RFQs (v2)',
    productsList = 'productsList',
    createRequestForQuote = 'createRequestForQuote',
    rfqsList = 'rfqsList',
    profile = 'profile',
    dontHaveRfqsYetCreateYourFirst = 'dontHaveRfqsYetCreateYourFirst',
    suppliers = 'suppliers',
    active = 'active',
    inactive = 'inactive',
    closed = 'closed',
    noQuote = 'noQuote',
    unviewedReplies = 'unviewedReplies',
    emailContent = 'emailContent',
    user = 'user',
    supplier = 'supplier',
    product = 'product',
    others = 'others',
    other = 'Other',
    configureEmailContentMessage = 'configureEmailContentMessage',
    price = 'price',
    prices = 'Prices',
    quantity = 'quantity',
    quantityRequested = 'quantityRequested',
    availableAt = 'availableAt',
    lotNumber = 'lotNumber',
    quoteReplyForm = 'quoteReplyForm',
    configureQuoteReplyForm = 'configureQuoteReplyForm',
    mandatoryFields = 'mandatoryFields',
    optionalFields = 'optionalFields',
    noOptions = 'noOptions',
    add = 'add',
    send = 'send',
    createRfqAndSendTheEmails = 'createRfqAndSendTheEmails',
    sendUsingOtherAccount = 'sendUsingOtherAccount',
    rfqCreated = 'rfqCreated',
    original = 'original',
    withVAT = 'withVAT',
    withoutVAT = 'withoutVAT',
    noData = 'noData',
    noColumns = 'noColumns',
    hideColumns = 'hideColumns',
    fromToOf = 'fromToOf',
    rowsPerPage = 'rowsPerPage',
    showAll = 'showAll',
    export = 'export',
    exportVisibleItems = 'exportVisibleItems',
    exportSelectedItems = 'exportSelectedItems',
    dragColumnToGroup = 'dragColumnToGroup',
    sort = 'sort',
    search = 'search',
    advancedSearch = 'advancedSearch',
    clearAllFilters = 'clearAllFilters',
    clearFilters = 'clear',
    yes = 'yes',
    no = 'no',
    productNotes = 'productNotes',
    start = 'start',
    end = 'end',
    minStart = 'minStart',
    maxStart = 'maxStart',
    minEnd = 'minEnd',
    maxEnd = 'maxEnd',
    onlyActive = 'onlyActive',
    partialshortage = 'partialshortage',
    showHideTablePageFilters = 'showHideTablePageFilters',
    contains = 'contains',
    notContains = 'notContains',
    startsWith = 'startsWith',
    endsWith = 'endsWith',
    equal = 'equal',
    notEqual = 'notEqual',
    greaterThan = 'greaterThan',
    greaterThanOrEqual = 'greaterThanOrEqual',
    lessThan = 'lessThan',
    lessThanOrEqual = 'lessThanOrEqual',
    categories = 'categories',
    pil = 'pil',
    spc = 'spc',
    label = 'label',
    file = 'file',
    resetTableSettings = 'resetTableSettings',
    freeTextSearch = ' ',
    freeTextSearchDescription = 'freeTextSearchDescription',
    productCodeOrMANumber = 'productCodeOrMANumber',
    manufacturerOrMAHolder = 'manufacturerOrMAHolder',
    empty = 'empty',
    close = 'close',
    ok = 'ok',
    defaultBehavior = 'defaultBehavior',
    freeTextSearchDefaultBehaviorInfo = 'freeTextSearchDefaultBehaviorInfo',
    mandatoryWords = 'mandatoryWords',
    freeTextSearchMandatoryWordsInfo = 'freeTextSearchMandatoryWordsInfo',
    exactPhrase = 'exactPhrase',
    freeTextSearchExactPhraseInfo = 'freeTextSearchExactPhraseInfo',
    excludeWords = 'excludeWords',
    freeTextSearchExcludeWordsInfo = 'freeTextSearchExcludeWordsInfo',
    email = 'email',
    accountSettings = 'accountSettings',
    rfqNr = 'rfqNr',
    unitQuant = 'unitQuant',
    unUsed = 'unUsed',
    totalUnits = 'totalUnits',
    exwNetPriceEuro = 'exwNetPriceEuro',
    netPriceEuro = 'netPriceEuro',
    priceEuro = 'priceEuro',
    availabilityPacks = 'availabilityPacks',
    availabilityVisuals = 'availabilityVisuals',
    leadTimeToDeliver = 'leadTimeToDeliver',
    expDate = 'expDate',
    countryOfOrigin = 'countryOfOrigin',
    precForStorage = 'precForStorage',
    comments = 'comments',
    productsTables = 'productsTables',
    seeAndConfigureTheTablesInEmails = 'seeAndConfigureTheTablesInEmails',
    sendingTo = 'sendingTo',
    markAsValidated = 'markAsValidated',
    productsToAssociate = 'productsToAssociate',
    pleaseMarkAllProductsTablesAsVerified = 'pleaseMarkAllProductsTablesAsVerified',
    pleaseMakeSureAllRFQsHaveAValidNumber = 'pleaseMakeSureAllRFQsHaveAValidNumber',
    pleaseMakeSureAllRFQsHaveAValidDescription = 'pleaseMakeSureAllRFQsHaveAValidDescription',
    pleaseAssociateAllProductsToAnRFQ = 'pleaseAssociateAllProductsToAnRFQ',
    dragAndDropToAssignProductsToARFQ = 'dragAndDropToAssignProductsToARFQ',
    noProductsSelectedError = 'noProductsSelectedError',
    rfqAssociation = 'rfqAssociation',
    allProductsAreAssociated = 'allProductsAreAssociated',
    theEmailBodyCannotBeEmpty = 'theEmailBodyCannotBeEmpty',
    subject = 'subject',
    default = 'default',
    remove = 'remove',
    saveThisTemplate = 'saveThisTemplate',
    removeThisTemplate = 'removeThisTemplate',
    reset = 'reset',
    autoFillFields = 'autoFillFields',
    selectAccount = 'selectAccount',
    addAccount = 'addAccount',
    firstName = 'firstName',
    lastName = 'lastName',
    creatingRFQsAndSendingEmails = 'creatingRFQsAndSendingEmails',
    rfqsCreatedAndEmailsSent = 'rfqsCreatedAndEmailsSent',
    pleaseVerifyYourOutbox = 'pleaseVerifyYourOutbox',
    mailbox = 'mailbox',
    title = 'title',
    supplierFirstNameMissing = 'supplierFirstNameMissing',
    supplierLastNameMissing = 'supplierLastNameMissing',
    userFirstNameMissing = 'userFirstNameMissing',
    userLastNameMissing = 'userLastNameMissing',
    userEmailMissing = 'userEmailMissing',
    userTitleMissing = 'userTitleMissing',
    productsTableMissing = 'productsTableMissing',
    supplierNotFoundInDatabase = 'supplierNotFoundInDatabase',
    recipientNotFoundInSupplierData = 'recipientNotFoundInSupplierData',
    userNotFoundInDatabase = 'userNotFoundInDatabase',
    emailBodyContainsUnknownPlaceholder = 'emailBodyContainsUnknownPlaceholder',
    unableToConnectToYourEmailAccount = 'unableToConnectToYourEmailAccount',
    platform = 'platform',
    platformV3 = 'platformV3',
    savePlatformSettings = 'savePlatformSettings',
    saving = 'saving',
    processingSettings = 'processingSettings',
    fields = 'fields',
    specifyProductFieldsWhereInfoCanBeFound = 'specifyProductFieldsWhereInfoCanBeFound',
    specifyTheKeywordsForTheCorrespondingValues = 'specifyTheKeywordsForTheCorrespondingValues',
    units = 'units',
    save = 'save',
    culture = 'culture',
    decimalSeparator = 'decimalSeparator',
    numberGroupSeparator = 'numberGroupSeparator',
    general = 'general',
    copied = 'copied',
    minimumCharacters = 'minimumCharacters',
    reprocessProducts = 'reprocessProducts',
    backupPlatform = 'Save to Backup',
    backupRestorePlatform = 'Restore from Backup',
    reprocessRequestSubmitted = 'reprocessRequestSubmitted',
    pFormCreated = 'pFormCreated',

    streakApiKey = 'streakApiKey',
    open = 'open',
    supplierWaitingReply = 'supplierWaitingReply',
    openQuotes = 'openQuotes',
    closedQuotes = 'closedQuotes',

    onlyOpen = 'onlyOpen',
    onlyMine = 'onlyMine',
    userIsNotACollaborator = 'userIsNotACollaborator',
    assigneeSuccessfullyUpdated = 'assigneeSuccessfullyUpdated',
    conversations = 'conversations',
    quotes = 'quotes',
    saveChanges = 'saveChanges',
    userIsNotAllowed = 'userIsNotAllowed',
    threadNotFound = 'threadNotFound',
    tableDataSuccessfullyUpdated = 'tableDataSuccessfullyUpdated',
    rfqUpdated = 'rfqUpdated',
    emailsSentButErrorIntegrationWithStreak = 'emailsSentButErrorIntegrationWithStreak',
    emailsSentButErrorSavingInDatabase = 'emailsSentButErrorSavingInDatabase',
    errorAccessingDatabase = 'errorAccessingDatabase',
    errorSendingEmails = 'errorSendingEmails',
    copyFromOther = 'copyFromOther',
    fullList = 'Full List',
    rfqReplyForm = 'rfqReplyForm',
    requestsForQuote = 'requestsForQuote',
    thisReplyFormIsNoLongerAvailable = 'thisReplyFormIsNoLongerAvailable',
    submit = 'submit',
    successfullySubmited = 'successfullySubmited',
    contact = 'contact',
    days = 'days',
    externalLinkForSupplierReplyForm = 'externalLinkForSupplierReplyForm',
    description = 'description',
    desc = 'desc',
    rfqDescription = 'rfqDescription',
    unitPrice = 'unitPrice',
    totalPrice = 'totalPrice',
    packsTotal = 'packsTotal',
    numberOfPacks = 'numberOfPacks',
    requestedPacks = 'requestedPacks',
    addFieldsToBeAutomaticallyFilledInProductsTables = 'addFieldsToBeAutomaticallyFilledInProductsTables',
    fillProductTables = 'fillProductTables',
    codes = 'codes',
    code = 'code',
    pleaseMakeSureAllContactsHaveAValidEmail = 'pleaseMakeSureAllContactsHaveAValidEmail',
    pleaseMakeSureVerifiedAllProducts = 'pleaseMakeSureVerifiedAllProducts',
    youAreSelectingASupplierForACountryWithoutSelectedProducts = 'youAreSelectingASupplierForACountryWithoutSelectedProducts',
    linkWithGoogle = 'linkWithGoogle',
    availableUntil = 'availableUntil',
    imInterested = 'imInterested',
    skip = 'Skip',
    skipped = 'Declined',
    details = 'details',
    offerAlternative = 'offerAlternative',
    offerAlternativeOnDecline = 'offerAlternativeOnDecline',
    declineQuote = 'declineQuote',
    submitOffer = 'submitOffer',
    decline = 'decline',
    iveChangedMyMindAndImInterested = 'iveChangedMyMindAndImInterested',
    changeQuote = 'changeQuote',
    editQuote = 'editQuote',
    accept = 'accept',
    termsAndConditions = 'platTermsAndConditions',
    receiveACopyOfMyOfferByEmail = 'receiveACopyOfMyOfferByEmail',
    new = 'Open',
    toQuote = 'toQuote',
    v1 = 'v1',
    v2 = 'v2',
    interested = 'Open',
    kanbanInterested = 'Interested',
    inProgress = 'inProgress',
    quoted = 'Quoted',
    declined = 'declined',
    Category_Solid = 'solid',
    Category_SemiSolid = 'semiSolid',
    Category_Liquid = 'liquid',
    Category_Gas = 'gas',
    createdBy = 'createdBy',
    updatedBy = 'updatedBy',
    expires = 'expires',
    seeAllYourRFQs = 'seeAllYourRFQs',
    viewAll = 'viewAll',
    dueDate = 'dueDate',
    dueDateMissing = 'dueDateMissing',
    alternative = 'alternative',
    assignToExistingRfq = 'assignToExistingRfq',
    youreTryingToAssignToAnExistingRfqWhichWasntFound = 'youreTryingToAssignToAnExistingRfqWhichWasntFound',
    someoneHasCreatedRfqWithTheSameNumberPleaseTryAgain = 'someoneHasCreatedRfqWithTheSameNumberPleaseTryAgain',
    requestAnAccessLink = 'requestAnAccessLink',
    accessLinkWasSentToYourInbox = 'accessLinkWasSentToYourInbox',
    yourRequestHasBeenRegistered = 'yourRequestHasBeenRegistered',
    thisLinkIsNoLongerAvailable = 'thisLinkIsNoLongerAvailable',
    language = 'language',
    noResults = 'noResults',
    quoteLater = 'quoteLater',
    yourQuote = 'yourQuote',
    whatWeNeed = 'whatWeNeed',
    why = 'why',
    duration = 'duration',
    ifApplicable = 'ifApplicable',
    hide = 'hide',
    includeExprice = 'includeExprice',
    viewOpen = 'viewOpen',
    notInOurRange = 'Not in our range',
    onShortage = 'On shortage',
    notCommercialized = 'Not commercialized',
    authorizationCeased = 'Authorization ceased',
    exportBan = 'Export ban',
    otherPleaseIndicate = 'otherPleaseIndicate',
    qualified = 'qualified',
    suspended = 'suspended',
    qualify = 'qualify',
    archive = 'Archive',
    generic = 'Generic',
    psychotropic = 'Psychotropic',
    biological = 'Biological',
    additionalMonitoring = 'Additional Monitoring',
    prescription = 'Prescription',
    hospitalar = 'Hospitalar',
    precautionsForStorage = 'Precautions For Storage',
    reason = 'reason',
    register = 'Register',
    registration = 'REGISTRATION',
    statusNotes = 'Status Notes',

    notAuthorised = 'Not Authorised',

    commercialised = 'Commercialised',
    notCommercialised = 'Not Commercialised',

    unknown = 'Unknown',
    NotAuthorised = 'NotAuthorised', // for color codes
    NotMarketed = 'NotMarketed',
    Marketed = 'Marketed',
    acceptSelectedProposals = 'Accept Selected Proposals',
    shortage = 'Shortage',
    availability = 'Availability',
    additionalInformation = 'Additional Information',
    shareLink = 'Copy link',
    searchAnyColumn = 'Search any column',
    productDescription = 'productDescription',
    additionalCost = 'additionalCost',
    weightedPrice = 'weightedPrice',
    comparison = 'comparison',
    TotalQuotes = 'Total quotes:',
    TotalResult = 'Total results:',
    pleaseSelectProducts = 'pleaseSelectProducts',
    errorMessageInvalidDate = 'Invalid expDate, should be MM/YYYY or month less than 12',
    errorMessageInvalidYear = 'Invalid date',
    selectSuppliersMessage = 'Please select suppliers for all products!',
    confirmDialogMessage = 'Are you sure you want to delete this?',
    confirm = 'Confirm',
    weAcceptAlt = 'We accept alternative',
    showTableView = 'Table view',
    showKanbanView = 'Kanban view',
    searchKanban = 'Search rfq, pack size, user ...',
    unSavedChanges = 'You have unsaved changes, please save them',
    RFQEnquiry = 'RFQ Enquiry',
    enquiryCreationConfirmationMessage = 'Are you sure you want to submit this enquiry?',
    enquiriesList = 'Enquiries List',
    need = 'Need',
    proposal = 'Proposal',
    proposalStatus = 'Proposal Status',
    order = 'Order',
    orderStatus = 'Order Status',
    createdDate = 'Created Date',
    RFQQuantity = 'Quantity',
    RFQProductCode = 'Product Code',
    RFQOfferInfo = 'Offer Info',
    RFQOrigin = 'Origin',
    RFQPackPrice = 'Pack Price',
    RFQLeadTime = 'Lead Time',
    RFQUnitPriceEuro = 'Unit Price (€)',
    EuroSimbol = '€',
    RFQExpDate = 'Exp. Date',
    RFQComments = 'Comments',
    RFQLastUpdatedBy = 'Last updated by',
    on = 'on',
    RFQAvailableQTT = 'Available QTT',
    Trasporter = 'Trasporter',
    RFQAdditionalCost = 'Additional Cost',
    RFQWeightedPrice = 'Weighted Price',
    SellingPackPrice = ' Selling Pack Price',
    TotalSellingPrice = ' Total Selling Price',
    Margin = 'Margin',
    packs = 'packs',
    priceSimulation = 'Price Simulation',
    approved = 'Approved',
    draft = 'Draft',
    forApproval = 'For Approval',
    createEnquiry = 'Create Enquiry',
    needed = 'Needed',
    moreDetailsAbout = 'More details about the request',
    acceptAlternative = 'Accept Alternative',
    acceptGenerics = 'Accept generics',
    acceptOtherPackSizes = 'Accept other pack sizes',
    singleLot = 'Single lot',
    acceptDifferentCountries = 'Accept different countries',
    additionalDocuments = 'Additional documents',
    selectAnyCountry = 'Select any country',
    selectDocuments = 'Select documents',
    select = 'Select',
    client = 'Client',
    rfqStatus = 'RFQ Status',
    rfq = 'RFQ',
    enquiriesListPharma = 'Enquiries List (RB Pharma)',
    acceptDecline = 'Accept/Decline',
    onlyCreatedbyMe = 'Only Created by Me',
    statusDetailOpenMessage = 'Your request is under review.',
    statusDetailAcceptedMessage = 'You will be contacted with a proposal for this product',
    acceptNeed = 'Accept Need',
    declineNeed = 'Decline Need',
    reasonWhy = 'Reason why',
    notesToClient = 'Notes to Client',
    submitEnquiry = 'Submit Enquiry',
    findMedicines = 'Find',
    getQuotes = 'Quote',
    trackStock = 'Buy',
    connectWithGlobalSuppliers = 'Connect with Global Suppliers',
    allInOnePlatform = 'All in One Platform',
    customer = 'Customer',
    notFound = 'Page not found',
    goToHome = 'Go to Home',
    noOffers = 'There are no offers to show at the moment.',
    welcomeWixmed = 'Welcome to Wixmed',
    definePassword = 'Please define a Password',
    registrationCompletedSuccess = 'Your account is now enabled',
    passwordRole = 'Must contain a capital letter, a number and at least 8 characters',
    excludingCharactersRole = `Excluding (&,",',<,>, *)`,
    confLinkNotValid = 'Your confirmation link is no longer valid',
    passwordError = 'Password empty or invalid',
    passwordMustMatch = 'Password must match',
    tooltipTabCapVials = 'e.g.: tablets/capsules/vials',
}
