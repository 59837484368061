import { ApplicationState } from '..';
import { DomainInfoName } from './state';

const DOMAIN_INFO_REFRESH_SECONDS = 60 * 60;

export const needsUpdate = (state: ApplicationState, domainInfoName: DomainInfoName) => {
    if (state.domainInfo[domainInfoName]?.isUpdating) {
        return false;
    }

    if (!state.domainInfo[domainInfoName].items?.length) {
        return true;
    }

    const lastUpdate = state.domainInfo[domainInfoName]?.lastUpdate;

    return !lastUpdate || new Date().getTime() - new Date(lastUpdate).getTime() > DOMAIN_INFO_REFRESH_SECONDS * 1000;
};

export const getDomainInfo = (state: ApplicationState, domainInfoName: DomainInfoName): any[] => state.domainInfo[domainInfoName]?.items || [];
