export enum SupplierState {
	New = 'New',
	ToQualify = 'ToQualify',
	Qualifying = 'Qualifying',
	Active = 'Active',
	Inactive = 'Inactive',
	Suspended = 'Suspended',
	Qualified = "Qualified",
	Qualify = "Qualify",
	Others = "Others",
}