import { DialogContent } from '@material-ui/core';
import styled from 'styled-components';

export const DialogTitleContainerStyled = styled.div`
    display: inline-flex;
    background-color: ${({ theme }) => theme.colors.brightGreen};
    font-weight: 200;
    color: ${({ theme }) => theme.colors.textRBGreen};
    width: 100%;
`;

export const CloseIconWrapperStyled = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
`;

export const DialogContentStyled = styled(DialogContent)`
    margin-top: 10px;
`;
