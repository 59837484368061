import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import Page from '../../components/Page';
import { TK } from '../../store/Translations/translationKeys';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { goTo, goToEnquireProducts, goToPaginatedProducts } from '../../store/Router/actions';
import { routes } from '../../store/Router/routes';
import { getLoggedUser } from '../../store/Session/selectors';
import { UserRole } from '../../models/UserRole';

const Home: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const logged = useSelector(getLoggedUser);
    React.useEffect(() => {
        if (logged?.roles.includes(UserRole.Customer)) {
            dispatch(goTo(routes.enquiryProducts));
        } else if (logged?.roles.includes(UserRole.Supplier)) {
            dispatch(goTo(routes.rfqReply));
        } else {
            dispatch(goToPaginatedProducts());
        }
    }, [logged]);

    return (
        <Page title={t(TK.welcome)}>
            <Typography>Trust me, this is a pretty cool application, it just doesn't have an home page yet!</Typography>
        </Page>
    );
};

export default Home;
