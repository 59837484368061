import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import Page from '../../components/Page';
import { TK } from '../../store/Translations/translationKeys';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { goTo, goToEnquireProducts, goToPaginatedProducts } from '../../store/Router/actions';
import { routes } from '../../store/Router/routes';
import { getLoggedUser } from '../../store/Session/selectors';
import { UserRole } from '../../models/UserRole';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import TextInput from '../../components/inputs/TextInput';
import { CreateUser, RegisterCompany } from '../../models/CreateUser';
import Panel from '../../components/Panel';
import Input from '../../components/inputs/Input';

import { Label } from '../../components/Styled';
import { alertError } from '../../store/Session/actions';
import { registerCompanyAction } from '../../store/Users/actions';
import { GridCenter } from './Register.styles';

const _emailRegexFuntion = (value: string) => /^(?=.*[A-Za-z])[A-Za-z\d\.\-@]{4,}$/g.test(value);
const _passwordStrengthRegexFunction = (value: string) => /^(?=.*[A-Za-z])(?=.*\d).{4,}$/g.test(value);
const mailError = 'Mail is not in correct format';
const passError = 'Password is not in correct format';
const confirmPassError = 'Password not match';
const companyError = 'Company name empty';
const typeError = 'Acess Type empty';
const countryError = 'Country empty';
const termsError = 'Terms not accepted';

const Register: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [validMail, setValidMail] = React.useState(false);
    const [isClicked, setIsClicked] = React.useState(false);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [validPass, setValidPass] = React.useState(false);
    const [registerCompany, setRegisterCompany] = React.useState({ value: {} as RegisterCompany, valid: false });
    React.useEffect(() => {
        setHeaderName(t(TK.products));
    }, []);

    const handleChange = React.useCallback(
        (newValue: RegisterCompany) => {
            const valid = !!newValue.password?.length && newValue.password === confirmPassword;
            setValidMail(_emailRegexFuntion(newValue.email ?? ''));
            setValidPass(_passwordStrengthRegexFunction(newValue.password));
            setIsClicked(false);
            setRegisterCompany({ value: newValue, valid });
        },
        [
            setRegisterCompany,
            confirmPassword,
            _emailRegexFuntion,
            setValidMail,
            _passwordStrengthRegexFunction,
            setValidPass,
            setIsClicked,
        ],
    );
    const onClickRegister = React.useCallback(() => {
        const emailValid = _emailRegexFuntion(registerCompany.value.email ?? '');
        const passValid = _passwordStrengthRegexFunction(registerCompany.value?.password || '');
        setValidMail(emailValid);
        setValidPass(passValid);
        setIsClicked(true);
        if (!emailValid) {
            dispatch(alertError(mailError));
            return;
        }
        if (!validPass) {
            dispatch(alertError(passError));
            return;
        }
        if (!registerCompany.valid) {
            dispatch(alertError(confirmPassError));
            return;
        }
        if (!registerCompany.value.companyName?.length) {
            dispatch(alertError(companyError));
            return;
        }
        if (!registerCompany.value.acessType) {
            dispatch(alertError(typeError));
            return;
        }
        if (!registerCompany.value.country) {
            dispatch(alertError(countryError));
            return;
        }
        if (!termsAccepted) {
            dispatch(alertError(termsError));
            return;
        }
        dispatch(registerCompanyAction(registerCompany.value));
    }, [
        dispatch,
        setIsClicked,
        _emailRegexFuntion,
        _passwordStrengthRegexFunction,
        registerCompany,
        termsAccepted,
        alertError,
        setValidMail,
        setValidPass,
    ]);

    return (
        <Page title={t(TK.welcome)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2" style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                        {t(TK.registration)}
                    </Typography>
                </Grid>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <Input
                            type="email"
                            label={t(TK.email)}
                            value={registerCompany.value.email}
                            required
                            error={isClicked && !validMail ? 'Email empty or not valid' : ''}
                            onChange={(email) => handleChange({ ...registerCompany.value, email })}
                            onKeyUp={(a) => {}}
                        />
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <div style={{ display: 'block', width: '100%' }}>
                            <Input
                                label={t(TK.password)}
                                value={registerCompany.value.password}
                                type="password"
                                required
                                error={isClicked && !validPass ? 'Password empty or invalid' : ''}
                                onKeyUp={(a) => {}}
                                onChange={(password) => handleChange({ ...registerCompany.value, password })}
                            />
                        </div>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <Input
                            label={t(TK.confirmPassword)}
                            value={confirmPassword}
                            type="password"
                            required
                            error={
                                isClicked && registerCompany.value.password !== confirmPassword
                                    ? 'Password must match'
                                    : ''
                            }
                            onKeyUp={(a) => {}}
                            onChange={(confirmPassword) => setConfirmPassword(confirmPassword)}
                        />
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <Box width={'100%'}>
                            <Box display={'flex'}>
                                <Label>{t(TK.acessType)}</Label>
                                <Label style={{ color: 'red', fontSize: '2rem', float: 'right' }}>*</Label>
                            </Box>
                            <FormControl fullWidth style={{ alignItems: 'center' }}>
                                <RadioGroup
                                    row
                                    onChange={(i, acessType) => {
                                        handleChange({ ...registerCompany.value, acessType });
                                    }}
                                >
                                    <FormControlLabel
                                        value="customer"
                                        control={<Radio color="primary" />}
                                        label="Customer"
                                    />
                                    <FormControlLabel
                                        value="supplier"
                                        control={<Radio color="primary" />}
                                        label="Supplier"
                                    />
                                    <FormControlLabel value="both" control={<Radio color="primary" />} label="Both" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <Input
                            label={t(TK.companyName)}
                            value={registerCompany.value.companyName}
                            type="text"
                            required
                            error={
                                isClicked && !registerCompany.value.companyName ? 'Company Name need to be filled' : ''
                            }
                            onKeyUp={(a) => {}}
                            onChange={(companyName) => handleChange({ ...registerCompany.value, companyName })}
                        />
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <Input
                            label={t(TK.countryV2)}
                            value={registerCompany.value.country}
                            type="text"
                            required
                            error={isClicked && !registerCompany.value.companyName ? 'Country need to be filled' : ''}
                            onKeyUp={(a) => {}}
                            onChange={(country) => handleChange({ ...registerCompany.value, country })}
                        />
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <Box display={'flex'} flexDirection={'row'}>
                            <Checkbox
                                checked={termsAccepted}
                                onChange={() => setTermsAccepted(!termsAccepted)}
                                color="primary"
                            />
                            <Typography>{t(TK.registerConfirmMessage)}</Typography>
                        </Box>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={2}>
                        <Button onClick={onClickRegister} fullWidth color="primary" variant="contained">
                            {t(TK.register)}
                        </Button>
                    </GridCenter>
                </GridCenter>
            </Grid>
        </Page>
    );
};

export default Register;
