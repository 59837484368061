import { ApplicationState } from '..';
import { RFQSummary } from '../../models/RFQSummary';
import { RFQRequestV3 } from '../../models/RFQRequest';
import { RFQDetails } from '../../models/RFQDetails';
import { MapOf } from '../../utils/Types';

export const getRFQSummaries = (state: ApplicationState): MapOf<RFQSummary> => state.rfqsV3.summaries;
export const getRFQSearchTotal = (state: ApplicationState): number => state.rfqsV3.searchTotal || 0;
export const getRFQDetails = (state: ApplicationState): MapOf<RFQDetails> => state.rfqsV3.details;
export const isLoadingRFQs = (state: ApplicationState): boolean => state.rfqsV3.isLoading;
export const isLoadingRFQsDetails = (state: ApplicationState): boolean => state.rfqsV3.isLoadingDetails;
export const getLoadingTime = (state: ApplicationState): number => state.rfqsV3.timeInSeconds || 0;
export const isSubmittingRFQs = (state: ApplicationState): boolean => state.rfqsV3.isSubmitting;
export const isUpdatingRFQ = (state: ApplicationState, rfqNumber: string): boolean =>
    !!state.rfqsV3.isUpdating?.includes(rfqNumber);
export const getLatestRFQRequest = (state: ApplicationState): RFQRequestV3 | undefined =>
    state.rfqsV3.latestRequestSubmitted;
export const getNextRfqNumber = (state: ApplicationState): string | undefined => state.rfqsV3.nextRfqNumber;
export const getRfqsNumbersCreated = (state: ApplicationState): string[] => state.rfqsV3.createNumbers || [];
