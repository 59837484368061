import { Accordion, AccordionDetails, Box, BoxProps, Card, Typography, TypographyProps } from '@material-ui/core';
import styled from 'styled-components';
import typography from '../../theme/typography';

export const StikyDiv = styled.div`
    position: sticky;
    top: 0;
`;

export const RFQHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
`;

export const RFQHeaderH = styled(Typography)`
    width: 50%;
    align-content: center;
`;
export const RFQSpan = styled.span`
    font-weight: bolder;
`;

export const RFQNeed = styled(Box)`
    border-radius: 25px;
    border: 2px;
    background-color: white;
    padding: 1%;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
`;

export const RFQAccordion = styled(Accordion)`
    border: 2px;
    background-color: white;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
`;

export const RFQCard = styled(Card)`
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
`;

export const RFQStatus = styled(Box)<BoxProps & { $color: string }>`
    border-radius: 25px;
    border: 2px solid ${(p) => p.$color};
    background-color: ${(p) => p.$color};
    color: white;
`;

export const RFQLabel = styled.label`
    margin: 1px 5px 0px 0px;
`;

export const RFQH = styled(Typography)<TypographyProps & { $color: string }>`
    color: ${(p) => p.$color};
`;
export const RFQBoxFlex = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
`;

const ButtonDocs = styled.a`
    flex: 1;
    display: flex;
    justify-content: center;
    color: black;
    height: 28px;
    margin: 16px;
    background-color: #b7e1cd;
    position: relative;
    &:hover {
        color: white;
    }
`;