import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '..';
import { getDomainInfo } from './selectors';
import { fetchDomainInfo } from './actions';
import { DomainInfoName } from './state';
import { Country } from '../../models/Country';
import { AdditionalDocuments } from '../../models/AdditionalDocuments';
import { RFQSimulationTransporterDto } from '../../models/RFQSimulationTransporterDto';

const useDomainInfo = (domainInfoName: DomainInfoName): any[] => {
    const dispatch = useDispatch();
    const values = useSelector<ApplicationState, string[]>((state) => getDomainInfo(state, domainInfoName));

    if (!(values && values.length)) {
        setTimeout(() => dispatch(fetchDomainInfo(domainInfoName)), 50);
    }

    return values;
};

export const useCountries = () => useDomainInfo(DomainInfoName.Countries) as Country[];
export const useTransporters = () => useDomainInfo(DomainInfoName.Transporters) as RFQSimulationTransporterDto[];
export const useAdditionalDocuments = () => useDomainInfo(DomainInfoName.AdditionalDocuments) as AdditionalDocuments[];
